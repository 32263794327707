import React, { ReactElement, useCallback } from 'react'
import { Layout, Text } from '@clout-team/web-components';
import { useSelector } from 'react-redux';
import { BRAND_NAME, LAND_PAGE } from '../../../constants/contants';
import { isElectron } from '@clout-team/helpers';

const Header = React.memo((props: {
  right?: ReactElement
}) => {
  const lang = useSelector((state: any) => state.localization),
    L = useCallback((key: string) => lang.translations[lang.locale][key], [lang]),
    {right} = props
  return (
    <>
      <div className='pages-wrap--component wrapper-header'>
        {!isElectron() && <div className={`waiting-room-logo`} onClick={() => {
          document.location.href = LAND_PAGE;
        }}>
          <Layout gap={'sm'} isAlignCenter horizontal>
            <img loading={`lazy`} src={'favicon-32x32.png'} alt={'meet'} />
            <Text size={'header-bold'} type={'secondary'}>{BRAND_NAME}</Text>
            <Text size={'header'} type={'secondary'}>{L('calls.meet')}</Text>
          </Layout>
        </div>}
        {right ? right : <></>}
        {/* <Button color="white" size="lg" onClick={() => {
          window.open(AUTH_URL, "_blank")
        }}>
          <Text size="body-bold" type='' align='center'>
            {L("meet_landing.sign_in")}
          </Text>
        </Button> */}
      </div>
    </>
  )
})
export default React.memo(Header)