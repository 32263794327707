import React, { useCallback, useState, useEffect } from "react";
import { Button, Input, Layout, Text } from "@clout-team/web-components";
import SendFilled from '@clout-team/icons/react/SendFilled'
import './SendToEmailBanner.tsx.scss';
import { useSelector } from "react-redux";
import { conferenceHashSelector, conferenceToken } from "../../../../redux/selectors/conference";
import { sendMeetingDetails } from "../../../../api/calls";
import { isValidEmail } from "@clout-team/helpers";

const SendToEmailBanner = ({ L }: { L: (key: string) => string }) => {
  const [isSended, setIsSended] = useState<boolean>(false),
    [email, setEmail] = useState<string>('')
  return (
    <>
      <Layout gap="none" horizontal className="send-to-email-banner">
        {!isSended && <EmailTypeBanner
          email={{
            getter: email,
            setter: setEmail
          }}
          L={L}
          onSendData={() => setIsSended(true)}
        />}
        {isSended && (
          <Layout gap="lg" vertical className="send-to-email-banner--content">
            <Layout gap="sm" vertical>
              <Text type="primary" size="dialog-title">
                {L("calls.label.details_have_been_successfully_sent")}
              </Text>
              <Text type="secondary" size="body">
                {L("calls.label.details_have_been_sent_to_this_email").replace("{_email_}", email)}
              </Text>
            </Layout>
          </Layout>
        )}
        <div className="send-to-email-banner--image" />
      </Layout>
    </>
  )
}

const EmailTypeBanner = ({ onSendData, L, email }: {
  onSendData: () => void;
  email: {
    getter: string,
    setter: (key: string) => void
  },
  L: (key: string) => string
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false),
    { getter, setter } = email,
    token = useSelector(conferenceToken),
    hash = useSelector(conferenceHashSelector);
  const sendData = useCallback(() => {
    if (email) {
      setIsLoading(true);

      sendMeetingDetails(getter, hash, `${token}`).then((res) => {
        setIsLoading(false);
        if (res) {
          onSendData()
        } else {

        }
      })
    }
  }, [email, hash, token]);
  const formSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    sendData()
  }
  return <Layout gap="lg" vertical className="send-to-email-banner--content">
    <Layout gap="sm" vertical>
      <Text type="primary" size="dialog-title">
        {L("calls.title.send_chat_details_to_mail")}
      </Text>
      <Text type="secondary" size="body">
        {L("calls.label.we_will_send_chat_details_to_email")}
      </Text>
    </Layout>
    <form onSubmit={formSubmit}>
      <Layout gap="sm" horizontal isAlignCenter>
        <Input
          dismiss={false}
          placeholder={L('calls.label.enter_your_email_address')}
          capitalizeValue={false}
          onChange={(e) => setter(e.target.value)}
          type={`email`}
        />
        <Button
          loading={isLoading}
          size={'lg'}
          type="submit"
          disabled={!isValidEmail(getter)}
          className={'rounded-corners-8'}
          onClick={sendData} iconInside={<SendFilled />}
        />
      </Layout>
    </form>
  </Layout>
}
export default React.memo(SendToEmailBanner)