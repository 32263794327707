import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {IParticipant} from "./types";
import "./participant.scss";
import classNames from "classnames";
import {Avatar, Button, Layout, Text, TooltipWrap} from "@clout-team/web-components";
import PersonFilled from "@clout-team/icons/react/PersonFilled";
import MicOffFilled from "@clout-team/icons/react/MicOffFilled";
import ShareScreen from "@clout-team/icons/react/ShareScreen";
import CircleButton from "../../pages/index/components/CircleButton/CircleButton";
import Pin from "@clout-team/icons/react/Pin";
import Mic from "@clout-team/icons/react/Mic";
import MicOff from "@clout-team/icons/react/MicOff";
import More from "@clout-team/icons/react/More";
import {PARTICIPANT_STATUS} from "../../constants/contants";


const Video = React.memo(() => {
  return <video autoPlay={true} playsInline={true} muted={true}/>;
})

let observer: any = null;
export const Participant: React.FC<IParticipant> = (props: IParticipant) => {
  const {
    id,
    fullName,
    avatar,
    stream,
    isHide = false,
    isSpeaking = false,
    isMutedAudio = false,
    isCalling = false,
    isHost = false,
    isSharing = false,
    isYou = false,
    isPinned = false,
    isConteinVideo = false,
    isShowButtons = true,
    isShowUnpinButton = false,
    isVideo = false,
    status = PARTICIPANT_STATUS.NONE,
    onPinClick = () => {},
    onUnPinClick = () => {},
    onMoreClick = () => {},
    onMicClick = () => {},
    onDoubleClick = (e) => {},
    onClick,
    isPinButton = true,
    isHideUserName = false,
    className,
    localUserId,
    userId,
    L,
    debug = undefined
  } = props;
  let ref: any = useRef(null);

  const classes = classNames(className, {
    'participant-card': true,
    'participant-card__speaking': isSpeaking,
    'participant-card__hide': isHide,
    'participant-card__contain-video': isConteinVideo,
    'participant-card__reverse-video': isYou,
    'participant-card__hide-video': !isVideo,
    'size-lg': ref?.current?.offsetWidth >= 580,
    'size-md': ref?.current?.offsetWidth < 580 && ref?.current?.offsetWidth >= 288,
    'size-sm': ref?.current?.offsetWidth < 288,
  })

  // useEffect(() => {
  //   const block = ref.current;
  //   if (block && userId && window.userPosters[userId]) {
  //     const video = block.querySelector('video');
  //     console.log(787878, window.userPosters[userId]);
  //     if (video) video.poster = window.userPosters[userId];
  //   }
  // }, [userId])

  useEffect(() => {
    const block = ref.current;
    if (block) {
      const video = block.querySelector('video');
      if (video) {
        if (stream && stream.getVideoTracks().length > 0 && isVideo) {
          // @ts-ignore
          window.Janus.attachMediaStream(video, stream);
          video.autoplay = true;
          video.playsInline = true;
        } else {
          video.srcObject = new MediaStream()
        }
      }
    }
  }, [stream, isVideo])

  useEffect(() => {
    const block = ref.current;
    if (block) {
      const resizeHandle = (entries: any) => {
        for (const entry of entries) {
          const width = entry.borderBoxSize ? entry.borderBoxSize[0].inlineSize : entry.contentRect.width
          if (width >= 580) {
            block.classList.add('size-lg');
            block.classList.remove('size-md', 'size-sm');
          } else if (width < 580 && width >= 288) {
            block.classList.add('size-md');
            block.classList.remove('size-lg', 'size-sm');
          } else {
            block.classList.add('size-sm');
            block.classList.remove('size-lg', 'size-md');
          }
        }
      }

      observer = new ResizeObserver(resizeHandle);
      observer.observe(block);
    }

    return () => {
      if (observer) observer.disconnect();
    }
  }, [])

  const renderFioBottom = useMemo(() => {

    const renderName = () => {
      if (isSharing && localUserId && userId && (userId / 1000) === localUserId) {
        return L('calls.you_are_sharing')
      } else if (isSharing && !isYou) {
        return L('calls.user_is_screen_sharing')?.replace('{__user__}', fullName)
      } else {
        if (isYou && !isCalling) return `${fullName} (${L('calls.you')})`
        else if (isCalling) return `${fullName} (${L('calls.right.calling')}...)`
        else return fullName;
      }
    }

    return <div className={classNames('participant-card__name', {
      'is-hide': isHideUserName
    })}>
      {isHost && (
        <TooltipWrap hint={L('calls.host')}>
          <span className={`participant-card__name-host`}><PersonFilled/></span>
        </TooltipWrap>
      )}
      {isMutedAudio && !isSharing &&
      <span className={`participant-card__name-mic`}><MicOffFilled width={16} height={16}/></span>}
      <span className={`participant-card__name-user`}>
        {renderName()}
      </span>
      {isSharing && !isYou && <span className={`participant-card__name-share`}><ShareScreen/></span>}
      <div className={'sky-bg'}/>
    </div>
  }, [L, isSharing, isYou, fullName, isCalling, isHideUserName, isHost, isMutedAudio])

  const renderPinButton = useMemo(() => {
    return <Layout gap={'xsm'} className={'participant-card__button-layout'}>
      <CircleButton
        onClick={onPinClick}
        icon={<Pin/>}
        hint={L('calls.move_to_stage')}
      /></Layout>
  }, [L, onPinClick])

  const renderGroupButton = useMemo(() => {
    return <Layout gap={'xsm'} className={'participant-card__button-layout'}>
      <CircleButton
        onClick={onMicClick}
        icon={isMutedAudio ? <MicOff/> : <Mic/>}
        hint={isMutedAudio ? L('calls.you_cant_turn_on_another') : L('calls.turn_off_the_user_microphone')}/>
      <CircleButton
        onClick={onMoreClick}
        icon={<More/>}
        hint={L('button.more_options')}
      />
    </Layout>
  }, [L, onMoreClick, onMicClick, isMutedAudio])

  const renderUnpinButton = useMemo(() => {
    return <div className={'participant-card__unpin'}>
      <TooltipWrap hint={L('calls.waiting.remove_from_stage')}>
        <Button
          className={'rounded-corners-8 custom-btn-style'}
          iconLeft={<Pin width={24} height={24}/>}
          color={'white'}
          onClick={onUnPinClick}
        >{L('calls.unpin')}</Button>
      </TooltipWrap>
    </div>
  }, [L, onUnPinClick])

  const renderAvatar = useMemo(() => {
    if (avatar) {
      return <Avatar
        size={'48'}
        type={'private'}
        src={avatar}
      />
    } else {
      return <div className={'resize-text'}>{fullName}</div>
    }
  }, [avatar, fullName])

  const renderStatus = useMemo(() => {
    if (status === PARTICIPANT_STATUS.CALLING) {
      return <Text align={'center'} className={'center-call-status'} type={'secondary'}>
        {`${L('calls.right.calling')}...`}
      </Text>
    } else if (status === PARTICIPANT_STATUS.CONNECTING) {
      return <Text align={'center'} className={'center-call-status'} type={'secondary'}>
        {`${L('calls.connecting')}...`}
      </Text>
    }
    return null;
  }, [L, status])

  const doubleHandle = useCallback((e: any) => {
    if (onDoubleClick) onDoubleClick(e);
  }, [onDoubleClick])

  const renderDebug = useMemo(() => {
    if (!debug || isYou) return null;
    return <div className={'pr-debug'}>
      <div id={`pr-bitrate-${userId}`}/>
      <div id={`pr-resolution-${userId}`}/>
      <div id={`pr-other-${userId}`}/>
    </div>
  }, [debug, userId, isYou])

  return (
    <div ref={ref} id={id} onClick={onClick} className={classes} onDoubleClick={doubleHandle}>
      <div className={'participant-card__content'}>
        {renderDebug}
        <Video/>
        <div className={'participant-card__content-status'}>
          {renderStatus}
          {renderAvatar}
        </div>
        {isShowButtons ?
          isPinButton ? renderPinButton : renderGroupButton
          : <></>}
        {isShowUnpinButton && isPinned && renderUnpinButton}
        {renderFioBottom}
      </div>
    </div>
  )
}
