export const USERS = {
  CALLING: 'calling',
  IN_MEETING: 'in_meeting',
  CANCELLED: 'cancelled',
  WAITING: 'waiting',
  GUEST: 'guest'
}

export const MEET_URL = process.env.REACT_APP_DEV_URL

export const UI_TYPE = {
  MAC: 'mac',
  WIN: 'win'
}
