import { Button, Layout, ReportProblemDialog, Text } from '@clout-team/web-components';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { sendMeetingRate } from '../../../../api/calls';
import { conferenceHashSelector, conferenceToken } from '../../../../redux/selectors/conference';
import RateItem from './RateItem';

const rateItems = [
  {
    name: "calls.rate.terrible",
    icon: "assets/rate/persevering_face.webp",
    id: 1,
  },
  {
    name: "calls.rate.bad",
    icon: "assets/rate/frowning_face.webp",
    id: 2,
  },
  {
    name: "calls.rate.okay",
    icon: "assets/rate/neutral_face.webp",
    id: 3,
  },
  {
    name: "calls.rate.good",
    icon: "assets/rate/slightly_smiling_face.webp",
    id: 4,
  },
  {
    name: "calls.rate.amazing",
    icon: "assets/rate/star_struck.webp",
    id: 5,
  },
]

const RateWrapper = ({ L }: { L: (key: string) => string }) => {
  const [selected, setSelected] = useState<number | undefined>(undefined),
    [requestSended, setSendRequst] = useState(false),
    token = useSelector(conferenceToken),
    hash = useSelector(conferenceHashSelector),
    [showReport, setShowReport] = useState<boolean>(false);

  const handleSendReport = () => {
    setShowReport(true)
  }
  const handleRateSelect = (id: number) => {
    sendMeetingRate(hash, token, id)
    setSendRequst(true)
  }
  useEffect(() => {
    if (requestSended) {
      const showMessage = setTimeout(() => {
        setSendRequst(false)
      }, 2000)
      return () => clearTimeout(showMessage)
    }
    return () => {

    }
  }, [requestSended])
  const rateItemsList = useMemo(() => {
    return rateItems.map((args, index) => {
      const isSelected = selected === args.id;
      return <RateItem
        disabled={(selected !== undefined && !isSelected)}
        key={index}
        icon={args.icon}
        name={L(args.name)}
        active={isSelected}
        onClick={() => {
          if (selected) {
            return false
          }
          setSelected(args.id)
          handleRateSelect(args.id)
        }}
      />
    })
  }, [L, handleRateSelect, selected])
  return (
    <>
      {
        showReport && <ReportProblemDialog
          onClose={() => {
            setShowReport(false)
          }}
          hash={hash}
          user_token={token}
        />
      }
      <Layout gap='md' vertical isAlignCenter className='rate-us-wrapper'>
        <div>
          {
            L("calls.title.evaluate_the_quality_of_the_call")
          }
        </div>
        <Layout gap='xlg' horizontal isAlignCenter>
          {rateItemsList}
        </Layout>
        <div className='rate-us-wrapper--button'>
          <Button
            className={classNames({
              "hide": requestSended
            })}
            inverted
            size="lg"
            onClick={handleSendReport}
          >
            {L("calls.rate.report_a_problem")}
          </Button>
          {
            <div className={classNames("thanks-for-feedback", {
              "show": requestSended
            })}>
              <Text type="secondary" size="body" className="">
                {L("calls.label.thanks_for_rate_us")}
              </Text>
            </div>
          }
        </div>
      </Layout>
    </>
  )
}

export default React.memo(RateWrapper);
