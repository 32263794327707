import React, {useEffect, useRef} from 'react'
import classNames from 'classnames'
import {Loading} from "@clout-team/web-components";
import { IVideoBlock } from './types'
import './VideoBlock.scss'

const VideoBlock = React.memo((props: IVideoBlock) => {
  const {
    stream,
    className,
    action,
    fullScreen,
    muted = false,
    loading = false,
    hide = false,
    isReverse = false
  } = props

  const videoClass = classNames(
    'video',
    {
      'hidden': hide,
      'full-video': fullScreen,
      'reverse-video': isReverse
    },
    className
  )

  const video = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (video && video.current) video.current.muted = muted;
  }, [muted])

  useEffect(() => {
    if (video && video.current) {
      if (typeof stream === 'string') {
        video.current.src = stream
      } else {
        video.current.srcObject = stream
      }
    }
  }, [stream])

  return (
    <div className={videoClass} onClick={action}>
      {loading && (
        <div className={`video-loading`}>
          <Loading />
        </div>
      )}
      <video ref={video} autoPlay={true} playsInline={true} />
    </div>
  )
})

export default VideoBlock;
