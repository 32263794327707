import { Layout, Text } from '@clout-team/web-components';
import classNames from 'classnames';
import React from 'react';
import './RateItem.scss'

const RateItem = (props: {
  active?: boolean,
  disabled?: boolean,
  icon?: string,
  name?: string,
  onClick?: () => void
}) => {
  const { active, icon, name, disabled, onClick } = props;
  return (
    <>
      <div onClick={onClick} className="rate-item-wrapper">
        <Layout vertical gap='sm' isAlignCenter>
          <Layout gap='lg' className={classNames("rate-item", {
            "active": active,
            "disabled": disabled,
          })}>
            <img loading={`lazy`} className='rate-item--icon' src={icon} />
          </Layout>
          <Text size="footnote" type="secondary">
            {name}
          </Text>
        </Layout>
      </div>
    </>
  )
}

export default React.memo(RateItem)