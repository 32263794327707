import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Button, sendToElectron, TooltipWrap} from "@clout-team/web-components";
import CallEnd from "@clout-team/icons/react/CallEnd";
import EndConferenceContext from "../EndConferenceContext/EndConferenceContext";
import ChangeHostContext from "../ChangeHostContext/ChangeHostContext";
import {uiSelectorIsSmall} from "../../../../redux/selectors/ui";
import classNames from "classnames";

const CallEndButton = React.memo((props: {
  hint: ReactElement
  L: (key: string) => void
}) => {
  const {hint, L} = props;
  const [context, setContext] = useState<any>(null),
    [changeHostContext, setChangeHostContext] = useState<any>(null),
    isSmall = useSelector(uiSelectorIsSmall)

  useEffect(() => {
    if (isSmall) setContext(null);
  }, [isSmall])

  const onClickEnd = useCallback(async (e: any) => {
    if (isSmall) {
      sendToElectron('request-focus-call')
      setTimeout(() => {
        const btns = document.querySelectorAll('.end-call-button');
        if (btns[1]) setContext(btns[1]);
      }, 500)
    } else {
      setContext(e.currentTarget);
    }
  }, [isSmall, context]);

  return (
    <>
      {changeHostContext && <ChangeHostContext align={changeHostContext} onClose={() => setChangeHostContext(null)}/>}
      {context && <EndConferenceContext
        align={context}
        onClose={() => setContext(null)}
        onChangeHost={() => {
          // таймаут так как не успевает закрыться предыдушее меню
          setTimeout(() => setChangeHostContext(context), 100)
          setContext(null);
        }}
      />}
      <TooltipWrap size={'big'} hint={hint}>
        <Button
          className={classNames('custom-btn-style rounded-corners-8 end-call-button')}
          size={'lg'}
          iconLeft={<CallEnd width={24} height={24}/>}
          color={'red'}
          onClick={onClickEnd}
        >{L('chat.button.leave')}</Button>
      </TooltipWrap>
    </>
  );
});

export default CallEndButton;
