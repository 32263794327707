import { Avatar, PageHeaderButton } from "@clout-team/web-components";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DIALOG_CONFIRM } from "../../../constants/contants";
import { profileCurrentSelector } from "../../../redux/selectors/users";
import { setConfirmType } from "../../../redux/slices/ui";
import {formatUserName} from "../../../helpers/functions";

const Profile = React.memo(() => {
  const profile = useSelector(profileCurrentSelector),
  dispatch = useDispatch()

  const clickHandle = useCallback(() => {
    dispatch(setConfirmType({type: DIALOG_CONFIRM.PROFILE_INFO, data: profile}));
  }, [dispatch, profile]);

  if (!profile) return <></>;
  return (
    <PageHeaderButton
      title={formatUserName(profile)}
      icon={<Avatar type="private" size={"24"} src={profile.avatar} />}
      onClick={clickHandle}
      withChevron={false}
    />
  );
});

export default Profile;
