import { createSelector } from '@reduxjs/toolkit';
import {RootState} from "../stores/callStore";

export const uiSelector = createSelector(
    (state: RootState) => state.ui,
    (ui) => ui
)

export const uiSelectorRightBar = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.rightBarType
)

export const uiSelectorSize = createSelector(
    (state: RootState) => state.ui,
    (ui) => ui.size
)

export const uiSelectorLayout = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.layout
)

export const uiSelectorIsSmall = createSelector(
    (state: RootState) => state.ui,
    (ui) => ui.size === 'S'
)

export const uiSelectorIsXSmall = createSelector(
    (state: RootState) => state.ui,
    (ui) => ui.size === 'XS'
)

export const uiSelectorAllSmall = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.size === 'XS' || ui.size === 'S'
)

export const uiSelectorIsFull = createSelector(
    (state: RootState) => state.ui,
    (ui) => ui.fullScreen
)

export const uiSelectorIsHide = createSelector(
    (state: RootState) => state.ui,
    (ui) => ui.isHide
)

export const uiSelectorIsMaximized = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.isMaximized
)

export const uiSelectorAppUi = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.appUi
)

export const uiSelectorError = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.error
)

export const uiSelectorOs = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.os
)

export const uiSelectorCommentsCounters = createSelector(
    (state: RootState) => state.ui,
    (ui) => ui.commentsCounters
)

export const uiSelectorIsPinned = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.isPinnedUI
)

export const uiSelectorAlertType = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.alertType
)

export const uiSelectorContextType = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.contextType
)

export const uiSelectorIsAnswer = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.isAnswer
)

export const uiSelectorRout = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.router
)
export const uiSelectorConfirmType = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.confirmType
)
export const uiSelectorPlugType = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.plugType
)
export const uiSelectorFirstBanner = createSelector(
    (state: RootState) => state.ui,
    (ui) => ui.firstBanner
)
export const uiSelectorIsAwaitStart = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.isAwaitStart
)
export const uiSelectorIsAwaitHostConfirm = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.isAwaitHostConfirm
)
export const uiSelectorIsShowWaitingEntry = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.isShowWaitingEntry
)
export const uiSelectorIsAwaitReady = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.isAwaitReady
)
export const uiSelectorIsPermissionAwaitRequest = createSelector(
  (state: RootState) => state.ui,
  (ui) => ui.isPermissionAwaitRequest
)
export const uiSelectorInviteNewPerson = createSelector(
  (state: RootState) => state.ui,
  (ui) => {
    return ui.inviteNewPerson
  }
)
export const uiSelectorFromWaiting = createSelector(
  (state: RootState) => state.ui,
  (ui) => {
    return ui.fromWaiting
  }
)
