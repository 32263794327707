import React, {Suspense, useEffect, useMemo, useRef} from 'react';
import {useSelector} from "react-redux";
import {uiSelectorRightBar} from "../../../../redux/selectors/ui";
import {API, Loading, Right, SocketView} from "@clout-team/web-components";
import classNames from "classnames";
import '../CommentsRightBar/CommentsRightBar.scss';
import {RIGHT_BAR_TYPES} from "../../../../constants/contants";
import {settingsSelectorIsInvertRightBar} from "../../../../redux/selectors/settings";
import {subjectSelector} from "../../../../redux/selectors/conference";
import _ from "lodash";
import callStore from "../../../../redux/stores/callStore";

const ParticipantsRightBar = React.lazy(() => import("../ParticipantsRightBar"));
const CommentsRightBar = React.lazy(() => import("../CommentsRightBar/CommentsRightBar"));

const RightBar = React.memo(() => {
    const rightBarType = useSelector(uiSelectorRightBar),
        isInvertRightBar = useSelector(settingsSelectorIsInvertRightBar),
        disableView = useRef(false),
        subject = useSelector(subjectSelector);

    useEffect(() => {

        const resizer: HTMLElement | null = document.querySelector('.resizer');
        if (resizer) {
            if (rightBarType !== '') resizer.style.display = 'block';
            else resizer.style.display = 'none';
        }
    }, [rightBarType])

    const renderLoad = useMemo(() => {
        return <div className={'rp-import-load'}>
            <Loading isNewDesign/>
        </div>
    }, []);

    useEffect(() => {
        if (rightBarType !== RIGHT_BAR_TYPES.COMMENTS && subject) {
            new API().chat().counters([subject]).then((counter) => {
                const store = callStore.getState();
                if (store.ui?.rightBarType !== RIGHT_BAR_TYPES.COMMENTS) {
                    callStore.dispatch({
                        type: 'ui/setCommentCounters',
                        payload: counter[subject]
                    });
                }
            });
        }
    }, [rightBarType, subject]);

    return <>

        {(rightBarType !== '') ? (
            <Right className={
                classNames(
                    'comments-right-bar',
                    {
                        "inverted-theme-aware": isInvertRightBar
                    }
                )}
            >
                <Suspense fallback={renderLoad}>
                    <div className={classNames(`right-bar-list`, {
                        [`right-bar-list-${RIGHT_BAR_TYPES.PARTICIPANTS}`]: rightBarType === RIGHT_BAR_TYPES.PARTICIPANTS,
                        [`right-bar-list-${RIGHT_BAR_TYPES.COMMENTS}`]: rightBarType === RIGHT_BAR_TYPES.COMMENTS
                    })}>
                        {rightBarType === RIGHT_BAR_TYPES.PARTICIPANTS && <ParticipantsRightBar/>}
                        {rightBarType === RIGHT_BAR_TYPES.COMMENTS && <CommentsRightBar disableView={disableView.current}/>}
                    </div>
                </Suspense>
            </Right>
        ) : <></>}
    </>
})

export default RightBar;
