import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import './LayoutGrid.scss'

let observer: ResizeObserver | null = null;

const LayoutGrid = (props: {
  childs: JSX.Element[]
}) => {
  const {childs} = props;
  const layoutRef = useRef<HTMLDivElement>(null);
  const MARGIN = 8, l = 0.5625, p = 1.777777778;

  const [size, setSize] = useState<Record<string, string | number | undefined>>({
    width: "",
    height: ""
  })

  const resizeHandle = () => {
    //TODO separate size function
    let arr: number[] = [], obj: any = {}
    let computedSize: number;
    let w = 0, h = 0;

    if( !layoutRef.current ) return;

      let width = layoutRef?.current.offsetWidth,
          height = layoutRef?.current.offsetHeight;

    if (width <= 168) {
      w = 160
    } else {

      // if( childs.length <= 2 ){
      // 1 вписание элементов вертикально в 1 линию,  по макс возможной высоте ячейки
      h = (height - (MARGIN * (childs.length - 1))) / childs.length;
      w = h * p;
      if (w > width) {

      } else {
        obj.q1 = w;
        arr.push(w)
      }

      // 2 вписание элементов в 1 линию вертикально, по макс возможной ширине элемента
      if (width * l * childs.length + MARGIN * (childs.length - 1) < height) {
        obj.q2 = width;
        arr.push(width)

      }

      // 3 вписание элементов в 1 линию горизонтально, по макс возможной ширине элемента
      w = (width - (MARGIN * (childs.length - 1))) / childs.length;
      h = w * l
      if (h > height) {

      } else {
        obj.q3 = w;
        arr.push(w)
      }


      // 4 вписание элементов в 1 линию горизонтально, по макс возможной высоте элемента
      if (height * p * childs.length + MARGIN * (childs.length - 1) < width) {
        obj.q4 = height * p;
        arr.push(height * p)
      }
    // }
    //   else
        if (childs.length > 2) {
        const brickDouble = Math.ceil(childs.length / 2)
        const marginDouble = brickDouble - 1
        const hD = (height - MARGIN) / 2
        const wD = (width - MARGIN) / 2


        // 5 вписание элементов в 2 горизонтальные линии по макс высоте элемента
        if (hD * p * brickDouble + MARGIN * marginDouble < width) {
          obj.q5 = (hD * p);
          arr.push(hD * p)
        }

        // 6 вписание элементов в 2 горизонтальные линии по макс ширине элемента
        w = (width - MARGIN * marginDouble) / brickDouble

        if (w * l * 2 + MARGIN > height) {

        } else {
          obj.q6 = w;
          arr.push(w)
        }

        // 7 вписание элементов в 2 вертикальные линий по макс ширине элемента

        if ((width - MARGIN) / 2 * l * brickDouble + MARGIN * marginDouble < height) {
          obj.q7 = wD;
          arr.push(wD)
        }

        // 8 вписание элементов в 2 вертикальные линии по макс высоте элемента

        w = (height - MARGIN * marginDouble) * p / brickDouble

        if (w * 2 + MARGIN > width) {

        } else {
          obj.q8 = w;
          arr.push(w)
        }

        if (childs.length > 6) {

          const brickTriple = Math.ceil(childs.length / 3)
          const marginTriple = brickTriple - 1
          const hT = (height - MARGIN * 2) / 3
          const wT = (width - MARGIN * 2) / 3


          // 9 вписание элементов в 3 горизонтальные линии по макс высоте элемента
          if (hT * p * brickTriple + MARGIN * marginTriple > width) {

          } else {
            obj.q9 = hT * p;
            arr.push(hT * p)
          }

          // 10 вписание элементов в 3 горизонтальные линии по макс ширине элемента
          w = (width - MARGIN * marginTriple) / brickTriple
          if ((w * l * 3 + 16) > height) {

          } else {
            obj.q10 = w;
            arr.push(w)
          }

          // 11 вписание элементов в 3 вертикальные линии по макс ширине элемента
          if (wT * l * brickTriple + MARGIN * marginTriple > height) {

          } else {
            obj.q11 = wT;
            arr.push(wT)
          }


          // 12 вписание элементов в 3 вертикальные линии по макс ширине элемента
          h = (height - MARGIN * marginTriple) / brickTriple * p
          if (h * 3 + MARGIN * 2 > width) {

          } else {
            obj.q12 = h;
            arr.push(h)
          }


          if (childs.length > 12) {

            const brickQuad = Math.ceil(childs.length / 4)
            const marginQuad = brickQuad - 1
            const wQ = (width - MARGIN * 3) / 4
            const hQ = (height - MARGIN * 3) / 4


            // 13 вписание элементов в 4 горизонтальные линии, по макс высоте элемента
            if (hQ * p * brickQuad + MARGIN * marginQuad > width) {

            } else {
              obj.q13 = hQ * p;
              arr.push(hQ * p)
            }

            // 14 вписание элементов в 4 горизонтальные линии по макс ширине элемента
            w = (width - MARGIN * marginQuad) / brickQuad
            if ((w * l * 4 + 24) > height) {

            } else {
              obj.q14 = w;
              arr.push(w)
            }

            // 15 вписание элементов в 4 вертикальные линии по макс ширине элемента
            if (wQ * l * brickQuad + MARGIN * marginQuad > height) {

            } else {
              obj.q15 = wQ;
              arr.push(wQ)
            }

            // 16 вписание элементов в 3 вертикальные линии по макс высоте элемента
            h = (height - MARGIN * marginQuad) / brickQuad * p
            if (h * 4 + MARGIN * 3 > width) {

            } else {
              obj.q16 = h;
              arr.push(h)
            }

            if (childs.length > 20) {

              const brickQuint = Math.ceil(childs.length / 5)
              const marginQuint = brickQuint - 1
              const wQu = (width - MARGIN * 4) / 5
              const hQu = (height - MARGIN * 4) / 5


              // 17 вписание элементов в 5 горизонтальных линий, по макс высоте элемента
              if (hQu * p * brickQuint + MARGIN * marginQuint > width) {


              } else {
                obj.q17 = hQu * p;
                arr.push(hQu * p)
              }

              // 18 вписание элементов в 5 горизонтальных линий по макс ширине элемента
              w = (width - MARGIN * marginQuint) / brickQuint
              if ((w * l * 5 + 32) > height) {

              } else {
                obj.q18 = w;
                arr.push(w)
              }

              // 19 вписание элементов в 5 вертикальных линий по макс ширине элемента
              if (wQu * l * brickQuint + MARGIN * marginQuint > height) {

              } else {
                obj.q19 = wQu;
                arr.push(wQu)
              }

              // 20 вписание элементов в 5 вертикальных линий по макс ширине элемента
              h = (height - MARGIN * marginQuint) / brickQuint * p
              if (h * 5 + MARGIN * 4 > width) {

              } else {
                obj.q20 = h;
                arr.push(h)
              }

            }


          }
        }
      }
      w = Math.max.apply(null, arr);
      arr = []
    }

    computedSize = w;

      setSize({
        width: `${parseInt(`${computedSize}`)}px`,
        height: `${parseInt(`${computedSize * l}`)}px`
      })
  }

  useEffect(() => {
    // квадратный корень из участников окргленный до ближайшего целого
    if( layoutRef.current && !observer ) {
      observer = new ResizeObserver(resizeHandle);
      observer.observe(layoutRef.current);

      resizeHandle();
    }

    return () => {
      if( observer ) {
        observer.disconnect();
        observer = null
      }
    };
  }, [childs.length])

  return <>
    <div ref={layoutRef} className={classNames({
      "meeting-grid-wrap": true,
    })} style={{
      width: "100%",
      height: "100%"
    }}>
      {childs.map((item, index) => {
        return <div key={index} className={classNames({
          "meeting-grid-wrap--item": true
        })} style={size}>
          {item}
        </div>
      })}
    </div>
  </>
}
export default LayoutGrid;
