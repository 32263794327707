import {USERS} from "../constants/meeting";
import callStore from "../redux/stores/callStore";
import {User} from "../interfaces/user";

export const createJoinedUser = (profile: any, data: any) => {
  const {token, isActive, video, isLocal} = data;

  let obj: any = {
    key: USERS.IN_MEETING,
    name: profile.name,
    surname: profile.surname,
    avatar: profile.avatar,
    id: parseInt(profile.id),
    answer: true,
    audio: true,
    isHost: profile.host,
    isOwner: profile.owner,
    cloutId: (profile.login && profile.domain) ? `${profile.login}@${profile.domain}` : ``,
    isShare: false,
    lastSpeak: 0,
  }

  if (token)
    obj.token = token;

  if (video)
    obj.video = video;

  if (isLocal)
    obj.isLocal = isLocal;

  if (isActive)
    obj.isActive = isActive;

  return obj;
}

export const getMe = () => {
  const store: any = callStore.getState(),
    users = store.meetingUsers.users;

  return users.find((v: User.IUsersTo) => v.isLocal)
}
