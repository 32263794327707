import * as lg from '../assets/locate/index';
import { Cookies } from "react-cookie"
const cookies = new Cookies();

const validLangs = [
  "ar", "cn", "cnSmpl", "de",
  "en", "enGB", "es", "esLa",
  "fr", "hi", "it", "ja", "ko",
  "pt", "ru"
]
export const getLang = () => {
  let userLang = "en";
  const _lg = {};

  const cookieLang = cookies.get('lang');

  validLangs.forEach(key => {
    if (navigator.language && navigator.language.indexOf(key) > -1) userLang = key
    _lg[key] = lg[key];
  });
  const localStorageLang = localStorage.getItem('lang')
  if (cookieLang && validLangs.includes(cookieLang)) userLang = cookieLang
  else if (localStorageLang && validLangs.includes(localStorageLang)) userLang = localStorageLang
  localStorage.setItem('lang', userLang)

  return {
    userLang: userLang,
    _lg: _lg
  }
}

export default getLang