import React, { useCallback, useMemo, useState } from "react";
import Mic from "@clout-team/icons/react/Mic";
import Speaker from "@clout-team/icons/react/Speaker";
import Video from "@clout-team/icons/react/Video";
import ChevronDown from "@clout-team/icons/react/ChevronDown";
import Checkmark from "@clout-team/icons/react/Checkmark";
import {
  Button,
  ContextualMenu,
  Layout,
  ListItem,
} from "@clout-team/web-components";
import "./SelectDevices.scss";
import { useSelector } from "react-redux";
import { settingsSelectorActiveDevices } from "../../../redux/selectors/settings";
import {
  devicesSelectorAudioDevices,
  devicesSelectorSpeakersDevices,
  devicesSelectorVideoDevices,
} from "../../../redux/selectors/devices";
import { ACTION_KEYS } from "../../../components/ContextMenus/UserSettings";
import { handleChangeDevice } from "../../../helpers/callsHelper";
import { conferencePermissions } from "../../../redux/selectors/conference";
import { IPermissions } from "../../../redux/slices/conferense";

const SelectDevices = () => {
  const activeDevices = useSelector(settingsSelectorActiveDevices),
    videoDevices = useSelector(devicesSelectorVideoDevices),
    audioDevices = useSelector(devicesSelectorAudioDevices),
    speakersDevices = useSelector(devicesSelectorSpeakersDevices),
    [contextAudio, setContextAudio] = useState<any>(null),
    [contextVideo, setContextVideo] = useState<any>(null),
    [contextSpeaker, setContextSpeaker] = useState<any>(null),
    lang = useSelector((state: any) => state.localization),
    L = useCallback(
      (key: string) => lang.translations[lang.locale][key],
      [lang]
    ),
    permissions: IPermissions = useSelector(conferencePermissions);


  const renderMic = useMemo(() => {
    let active = audioDevices.find((d) => d.deviceId === activeDevices.audio);

    return (
      <Button
        disabled={!permissions.microphone || !active}
        tooltip={{
          hint: L("calls.select_microphone"),
          className: "waiting-select-devices-buttons",
        }}
        className="rounded-corners-8"
        iconRight={<ChevronDown />}
        iconLeft={<Mic />}
        color="white"
        isStroke
        fullWidth
        onClick={(e) => {
          setContextAudio(e.currentTarget);
        }}
      >
        {
          !permissions.microphone ? L("calls.permission_needed") :
            !active ? L('settings.radio.default') : active?.label
        }
      </Button>
    );
  }, [L, activeDevices.audio, audioDevices, permissions.microphone]);

  const renderAudioOutput = useMemo(() => {
    const active = speakersDevices.find((d) => d.deviceId === activeDevices.speaker);

    return (
      <Button
        disabled={!active}
        tooltip={{
          hint: L("calls.select_speaker"),
          className: "waiting-select-devices-buttons",
        }}
        className="rounded-corners-8"
        iconRight={<ChevronDown />}
        iconLeft={<Speaker />}
        color="white"
        isStroke
        fullWidth
        onClick={(e) => {
          setContextSpeaker(e.currentTarget);
        }}
      >
        {!active ? L('settings.radio.default') : active?.label}
      </Button>
    );
  }, [L, activeDevices.speaker, speakersDevices]);

  const renderVideo = useMemo(() => {
    let active = videoDevices.find((d) => d.deviceId === activeDevices.video);

    return (
      <Button
        disabled={!permissions.camera || !active}
        tooltip={{
          hint: L("calls.select_camera"),
          className: "waiting-select-devices-buttons",
        }}
        className="rounded-corners-8"
        iconRight={<ChevronDown />}
        iconLeft={<Video />}
        color="white"
        isStroke
        fullWidth
        onClick={(e) => {
          setContextVideo(e.currentTarget);
        }}
      >
        {
          !permissions.camera ? L("calls.permission_needed") :
            !active ? L('settings.radio.default') : active?.label
        }
      </Button>
    );
  }, [L, activeDevices.video, permissions.camera, videoDevices]);

  const renderContextAudio = useMemo(() => {
    return (
      <ContextualMenu
        className="active-blue"
        closeClickedInside={false}
        isModal={true}
        alignToElement={contextAudio}
        fullWidth
        onClose={() => setContextAudio(null)}
      >
        {audioDevices &&
          audioDevices.map((d) => {
            return (
              <ListItem
                key={d.label}
                size="sm"
                title={d.label}
                iconRight={
                  activeDevices?.audio === d.deviceId ? <Checkmark /> : <></>
                }
                active={activeDevices?.audio === d.deviceId}
                onClick={() => {
                  if (activeDevices?.audio === d.deviceId) return;
                  handleChangeDevice(
                    d,
                    ACTION_KEYS.CHANGE_DEVICE_AUDIO,
                    true
                  );
                  setContextAudio(null);
                }}
              />
            );
          })}
      </ContextualMenu>
    );
  }, [activeDevices?.audio, audioDevices, contextAudio]);

  const renderContextSpeaker = useMemo(() => {
    return (
      <ContextualMenu
        className="active-blue"
        closeClickedInside={false}
        isModal={true}
        alignToElement={contextSpeaker}
        fullWidth
        onClose={() => setContextSpeaker(null)}
      >
        {speakersDevices &&
          speakersDevices.map((d) => {
            return (
              <ListItem
                key={d.label}
                size="sm"
                title={d.label}
                iconRight={
                  activeDevices?.speaker === d.deviceId ? <Checkmark /> : <></>
                }
                active={activeDevices?.speaker === d.deviceId}
                onClick={() => {
                  if (activeDevices?.speaker === d.deviceId) return;
                  handleChangeDevice(
                    d,
                    ACTION_KEYS.CHANGE_DEVICE_SPEAKER,
                    true
                  );
                  setContextSpeaker(null);
                }}
              />
            );
          })}
      </ContextualMenu>
    );
  }, [activeDevices?.speaker, contextSpeaker, speakersDevices]);

  const renderContextVideo = useMemo(() => {
    return (
      <ContextualMenu
        className="active-blue"
        closeClickedInside={false}
        isModal={true}
        alignToElement={contextVideo}
        fullWidth
        onClose={() => setContextVideo(null)}
      >
        {videoDevices &&
          videoDevices.map((d) => {
            return (
              <ListItem
                key={d.label}
                size="sm"
                title={d.label}
                iconRight={
                  activeDevices?.video === d.deviceId ? <Checkmark /> : <></>
                }
                active={activeDevices?.video === d.deviceId}
                onClick={() => {
                  if (activeDevices?.video === d.deviceId) return;
                  handleChangeDevice(
                    d,
                    ACTION_KEYS.CHANGE_DEVICE_VIDEO,
                    true
                  );
                  setContextVideo(null);
                }}
              />
            );
          })}
      </ContextualMenu>
    );
  }, [activeDevices?.video, contextVideo, videoDevices]);

  return (
    <div className="waiting-select-devices">
      {contextAudio && renderContextAudio}
      {contextSpeaker && renderContextSpeaker}
      {contextVideo && renderContextVideo}
      <Layout gap={"sm"}>
        {renderMic}
        {renderAudioOutput}
        {renderVideo}
      </Layout>
    </div>
  );
};

export default SelectDevices;
