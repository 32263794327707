export const meetLog =
  (window.location.host !== 'meet.lenzaos.com' && window.location.host !== 'app.meet.lenzaos.com') ?
    console.log.bind(console) :
    (...args) => {}

    export const getDate = () => {
      const dateFormat= new Date();
      return dateFormat.getDate()+
        "-"+(dateFormat.getMonth()+1)+
        "-"+dateFormat.getFullYear()+
        " "+dateFormat.getHours()+
        ":"+dateFormat.getMinutes()+
        ":"+dateFormat.getSeconds();
    }
