import React, { useCallback, useRef, useState } from "react";

import {
  Button,
  Dialog,
  Input,
  Text,
} from "@clout-team/web-components";
import { useSelector } from "react-redux";
import { settingsSelectorIsInvertRightBar } from "../../../redux/selectors/settings";
import classNames from "classnames";
import {conferenceHashSelector, conferenceSelector} from "../../../redux/selectors/conference";
import {renameTitle} from "../../../helpers/functions";

interface IDialogRenameMeeting {
  onClose: () => void;
}

const DialogRenameMeeting = (props: IDialogRenameMeeting) => {
  const { onClose } = props;
  const lang = useSelector((state: any) => state.localization),
    L = useCallback(
      (key: string) => lang.translations[lang.locale][key],
      [lang]
    ),
    invertedRight = useSelector(settingsSelectorIsInvertRightBar),
    link = useSelector(conferenceHashSelector),
  conference = useSelector(conferenceSelector);

  const title = useRef(conference.title);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleSetTitle = useCallback(() => {
    if (!title.current.length) {
      setShowErrorMessage(true);
    } else {
      renameTitle(title.current, link)
      onClose()
    }
  }, [title.current]);
  const handleChange = ({ target }) => {
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
    title.current = target.value;
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        className={classNames("dialog-rounded-corners", {
          "inverted-theme-aware": invertedRight,
        })}
        dialogTitle={{
          title: L("calls.label.rename_meeting"),
        }}
        dialogButtons={{
          right: [
            <Button
              size={"lg"}
              className="rounded-corners-8"
              color="white"
              inverted
              key={1}
              onClick={onClose}
            >
              {L("button.cancel")}
            </Button>,
            <Button
              size={"lg"}
              key={2}
              className="rounded-corners-8"
              onClick={handleSetTitle}
            >
              {L("button.continue")}
            </Button>,
          ],
        }}
      >
        <Text size="footnote" type="secondary">
          {L("calls.meeting_name")}
        </Text>
        <Input
          onChange={handleChange}
          errorMessage={
            showErrorMessage ? L("calls.the_field_must_be_filled") : ""
          }
          placeholder={L("calls.enter_meeting_name")}
          value={title.current}
        />
      </Dialog>
    </>
  );
};

export default DialogRenameMeeting;
