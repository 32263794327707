const regex = new RegExp(/\-[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\-/, 'gm');

const inString = (line: string = '') => {
    try {
        return Array.from(line.matchAll(regex)).map(v => v?.[0]).filter(Boolean);
    }
    catch (e) {
        console.error(e, line);
        return [];
    }
}

export default {
    inString
}