import {Cookies} from "react-cookie"
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";
import callStore from "../redux/stores/callStore";
import {setFullScreen, setPlugType, setRout} from "../redux/slices/ui";
import {PLUG_TYPES, ROUT_TYPES} from "../constants/contants";
import {setLocale} from "../redux/localization/actions";
import {Fullscreen} from "./fullScreen";
import {SOCKET_ACTIONS} from "../constants/socket";
import {Emit, getSocket} from "./socketHelper";
import {JanusSingleton} from "../janus/singleton";
import {endCall} from "./callsHelper";

const cookies = new Cookies();

export const endSession = () => {
  const store: any = callStore.getState(),
    isRememberUserName = store.settings.isRememberUserName,
    hash = store.conference.conference.hash,
    users = store.meetingUsers.users,
    isAwaitHostConfirm = store.ui.isAwaitHostConfirm

  if (isAwaitHostConfirm)
    Emit(SOCKET_ACTIONS.MEETING_JOIN_CANCEL, {link: hash})

  if (users.length === 1 && users[0].isHost)
    endCall(true, PLUG_TYPES.MEETING_OVER)

  JanusSingleton.leave();

  if (isRememberUserName === false) {
    cookies.remove('token');
  }
}

export const destroyConference = (typePlug: string) => {
  callStore.dispatch(setRout(ROUT_TYPES.FINISH));
  callStore.dispatch(setPlugType({type: typePlug}));

  JanusSingleton.destroy();
  const socket = getSocket();
  if (socket) socket.disconnect();
}

export const fullScreenHandle = () => {
  if (isElectron()) {
    // обработаем электрон
  } else {
    if (Fullscreen.active()) {
      Fullscreen.close();
      callStore.dispatch(setFullScreen(true));
    } else {
      Fullscreen.open(document.documentElement);
      callStore.dispatch(setFullScreen(false));
    }
  }
}

export const changeLang = (lang) => {
  callStore.dispatch(setLocale(lang));
  localStorage.setItem('lang', lang);
  cookies.set('lang', lang);
}

// TODO сделать экспорт этой функции из компонентов
export const settingsStorageKey = 'user-settings'
export const updateUserSettings = (settings: any) => {
  const storeName = `${window.user_hash ? `${window.user_hash}-` : ""}${settingsStorageKey}`
  const item = localStorage.getItem(storeName)
  if (item) {
    localStorage.setItem(
      storeName,
      JSON.stringify({
        ...JSON.parse(item),
        ...settings
      })
    )
  } else {
    localStorage.setItem(storeName, JSON.stringify(settings))
  }
  window.dispatchEvent(new Event('storage'));
}



