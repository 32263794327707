import {LAYOUTS, PARTICIPANT_STATUS, PLUG_TYPES, SHARE_ROLES, SOCKET_JANUS} from "../constants/contants";
import callStore from "../redux/stores/callStore";
import {setRoomId} from "../redux/slices/conferense";
import {checkShareUser, deleteUserAsync, setUserAsync, updateUser} from "../redux/slices/users";
import {setLocal} from "../redux/slices/debug";
import joinSound from "../audio/enter_in_room.mp3";
import {Emit, emitCustomEvent, emitLog} from "../helpers/socketHelper";
import {SOCKET_ACTIONS} from "../constants/socket";
import {USERS} from "../constants/meeting";
import {setIsAnswer, setLayout} from "../redux/slices/ui";
import {addSpeakEvent, createAudio, endCall, joinUser} from "../helpers/callsHelper";
import startShare from "../audio/start_share.mp3";
import {attachStreamVideo, formatUserName, playSounds, removeStream} from "../helpers/functions";
import {getDate, meetLog} from "../helpers/log";
import {io} from "socket.io-client";

let janus = null,
    sfutest = null,
    roomId = 0,
    myusername = null,
    myid = null,
    mystream = null,
    mypvtid = null;

const canvas = document.createElement("canvas");

const iceServers = null,
    opaqueId = "videoroomtest-" + window.Janus.randomString(12),
    bitrateTimer = [], posterTimer = {}

window.userPosters = {}
window.usersStreams = {
    aInternal: {},
    aListener: function (val) {
    },
    set streams(val) {
        this.aInternal = val;
        this.aListener(val);
    },
    get streams() {
        return this.aInternal;
    },
    registerListener: function (listener) {
        this.aListener = listener;
    }
}

let remoteFeed = null
let feeds = {}, feedStreams = {}, subStreams = {}, slots = {}, mids = {}, subscriptions = {};
let localTracks = {}, localVideos = 0, remoteTracks = {}
let simulcastStarted = {};

const doSimulcast = (getQueryStringValue("simulcast") === "yes" || getQueryStringValue("simulcast") === "true");
const acodec = (getQueryStringValue("acodec") !== "" ? getQueryStringValue("acodec") : null);
const vcodec = (getQueryStringValue("vcodec") !== "" ? getQueryStringValue("vcodec") : null);
const use_msid = (getQueryStringValue("msid") === "yes" || getQueryStringValue("msid") === "true");

const SingletonJanus = (function () {
    class JanusIn {
        constructor() {
            this.init()
        }

        init() {
            window.Janus.init({
                debug: "all",
                dependencies: window.Janus.useDefaultDependencies(),
                WebSocket: io,
                callback: function () {
                    janus = new window.Janus(
                        {
                            server: SOCKET_JANUS,
                            iceServers: iceServers,
                            success: function () {
                                JanusSingleton.attachVideoroom()
                            },
                            error: function (error) {
                                window.Janus.error(error);
                                emitLog({
                                    roomId,
                                    message: `Janus init error - ${JSON.stringify(error)}`,
                                    date: getDate()
                                })
                            },
                            destroyed: function () {
                                emitLog({
                                    roomId,
                                    message: `Janus destroyed`,
                                    date: getDate()
                                })
                                window.userPosters = {};
                                if (sfutest) sfutest.hangup()
                                Object.values(window.usersStreams.streams).forEach(s => removeStream(s))
                                Object.values(posterTimer).forEach(t => clearInterval(t))
                                window.usersStreams.streams = {};
                                if (window.waitingStream.stream) removeStream(window.waitingStream.stream)
                                window.waitingStream.stream = null;
                                localTracks = {}
                                remoteTracks = {}
                            }
                        });

                }
            });
        }

        leave() {
            const {conference: {conference: {hash}}} = callStore.getState();
            emitCustomEvent({
                event: 'im_leave_meet', data: {
                    link: hash
                }
            })
            if (sfutest) {
                sfutest.send({message: {request: "leave"}});
            }
        }

        getJanus() {
            return janus;
        }

        destroy() {
            janus?.destroy();
        }

        reconnect() {
            sfutest?.createOffer(
                {
                    iceRestart: true,
                    success: function (jsep) {
                        sfutest?.send({message: {audio: true, video: localUser?.video}, jsep: jsep});
                    }
                });

            emitLog({
                roomId,
                message: `Janus socket reconnect start`,
                session: sfutest.getId(),
                date: getDate(),
                publisher: true
            })

            const {meetingUsers: {users}} = callStore.getState(),
                localUser = users.find(u => u.isLocal)

            janus?.reconnect({
                success: () => {
                    emitLog({
                        roomId,
                        message: "Janus socket reconnect success",
                        session: sfutest.getId(),
                        date: getDate(),
                        publisher: true
                    })

                    // remoteFeed?.createOffer(
                    //   {
                    //     iceRestart: true,
                    //     success: function (jsep) {
                    //       remoteFeed?.send({message: {audio: true, video: true}, jsep: jsep});
                    //     }
                    //   });
                }
            });
        }

        iceRestart() {


            // subscriptions = {};
            // const sources = [];
            //
            // Object.keys(feedStreams).forEach(userId => {
            //   if (localUser.id !== userId) {
            //     sources.push(feedStreams[userId].streams);
            //   }
            // })
            //
            // console.log(777, feedStreams);
            // console.log(888, subscriptions);
            // console.log(999, sources);
            //
            // subscribeTo(sources);
            //
            // sfutest?.createOffer(
            //   {
            //     iceRestart: true,
            //     success: function (jsep) {
            //       sfutest?.send({message: {audio: true, video: localUser?.video}, jsep: jsep});
            //     }
            //   });
            //
            // remoteFeed?.createOffer(
            //   {
            //     iceRestart: true,
            //     success: function (jsep) {
            //       remoteFeed?.send({message: {audio: true, video: true}, jsep: jsep});
            //     }
            //   });

        }

        detach() {
            sfutest?.detach();
        }

        hangup() {
            sfutest?.hangup();
        }

        attachVideoroom() {
            if (!janus) return;
            janus.attach(
                {
                    plugin: "janus.plugin.videoroom",
                    opaqueId: opaqueId,
                    success: function (pluginHandle) {
                        sfutest = pluginHandle;
                        meetLog("Plugin attached! (" + sfutest.getPlugin() + ", id=" + sfutest.getId() + ")");
                        meetLog("  -- This is a publisher/manager");
                        emitLog({
                            roomId,
                            message: "Plugin attached! (" + sfutest.getPlugin() + ", id=" + sfutest.getId() + ")",
                            session: sfutest.getId(),
                            date: getDate(),
                            publisher: true
                        })
                    },
                    error: function (error) {
                        window.Janus.error("  -- Error attaching plugin...", error);
                        emitLog({
                            roomId,
                            message: `Error attaching plugin - ${JSON.stringify(error)}`,
                            session: sfutest.getId(),
                            date: getDate(),
                            publisher: true
                        })
                    },
                    consentDialog: function (on) {
                        window.Janus.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
                        emitLog({
                            roomId,
                            message: "Consent dialog should be " + (on ? "on" : "off") + " now",
                            session: sfutest.getId(),
                            date: getDate(),
                            publisher: true,
                        })
                    },
                    iceState: function (state) {
                        meetLog("ICE state changed to " + state);
                        emitLog({
                            roomId,
                            message: "iceState - ICE локальный изменен на " + state,
                            session: sfutest.getId(),
                            date: getDate(),
                            publisher: true,
                        })
                    },
                    mediaState: function (medium, on, mid) {
                        meetLog("Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")");
                        emitLog({
                            roomId,
                            message: "mediaState - Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")",
                            session: sfutest.getId(),
                            date: getDate(),
                            publisher: true,
                        })
                    },
                    webrtcState: function (on) {
                        meetLog("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
                        emitLog({
                            roomId,
                            message: "webrtcState - Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now",
                            session: sfutest.getId(),
                            date: getDate(),
                            publisher: true,
                        })
                        if (!on) return;
                        // send bitrate limit
                        // sfutest.send({message: {request: "configure", bitrate: bitrate}});
                    },
                    slowLink: function (uplink, lost, mid) {
                        window.Janus.warn("Janus reports problems " + (uplink ? "sending" : "receiving") +
                            " packets on mid " + mid + " (" + lost + " lost packets)");
                    },
                    onmessage: function (msg, jsep) {

                        console.log("[Janus] NEW MESSAGE", msg, jsep);

                        emitLog({
                            roomId,
                            message: `Onmessage - ${JSON.stringify(msg)}`,
                            session: sfutest.getId(),
                            date: getDate(),
                            publisher: true,
                        })
                        const store = callStore.getState(),
                            users = store.meetingUsers.users.slice(),
                            localUser = users.find(u => u.isLocal === true);

                        sfutest.send({
                            message: {
                                request: "listparticipants",
                                room: roomId
                            },
                            success: (res) => {
                                if (res?.participants && Array.isArray(res?.participants)) {
                                    res.participants.forEach((user) => {
                                        if (user.id !== 1 && user.display) {
                                            let userData = JSON.parse(user.display);

                                            if (
                                                (userData.isShare && !user.publisher) ||
                                                (localUser.id === parseInt(userData.id))
                                            ) return;

                                            if (!users.find(usr => usr.id === parseInt(userData.id))) {

                                                callStore.dispatch(setUserAsync({
                                                    isPinned: false,
                                                    status: PARTICIPANT_STATUS.NONE,
                                                    key: USERS.IN_MEETING,
                                                    name: userData.name,
                                                    surname: userData.surname,
                                                    avatar: userData.avatar,
                                                    id: parseInt(userData.id),
                                                    answer: true,
                                                    audio: userData.audio,
                                                    video: userData.video,
                                                    isShare: !!userData.isShare,
                                                    isLoadingShare: !!userData.isShare,
                                                    isActive: true,
                                                    isHost: userData.isHost,
                                                    isOwner: userData.isOwner,
                                                    cloutId: userData.cloutId,
                                                    lastSpeak: 0
                                                }));

                                                if (userData.isShare) {
                                                    localStorage.setItem('initial-layout-type', store.ui.layout)
                                                    callStore.dispatch(setLayout(LAYOUTS.STACK));
                                                } else {
                                                    joinUser();
                                                }
                                            }

                                        }
                                    })
                                }
                            }
                        });

                        window.Janus.debug(" ::: Got a message (publisher) :::", msg);
                        let event = msg["videoroom"];
                        window.Janus.debug("Event: " + event);
                        if (event !== undefined && event !== null) {
                            if (event === "joined") {
                                myid = msg["id"];
                                mypvtid = msg["private_id"];
                                meetLog("Successfully joined room " + msg["room"] + " with ID " + myid);
                                emitLog({
                                    roomId,
                                    message: "Успешно присоединился к комнате " + msg["room"] + " с ID " + myid,
                                    session: sfutest.getId(),
                                    date: getDate(),
                                    publisher: true,
                                })
                                publishOwnFeed(true, localUser?.video);

                                if (msg["publishers"]) {
                                    let list = msg["publishers"];
                                    window.Janus.debug("Got a list of available publishers/feeds:", list);
                                    const sources = list.map(it => {
                                        if (it["dummy"]) return null;
                                        let id = it["id"];
                                        let display = it["display"];
                                        let streams = it["streams"];
                                        for (let i in streams) {
                                            let stream = streams[i];
                                            stream["id"] = id;
                                            stream["display"] = display;
                                        }
                                        feedStreams[id] = {
                                            id: id,
                                            display: display,
                                            streams: streams,
                                            slot: feedStreams[id] ? feedStreams[id].slot : null,
                                            remoteVideos: feedStreams[id] ? feedStreams[id].remoteVideos : 0
                                        }
                                        window.Janus.debug("  >> [" + id + "] " + display + ":", streams);
                                        return streams;
                                    }).filter(Boolean);
                                    if (sources?.length > 0) {
                                        console.log("subscribeTo", list, sources);
                                        subscribeTo(sources);
                                    }
                                }
                            }
                            else if (event === "destroyed") {
                                window.Janus.warn("The room has been destroyed!");
                                emitLog({
                                    roomId,
                                    message: "Комната была закрыта",
                                    session: sfutest.getId(),
                                    date: getDate(),
                                    publisher: true,
                                })
                            }
                            else if (event === "event") {
                                // Any info on our streams or a new feed to attach to?
                                if (msg["streams"]) {
                                    let streams = msg["streams"];
                                    for (let i in streams) {
                                        let stream = streams[i];
                                        stream["id"] = myid;
                                        stream["display"] = myusername;
                                    }
                                    feedStreams[myid] = {
                                        id: myid,
                                        display: myusername,
                                        streams: streams
                                    }
                                }
                                else if (msg["publishers"]) {
                                    let list = msg["publishers"];
                                    window.Janus.debug("Got a list of available publishers/feeds:", list);

                                    const sources = list.map(it => {
                                        if (it["dummy"]) return null;
                                        let id = it["id"];
                                        let display = it["display"];
                                        let streams = it["streams"];
                                        for (let i in streams) {
                                            let stream = streams[i];
                                            stream["id"] = id;
                                            stream["display"] = display;
                                        }
                                        feedStreams[id] = {
                                            id: id,
                                            display: display,
                                            streams: streams,
                                            slot: feedStreams[id] ? feedStreams[id].slot : null,
                                            remoteVideos: feedStreams[id] ? feedStreams[id].remoteVideos : 0
                                        }
                                        window.Janus.debug("  >> [" + id + "] " + display + ":", streams);
                                        return streams;
                                    }).filter(Boolean);
                                    if (sources.length > 0) {
                                        console.log("subscribeTo", sources);
                                        subscribeTo(sources);
                                    }
                                }
                                else if (msg["leaving"]) {
                                    let leaving = msg["leaving"];
                                    meetLog("Publisher left: " + leaving);
                                    emitLog({
                                        roomId,
                                        message: "Publisher left: " + leaving,
                                        session: sfutest.getId(),
                                        date: getDate(),
                                        publisher: true,
                                    })
                                    if (leaving === 'ok') {
                                        endCall(false, PLUG_TYPES.I_WAS_KICK)
                                    } else {
                                        unsubscribeFrom(leaving);
                                        callStore.dispatch(deleteUserAsync(parseInt(leaving)))
                                    }
                                }
                                else if (msg["kicked"]) {
                                    meetLog("Publisher kicked: " + msg["kicked"]);
                                    emitLog({
                                        roomId,
                                        message: "Publisher kicked: " + msg["kicked"],
                                        session: sfutest.getId(),
                                        date: getDate(),
                                        publisher: true,
                                    })
                                    unsubscribeFrom(msg["kicked"]);
                                    callStore.dispatch(deleteUserAsync(msg["kicked"]))
                                }
                                else if (msg["unpublished"]) {
                                    // One of the publishers has unpublished?
                                    let unpublished = msg["unpublished"];
                                    meetLog("Publisher left: " + unpublished);
                                    emitLog({
                                        roomId,
                                        message: "Publisher unpublished (left): " + unpublished,
                                        session: sfutest.getId(),
                                        date: getDate(),
                                        publisher: true,
                                    })
                                    if (unpublished === 'ok') {
                                        sfutest.hangup();
                                        return;
                                    }
                                    unsubscribeFrom(unpublished);
                                    callStore.dispatch(deleteUserAsync(parseInt(unpublished)))
                                }
                                else if (msg["error"]) {
                                    if (msg["error_code"] === 426) {
                                        meetLog("Error 426: ", msg);
                                    } else {
                                        meetLog(msg["error"]);
                                    }
                                }
                            }
                        }
                        if (jsep) {
                            window.Janus.debug("Handling SDP as well...", jsep);
                            sfutest.handleRemoteJsep({jsep: jsep});
                            let audio = msg["audio_codec"];
                            if (mystream && mystream.getAudioTracks() && mystream.getAudioTracks().length > 0 && !audio) {
                                meetLog("Our audio stream has been rejected, viewers won't hear us");
                            }
                            let video = msg["video_codec"];
                            if (mystream && mystream.getVideoTracks() && mystream.getVideoTracks().length > 0 && !video) {
                                meetLog("Our video stream has been rejected, viewers won't see us");
                            }
                        }
                    },
                    onlocaltrack: function (track, on) {
                        const store = callStore.getState(),
                            hash = store.conference.conference.hash,
                            iAm = {...store.meetingUsers.users.find((u) => u.isLocal)}

                        if (!iAm.audio) sfutest?.muteAudio();
                        window.Janus.debug(" ::: Got a local track event :::");
                        window.Janus.debug("Local track " + (on ? "added" : "removed") + ":", track);
                        emitLog({
                            roomId,
                            message: "Локальный трек " + (on ? "добавлен" : "удален") + " - " + JSON.stringify({
                                kind: track.kind,
                                contentHint: track.contentHint,
                                enabled: track.enabled,
                                id: track.id,
                                label: track.label,
                                muted: track.muted
                            }),
                            session: sfutest.getId(),
                            date: getDate(),
                            publisher: true,
                        })
                        let trackId = track.id.replace(/[{}]/g, "");
                        if (!on) {
                            // Track removed, get rid of the stream and the rendering
                            let stream = localTracks[trackId];
                            if (stream) {
                                try {
                                    let tracks = stream.getTracks();
                                    for (let i in tracks) {
                                        let mst = tracks[i];
                                        if (mst) mst.stop();
                                    }
                                } catch (e) {

                                }
                            }
                            if (track.kind === "video") {
                                localVideos--;
                                if (localVideos === 0) {
                                    // No video, at least for now: show a placeholder
                                    meetLog('No webcam available');
                                }

                                callStore.dispatch(setLocal({video: null}))
                            } else {
                                callStore.dispatch(setLocal({audio: null}))
                            }
                            delete localTracks[trackId];
                            // if (window.usersStreams.streams[iAm.id]) delete window.usersStreams.streams[iAm.id];
                            return;
                        }
                        // If we're here, a new track was added
                        let stream = localTracks[trackId];
                        if (stream) return;

                        if (track.kind === "audio") {
                            addSpeakEvent(new MediaStream([track]), iAm.id);
                            // We ignore local audio tracks, they'd generate echo anyway
                            if (localVideos === 0) {
                                // No video, at least for now: show a placeholder
                                meetLog('No webcam available');
                            }

                            emitLog({
                                roomId,
                                userId: iAm.id,
                                message: `Добавлен локальный аудиотрек ${formatUserName(iAm)} - ${JSON.stringify({
                                    kind: track.kind,
                                    contentHint: track.contentHint,
                                    enabled: track.enabled,
                                    id: track.id,
                                    label: track.label,
                                    muted: track.muted
                                })}`,
                                session: sfutest.getId(),
                                date: getDate(),
                                publisher: true,
                            })

                            callStore.dispatch(setLocal({
                                userId: iAm.id,
                                userName: formatUserName(iAm),
                                roomId: roomId,
                                audio: {
                                    label: track.label,
                                    kind: track.kind,
                                    muted: track.muted,
                                    enabled: track.enabled
                                }
                            }))
                        } else {
                            // New video track: create a stream out of it
                            const settings = track.getSettings();

                            emitLog({
                                roomId,
                                userId: iAm.id,
                                message: `Добавлен локальный видеотрек ${formatUserName(iAm)} - ${JSON.stringify({
                                    kind: track.kind,
                                    contentHint: track.contentHint,
                                    enabled: track.enabled,
                                    id: track.id,
                                    label: track.label,
                                    muted: track.muted,
                                    width: settings?.width,
                                    height: settings?.height
                                })}`,
                                session: sfutest.getId(),
                                date: getDate(),
                                publisher: true,
                            })

                            callStore.dispatch(setLocal({
                                userName: formatUserName(iAm),
                                roomId: roomId,
                                video: {
                                    label: track.label,
                                    kind: track.kind,
                                    muted: track.muted,
                                    enabled: track.enabled,
                                    resolution: {
                                        width: settings?.width,
                                        height: settings?.height
                                    }
                                }
                            }))

                            localVideos++;
                            stream = new MediaStream([track]);
                            localTracks[trackId] = stream;
                            attachStreamVideo(iAm.id, stream)
                            meetLog("Created local stream:", stream);
                            emitLog({
                                roomId,
                                userId: iAm.id,
                                message: "Создан локальный стрим",
                                session: sfutest.getId(),
                                date: getDate(),
                                publisher: true,
                            })

                            if (track.muted === false) {

                            } else {

                            }

                            if (Object.keys(iAm).length > 0) {
                                if (iAm.isHost) {
                                    Emit(SOCKET_ACTIONS.MEETING_GET_ENTRY, {link: hash})
                                }
                                window.usersStreams.streams[iAm.id] = localTracks[trackId];

                                callStore.dispatch(updateUser({
                                    ...iAm,
                                    key: USERS.IN_MEETING,
                                }))

                                // дождемся пока поток уже точно начал передачу видео, чтобы не было морганий
                                setTimeout(() => {
                                    callStore.dispatch(updateUser({id: iAm.id, isLoadingVideo: false}))
                                }, 1000)
                            }

                        }

                        if (sfutest.webrtcStuff.pc.iceConnectionState !== "completed" &&
                            sfutest.webrtcStuff.pc.iceConnectionState !== "connected") {
                            meetLog('Publishing...');
                        }
                    },
                    onremotetrack: function (track, mid, on) {
                        // The publisher stream is sendonly, we don't expect anything here
                    },
                    oncleanup: function () {
                        meetLog(" ::: Got a cleanup notification: we are unpublished now :::");
                        emitLog({
                            roomId,
                            message: "Got a cleanup notification: we are unpublished now",
                            session: sfutest.getId(),
                            date: getDate(),
                            publisher: true,
                        })
                        mystream = null;
                        delete feedStreams[myid];
                        localTracks = {};
                        localVideos = 0;
                    }
                });
        }

        isAudioMuted() {
            return sfutest?.isAudioMuted()
        }

        muteAudio() {
            sfutest?.muteAudio();
            this.changeDisplay();
        }

        unmuteAudio() {
            sfutest?.unmuteAudio();
            this.changeDisplay();
        }

        muteVideo() {
            sfutest?.muteVideo();
            this.changeDisplay();

            // TODO для потухания камеры
            /*sfutest?.createOffer(
              {
                tracks: [{ type: 'video', mid: '1', remove: true }],
                success: function(jsep) {
                  sfutest?.send({ message: { video: true }, jsep: jsep })
                },
                error: function(error) {
                  window.Janus.error('WebRTC error... ', error.message);
                }
              });*/
        }

        unmuteVideo() {
            const store = callStore.getState(),
                meetingUsers = store.meetingUsers.users,
                iAm = meetingUsers.find((v) => v.isLocal)

            this.changeDisplay();
            if (
                iAm &&
                !window.usersStreams.streams[iAm.id]
            ) {
                const devices = store.settings.activeDevices;

                let v = devices.video;
                if (!v) v = true

                sfutest?.createOffer(
                    {
                        tracks: [{
                            type: 'video',
                            capture: typeof v === 'boolean' ? v : {
                                deviceId: v,
                                width: {min: 640, ideal: 1280, max: 1280},
                                height: {min: 480, ideal: 720, max: 720}
                            },
                            recv: true,
                            simulcast: doSimulcast,
                            add: true
                        }],
                        success: function (jsep) {
                            let publish = {request: "configure", video: true};
                            if (vcodec) publish["videocodec"] = vcodec;
                            sfutest?.send({message: publish, jsep: jsep});
                        },
                        error: function (error) {
                            emitLog({
                                roomId,
                                message: "unmuteVideo WebRTC error - " + JSON.stringify(error.message),
                                session: sfutest.getId(),
                                date: getDate(),
                                publisher: true,
                            })
                            window.Janus.error('WebRTC error... ', error.message);
                        }
                    });
                return;
            }

            sfutest?.unmuteVideo();
        }

        changeDisplay() {
            const {meetingUsers: {users}} = callStore.getState(),
                localUser = users.find(u => u.isLocal)

            sfutest?.send({
                message:
                    {
                        request: "configure", display: JSON.stringify({
                                id: localUser.id,
                                name: localUser.name,
                                surname: localUser.surname,
                                avatar: localUser.avatar,
                                video: localUser.video,
                                audio: localUser.audio,
                                isHost: localUser.isHost,
                                isOwner: localUser.isOwner,
                                cloutId: localUser.cloutId || '',
                            }
                        )
                    }
            });
        }

        joinToJanus(data) {
            callStore.dispatch(setRoomId(data.room_id));
            const store = callStore.getState();
            const meetingUsers = store.meetingUsers.users,
                iAm = meetingUsers.find((v) => v.isLocal);

            let profile = data.participants.find((u) => u.isLocal === true);

            if (profile) {
                if (iAm) {
                    const local = Object.assign({}, iAm);
                    local.isHost = profile.host;
                    local.name = profile.name;
                    local.avatar = profile.avatar;
                    local.isOwner = profile.owner;
                    local.cloutId = (profile.login && profile.domain) ? `${profile.login}@${profile.domain}` : ``;
                    local.token = data.token;

                    callStore.dispatch(updateUser(local));
                }
                roomId = parseInt(data.room_id)

                emitLog({
                    roomId,
                    userId: profile.id,
                    message: `join to media server - ${JSON.stringify({
                        video: iAm.video,
                        audio: iAm.audio,
                        isHost: profile.host,
                        isOwner: profile.owner,
                        userName: formatUserName(profile),
                    })}`,
                    session: sfutest?.getId(),
                    date: getDate(),
                    publisher: true,
                })

                sfutest.send({
                    message: {
                        request: "join",
                        room: roomId,
                        ptype: SHARE_ROLES.PUBLISHER,
                        display: JSON.stringify({
                            id: profile.id,
                            name: profile.name,
                            surname: profile.surname,
                            avatar: profile.avatar,
                            video: iAm.video,
                            audio: iAm.audio,
                            isHost: profile.host,
                            isOwner: profile.owner,
                            cloutId: (profile.login && profile.domain) ? `${profile.login}@${profile.domain}` : ``,
                        }),
                        token: data.token,
                        id: profile.id
                    }
                });

                playSounds(joinSound, 2500);
            }
        }


    }

    let instance;
    return function () {
        if (!instance) {
            instance = new JanusIn()
            delete instance.constructor
        }
        return instance
    }
})()
export const JanusSingleton = SingletonJanus()


// Helper to parse query string
function getQueryStringValue(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function publishOwnFeed(useAudio = false, useVideo = false) {
    const tracks = [];
    const store = callStore.getState(),
        devices = store.settings.activeDevices

    let a = devices.audio, v = devices.video;
    if (!a) a = true
    if (!v) v = true

    tracks.push({
        type: 'audio',
        capture: typeof a === 'boolean' ? a : {
            deviceId: a,
            width: {min: 640, ideal: 1280, max: 1920},
            height: {min: 480, ideal: 720, max: 1080}
        },
        recv: true
    });

    if (useVideo) tracks.push({
        type: 'video',
        capture: typeof v === 'boolean' ? v : {
            deviceId: v,
            width: {min: 640, ideal: 1280, max: 1920},
            height: {min: 480, ideal: 720, max: 1080}
        },
        recv: true,
        simulcast: doSimulcast
    });

    emitLog({
        roomId,
        message: `createOffer start - ${JSON.stringify({
            video: useVideo,
            deviceIdVideo: v,
            deviceIdAudio: a,
            audio: useAudio,
        })}`,
        session: sfutest?.getId(),
        date: getDate(),
        publisher: true,
    })

    sfutest.createOffer(
        {
            tracks: tracks,
            success: function (jsep) {
                window.Janus.debug("Got publisher SDP!");
                window.Janus.debug(jsep);
                let publish = {request: "configure", audio: useAudio, video: useVideo};

                if (acodec) publish["audiocodec"] = acodec;
                if (vcodec) publish["videocodec"] = vcodec;

                console.log("publishOwnFeed", devices, tracks, {
                    deviceIdVideo: v,
                    deviceIdAudio: a
                }, publish);

                sfutest.send({message: publish, jsep: jsep});

                emitLog({
                    roomId,
                    message: `createOffer success - ${JSON.stringify({
                        video: useVideo,
                        deviceIdVideo: v,
                        deviceIdAudio: a,
                        audio: useAudio,
                    })}`,
                    session: sfutest?.getId(),
                    date: getDate(),
                    publisher: true,
                })
            },
            error: function (error) {
                window.Janus.error("WebRTC error:", error);
                emitLog({
                    roomId,
                    message: `createOffer error - ${JSON.stringify(error)}`,
                    session: sfutest?.getId(),
                    date: getDate(),
                    publisher: true,
                })
                if (useAudio) {
                    publishOwnFeed();
                }
            }
        });
}

export function changeUserDevice(props) {
    const {deviceId, isAudio, isVideo} = props;

    if (!deviceId) return;
    const tracks = [];

    if (isAudio) {
        tracks.push({
            type: 'audio',
            mid: '0',	// We assume mid 0 is audio
            capture: {deviceId: {exact: deviceId}}
        });
    }

    if (isVideo) {
        tracks.push({
            type: 'video',
            mid: '1',	// We assume mid 1 is video
            capture: {deviceId: {exact: deviceId}}
        });
    }

    emitLog({
        roomId,
        message: `changeUserDevice - ${JSON.stringify({
            isAudio: isAudio,
            isVideo: isVideo,
            deviceId: deviceId,
        })}`,
        session: sfutest?.getId(),
        date: getDate(),
        publisher: true,
    })

    sfutest.replaceTracks({
        tracks: tracks,
        error: function (err) {
            meetLog(err.message);
            emitLog({
                roomId,
                message: `error changeUserDevice - ${JSON.stringify({
                    isAudio: isAudio,
                    isVideo: isVideo,
                    deviceId: deviceId,
                })}`,
                session: sfutest?.getId(),
                date: getDate(),
                publisher: true,
            })
        }
    });
}

let creatingSubscription = false;

function subscribeTo(sources) {
    const log = (...args) => console.log("[Janus]", ...args);
    log("subscribeTo", sources);

    // Check if we're still creating the subscription handle
    if (creatingSubscription) {
        log("subscribeTo creatingSubscription", creatingSubscription, remoteFeed);
        // Still working on the handle, send this request later when it's ready
        setTimeout(function () {
            subscribeTo(sources);
        }, 500);
        log("subscribeTo return");
        return;
    }
    log("subscribeTo remoteFeed", opaqueId, remoteFeed);
    // If we already have a working subscription handle, just update that one
    if (remoteFeed) {
        // Prepare the streams to subscribe to, as an array: we have the list of
        // streams the feeds are publishing, so we can choose what to pick or skip
        let added = [], removed = [];

        log("subscribeTo remoteFeed const", added, removed);

        sources.forEach(streams => {
            return streams.forEach(stream => {
                log("subscribeTo stream", stream);
                if (stream.disabled) {
                    log("subscribeTo stream.disabled", stream.disabled);
                    removed.push({
                        feed: stream.id,	// This is mandatory
                        mid: stream.mid		// This is optional (all streams, if missing)
                    });
                    delete subscriptions[stream.id][stream.mid];
                    return;
                }
                if (subscriptions[stream.id] && subscriptions[stream.id][stream.mid]) {
                    log("subscribeTo Already subscribed to stream, skipping", stream);
                    return;
                }

                // Find an empty slot in the UI for each new source
                if (!feedStreams[stream.id].slot) {
                    let slot;
                    for (let i = 1; i < 6; i++) {
                        if (!feeds[i]) {
                            slot = i;
                            feeds[slot] = stream.id;
                            if (!feedStreams[stream.id]) feedStreams[stream.id] = {};
                            feedStreams[stream.id].slot = slot;
                            feedStreams[stream.id].remoteVideos = 0;
                            break;
                        }
                    }
                }

                added.push({
                    feed: stream.id,	// This is mandatory
                    mid: stream.mid		// This is optional (all streams, if missing)
                });

                if (!subscriptions[stream.id]) subscriptions[stream.id] = {};
                subscriptions[stream.id][stream.mid] = true;
            });
        });

        if ((added.length + removed.length) === 0) {
            log("subscribeTo Nothing to do");
            // Nothing to do
            return;
        }
        let update = {request: 'update'};
        if (added.length > 0) update.subscribe = added;
        if (removed.length > 0) update.unsubscribe = removed;

        log("subscribeTo update", update);
        remoteFeed.send({message: update});
        // Nothing else we need to do
        return;
    }
    // If we got here, we're creating a new handle for the subscriptions (we only need one)
    creatingSubscription = true;
    janus.attach(
        {
            plugin: "janus.plugin.videoroom",
            opaqueId: opaqueId,
            success: function (pluginHandle) {
                remoteFeed = pluginHandle;
                remoteTracks = {};

                log("success", pluginHandle)
                meetLog("Plugin attached! (" + remoteFeed.getPlugin() + ", id=" + remoteFeed.getId() + ")");
                meetLog("  -- This is a multistream subscriber");

                emitLog({
                    roomId,
                    message: "Плагин видеокомнаты успешно подключен! (" + remoteFeed.getPlugin() + ", id=" + remoteFeed.getId() + ")",
                    session: remoteFeed.getId(),
                    date: getDate(),
                    publisher: false
                })

                // Prepare the streams to subscribe to, as an array: we have the list of
                // streams the feed is publishing, so we can choose what to pick or skip
                let subscription = [];

                sources.filter(Boolean).forEach(streams => {
                    return streams.forEach(stream => {
                        // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
                        /*if (stream.type === "video" && window.Janus.webRTCAdapter.browserDetails.browser === "safari" &&
                          (stream.codec === "vp9" || (stream.codec === "vp8" && !window.Janus.safariVp8))) {
                          meetLog("Publisher is using " + stream.codec.toUpperCase +
                            ", but Safari doesn't support it: disabling video stream #" + stream.mindex)
                          continue;
                        }*/
                        if (stream.disabled) {
                            console.log("Disabled stream:", stream)
                            meetLog("Disabled stream:", stream);
                            // TODO Skipping for now, we should unsubscribe
                            return;
                        }

                        emitLog({
                            roomId,
                            message: "Подписался к стриму " + stream.id + "/" + stream.mid + "?",
                            session: remoteFeed.getId(),
                            date: getDate(),
                            publisher: false
                        })

                        meetLog("Subscribed to " + stream.id + "/" + stream.mid + "?", subscriptions);
                        if (subscriptions[stream.id] && subscriptions[stream.id][stream.mid]) {
                            meetLog("Already subscribed to stream, skipping:", stream);
                            emitLog({
                                roomId,
                                message: "Уже подписан на стрим, пропускаем: " + stream.id,
                                session: remoteFeed.getId(),
                                date: getDate(),
                                publisher: false
                            })
                            return;
                        }
                        // Find an empty slot in the UI for each new source
                        if (feedStreams[stream.id] && !feedStreams[stream.id].slot) {
                            let slot;
                            for (let i = 1; i < 6; i++) {
                                if (!feeds[i]) {
                                    slot = i;
                                    feeds[slot] = stream.id;
                                    feedStreams[stream.id].slot = slot;
                                    feedStreams[stream.id].remoteVideos = 0;
                                    // $('#remote' + slot).removeClass('hide').html(escapeXmlTags(stream.display)).show();
                                    break;
                                }
                            }
                        }
                        subscription.push({
                            feed: stream.id,	// This is mandatory
                            mid: stream.mid		// This is optional (all streams, if missing)
                        });
                        if (!subscriptions[stream.id]) subscriptions[stream.id] = {};
                        subscriptions[stream.id][stream.mid] = true;
                    });
                });

                console.log("sources", sources, subscription);

                // We wait for the plugin to send us an offer
                let subscribe = {
                    request: "join",
                    room: roomId,
                    ptype: "subscriber",
                    streams: subscription,
                    use_msid: use_msid,
                    private_id: mypvtid
                };
                remoteFeed.send({message: subscribe});

                emitLog({
                    roomId,
                    message: 'Присоединился к янусу как подписчик',
                    session: remoteFeed.getId(),
                    date: getDate(),
                    publisher: false
                })
            },
            error: function (error) {
                log("error", error)
                window.Janus.error("  -- Error attaching plugin...", error);
                emitLog({
                    roomId,
                    message: "Ошибка подключения плагина видеокомнаты - " + JSON.stringify(error),
                    session: remoteFeed.getId(),
                    date: getDate(),
                    publisher: false
                })
            },
            iceState: function (state) {
                log("iceState", state)
                meetLog("ICE state (remote feed) changed to " + state);
                emitLog({
                    roomId,
                    message: "iceState - ICE пользователя изменены на " + state,
                    session: remoteFeed.getId(),
                    date: getDate(),
                    publisher: false
                })
            },
            webrtcState: function (on) {
                log("webrtcState", on)
                meetLog("Janus says this WebRTC PeerConnection (remote feed) is " + (on ? "up" : "down") + " now");
                emitLog({
                    roomId,
                    message: "webrtcState - Janus says this WebRTC PeerConnection (remote feed) is " + (on ? "up" : "down") + " now",
                    session: remoteFeed.getId(),
                    date: getDate(),
                    publisher: false
                })
            },
            slowLink: function (uplink, lost, mid) {
                log("slowLink", uplink, lost, mid)
                window.Janus.warn("Janus reports problems " + (uplink ? "sending" : "receiving") +
                    " packets on mid " + mid + " (" + lost + " lost packets)");
            },
            onmessage: function (msg, jsep) {
                log("onmessage", msg, jsep)
                window.Janus.debug(" ::: Got a message (subscriber) :::", msg);
                let event = msg["videoroom"];
                window.Janus.debug("Event: " + event);

                emitLog({
                    roomId,
                    message: `Onmessage - ${JSON.stringify(msg)}`,
                    session: remoteFeed.getId(),
                    date: getDate(),
                    publisher: false
                })

                if (msg["error"]) {
                    meetLog('Error', msg["error"])
                } else if (event) {
                    if (event === "attached") {
                        // Now we have a working subscription, next requests will update this one
                        creatingSubscription = false;
                        meetLog("Successfully attached to feed in room " + msg["room"]);
                        emitLog({
                            roomId,
                            message: "Успешно присоединился к комнате " + msg["room"],
                            session: remoteFeed.getId(),
                            date: getDate(),
                            publisher: false
                        })
                    } else if (event === "updated") {

                    } else if (event === "event") {
                        // Check if we got an event on a simulcast-related event from this publisher
                        // addSimulcastButtons
                    } else {
                        // What has just happened?
                    }
                }
                if (msg["streams"]) {
                    // Update map of subscriptions by mid
                    for (let i in msg["streams"]) {
                        let mid = msg["streams"][i]["mid"];
                        subStreams[mid] = msg["streams"][i];
                        let feed = feedStreams[msg["streams"][i]["feed_id"]];
                        if (feed && feed.slot) {
                            slots[mid] = feed.slot;
                            mids[feed.slot] = mid;
                        }
                    }
                }
                if (jsep) {
                    window.Janus.debug("Handling SDP as well...", jsep);

                    emitLog({
                        roomId,
                        message: "createAnswer start",
                        session: remoteFeed.getId(),
                        date: getDate(),
                        publisher: false
                    })
                    // Answer and attach
                    remoteFeed.createAnswer(
                        {
                            jsep: jsep,
                            tracks: [
                                {type: 'data'}
                            ],
                            success: function (jsep) {
                                window.Janus.debug("Got SDP!");
                                let body = {request: "start", room: roomId};
                                remoteFeed.send({message: body, jsep: jsep});
                                emitLog({
                                    roomId,
                                    message: "createAnswer success",
                                    session: remoteFeed.getId(),
                                    date: getDate(),
                                    publisher: false
                                })
                            },
                            error: function (error) {
                                window.Janus.error("WebRTC error:", error);
                                emitLog({
                                    roomId,
                                    message: "createAnswer error - " + JSON.stringify(error),
                                    session: remoteFeed.getId(),
                                    date: getDate(),
                                    publisher: false
                                })
                            }
                        });
                }
            },
            onlocaltrack: function (track, on) {
                log("onlocaltrack", track, on)
                // The subscriber stream is recvonly, we don't expect anything here
            },
            onremotetrack: function (track, mid, on) {
                log("onremotetrack", track, mid, on)
                const store = callStore.getState();
                let users = store.meetingUsers.users,
                    localUser = users.find((u) => u.isLocal),
                    devices = store.settings.activeDevices

                console.log("[Janus] onremotetrack", track, mid, on, users, localUser, devices);

                emitLog({
                    roomId,
                    message: `Трек (mid=${mid}) ${on ? "добавлен" : "удален"} пользователю ${formatUserName(localUser)}. Трек - ${JSON.stringify({
                        kind: track.kind,
                        contentHint: track.contentHint,
                        enabled: track.enabled,
                        id: track.id,
                        label: track.label,
                        muted: track.muted
                    })}`,
                    session: remoteFeed.getId(),
                    date: getDate(),
                    publisher: false
                })

                window.Janus.debug("Remote track (mid=" + mid + ") " + (on ? "added" : "removed") + ":", track);
                // Which publisher are we getting on this mid?
                let sub = subStreams[mid];
                let feed = feedStreams[sub?.feed_id];
                window.Janus.debug(" >> This track is coming from feed " + sub?.feed_id + ":", feed);
                let slot = slots[mid];
                if (feed && !slot) {
                    slot = feed.slot;
                    slots[mid] = feed.slot;
                    mids[feed.slot] = mid;
                }
                window.Janus.debug(" >> mid " + mid + " is in slot " + slot);

                const user = users.find((u) => u.id === sub?.feed_id)

                if (!on) {
                    callStore.dispatch(checkShareUser(user));
                }
                if (user) {
                    if (!on) {
                        // Track removed, get rid of the stream and the rendering

                        if (track.kind === "video" && feed) {
                            feed.remoteVideos--;
                            if (feed.remoteVideos === 0) {
                                // No video, at least for now: show a placeholder
                                meetLog('No remote video available')
                            }
                        }

                        delete remoteTracks[mid];
                        meetLog('Удален стрим юзера - ' + user.id);
                        emitLog({
                            roomId,
                            message: `Удален трек юзера - ${formatUserName(user)} у пользователя ${formatUserName(localUser)}. Трек - ${JSON.stringify({
                                kind: track.kind,
                                contentHint: track.contentHint,
                                enabled: track.enabled,
                                id: track.id,
                                label: track.label,
                                muted: track.muted
                            })}`,
                            session: remoteFeed.getId(),
                            date: getDate(),
                            publisher: false
                        })
                        // if (window.usersStreams.streams[user.id]) delete window.usersStreams.streams[user.id];
                        if (posterTimer[user.id]) clearInterval(posterTimer[user.id]);
                        delete slots[mid];
                        delete mids[slot];
                        return;
                    }

                    if (
                        window.usersStreams.streams[user.id] &&
                        document.getElementById(`remoteaudio-${user.id}`)
                    ) return;

                    const usr = Object.assign({}, user);

                    let stream;
                    if (track.kind === "audio") {
                        // New audio track: create a stream out of it, and use a hidden <audio> element
                        stream = new MediaStream([track]);

                        /** проверим наличие аудио и громкости */
                        try {
                            const audioContext = new AudioContext();
                            const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
                            const analyserNode = audioContext.createAnalyser();
                            mediaStreamAudioSourceNode.connect(analyserNode);

                            const pcmData = new Float32Array(analyserNode.fftSize);
                            const onFrame = () => {
                                analyserNode.getFloatTimeDomainData(pcmData);
                                let sumSquares = 0.0;
                                for (const amplitude of pcmData) {
                                    sumSquares += amplitude * amplitude;
                                }
                                emitLog({
                                    roomId,
                                    message: `Уровень громкости аудио в треке пользователя. Где 0 - нет звука. Трек пользователя ${formatUserName(user)} у пользователя ${formatUserName(localUser)} с уровнем = ${Math.sqrt(sumSquares / pcmData.length)}`,
                                    session: remoteFeed.getId(),
                                    date: getDate(),
                                    publisher: false
                                })
                            };

                            setTimeout(() => {
                                window.requestAnimationFrame(onFrame);
                                setTimeout(() => {
                                    window.requestAnimationFrame(onFrame);
                                    setTimeout(() => {
                                        window.requestAnimationFrame(onFrame);
                                    }, 3000)
                                }, 2000)
                            }, 2000)
                        } catch (e) {
                            console.log(e);
                            emitLog({
                                roomId,
                                message: `Браузер не поддерживает измерение громкости трека`,
                                session: remoteFeed.getId(),
                                date: getDate(),
                                publisher: false
                            })
                        }
                        /** */

                        remoteTracks[mid] = stream;
                        addSpeakEvent(stream, user.id);
                        meetLog("Created remote audio stream:", stream);

                        emitLog({
                            roomId,
                            message: `Создан аудиострим - ${formatUserName(user)} у пользователя ${formatUserName(localUser)}. Трек - ${JSON.stringify({
                                kind: track.kind,
                                contentHint: track.contentHint,
                                enabled: track.enabled,
                                id: track.id,
                                label: track.label,
                                muted: track.muted
                            })}`,
                            session: remoteFeed.getId(),
                            date: getDate(),
                            publisher: false
                        })

                        const audio = createAudio(`remoteaudio-${user.id}`, formatUserName(user), devices.speaker);
                        if (audio) window.Janus.attachMediaStream(audio, stream);

                        if (feed.remoteVideos === 0) {
                            // No video, at least for now: show a placeholder
                            meetLog('No remote video available');
                        }

                    } else {
                        // New video track: create a stream out of it
                        feed.remoteVideos++;
                        stream = new MediaStream([track]);

                        remoteTracks[mid] = stream;
                        attachStreamVideo(user.id, stream);

                        callStore.dispatch(checkShareUser(usr));

                        window.usersStreams.streams[user.id] = remoteTracks[mid];
                        meetLog("Created remote video stream:", stream);

                        emitLog({
                            roomId,
                            message: `Создан видеострим - ${formatUserName(user)} у пользователя ${formatUserName(localUser)}. Трек - ${JSON.stringify({
                                kind: track.kind,
                                contentHint: track.contentHint,
                                enabled: track.enabled,
                                id: track.id,
                                label: track.label,
                                muted: track.muted
                            })}`,
                            session: remoteFeed.getId(),
                            date: getDate(),
                            publisher: false
                        })

                        /* if (!posterTimer[user.id]) {
                           let previousBytes = 0;
                           let previousTS = 0;
                           let currentBytes = 0;
                           let currentTS = 0;

                           posterTimer[user.id] = setInterval(() => {
                             let config = remoteFeed.webrtcStuff;
                             if (!config.pc) return;
                             config.pc.getStats(null).then(stats => {
                               stats.forEach(report => {
                                 if (report.type === "inbound-rtp") {

                                   currentBytes = report.bytesReceived;
                                   currentTS = report.timestamp;

                                   if (previousBytes === 0) {
                                     previousBytes = currentBytes;
                                     previousTS = currentTS;
                                     return;
                                   }

                                   let deltaBytes = currentBytes - previousBytes;
                                   let deltaTS = currentTS - previousTS;

                                   console.log(88888, deltaBytes / deltaTS);

                                   //console.log("Delta: " + (deltaBytes / deltaTS) + " kB/s")
                                   previousBytes = currentBytes;
                                   previousTS = currentTS;

                                 }
                               });
                             });
                             // if (!track.muted && track.enabled) {
                             //   const videoWrap = document.querySelector(`.video-${user.id}`);
                             //   if (videoWrap) {
                             //     const v = videoWrap.querySelector('video');
                             //     if (v) {
                             //       let width = v.videoWidth;
                             //       let height = v.videoHeight;
                             //       try {
                             //         // Сафари не поддерживает
                             //         canvas.width = width;
                             //         canvas.height = height;
                             //         canvas.getContext("2d").drawImage(v, 0, 0, width, height);
                             //
                             //         console.log(88888);
                             //         попробывать подписаться на битрейт
                             //
                             //         window.userPosters[user.id] = canvas.toDataURL("image/webp");
                             //       } catch (e) {
                             //         meetLog(e);
                             //       }
                             //     }
                             //   }
                             // }
                           }, 3000)
                         }*/

                        if (!bitrateTimer[slot]) {
                            bitrateTimer[slot] = setInterval(function () {
                                let bitrate = remoteFeed.getBitrate(mid);

                                const blockBitrate = document.getElementById(`pr-bitrate-${user.id}`);
                                if (blockBitrate) blockBitrate.innerText = `Bitrate: ${bitrate}`

                                const videoWrap = document.querySelector(`.video-${user.id}`);
                                if (videoWrap) {
                                    const v = videoWrap.querySelector('video');
                                    if (v) {
                                        let width = v.videoWidth;
                                        let height = v.videoHeight;

                                        const blockResolution = document.getElementById(`pr-resolution-${user.id}`);
                                        if (blockResolution) blockResolution.innerText = `Resolution: ${width}x${height}`;

                                        if (width > 0 && height > 0 && user.videoResolution && user.videoResolution.width !== width) {
                                            callStore.dispatch(updateUser({
                                                id: user.id, videoResolution: {width, height}
                                            }));
                                        }
                                    }
                                }

                                if (user.isShare && !user.isLocal) {
                                    const debugOther = document.getElementById(`pr-other-${user.id}`);
                                    if (debugOther) {
                                        let text = `User ID: ${user.id}\r\nRoom: ${roomId}\r\n\r\n`
                                        text += `Video track data:\r\n- Enabled: ${track.enabled}\r\n- Label: ${track.label}\r\n- Muted: ${track.muted}`

                                        debugOther.innerText = text;
                                    }
                                }
                            }, 1000);
                        }
                    }

                    usr.answer = true;
                    meetLog(formatUserName(usr), 'id - ', usr.id, ' пришел медиапоток', ' видео - ', stream.getVideoTracks().length > 0, ' audio - ', stream.getAudioTracks().length > 0);

                    if (usr.isLoadingShare) {
                        if (localUser && (usr.id / 1000) !== localUser.id && !track.muted) {
                            setTimeout(() => {
                                playSounds(startShare, 2500);
                            }, 1000)
                        }
                        setTimeout(() => {
                            usr.isLoadingShare = false;
                            callStore.dispatch(updateUser(usr));
                        }, 1000)
                    }

                    callStore.dispatch(updateUser(usr));
                    callStore.dispatch(setIsAnswer(true));
                }
            },
            oncleanup: function () {
                log("oncleanup")
                meetLog(" ::: Got a cleanup notification (remote feed) :::");
                emitLog({
                    roomId,
                    message: `Очистил PeerConnection (remote feed)`,
                    session: remoteFeed.getId(),
                    date: getDate(),
                    publisher: false
                })
                for (let i = 1; i < 6; i++) {
                    if (bitrateTimer[i]) clearInterval(bitrateTimer[i]);
                    bitrateTimer[i] = null;
                    if (feedStreams[i]) {
                        feedStreams[i].simulcastStarted = false;
                        feedStreams[i].remoteVideos = 0;
                    }
                }
                remoteTracks = {};
            }
        });
}

function unsubscribeFrom(id) {
    let feed = feedStreams[id];
    if (!feed) return;

    if (bitrateTimer[feed.slot]) clearInterval(bitrateTimer[feed.slot]);
    bitrateTimer[feed.slot] = null;

    window.Janus.debug("Feed " + id + " (" + feed.display + ") has left the room, detaching");

    emitLog({
        roomId,
        message: "Сессия " + id + " (" + feed.display + ") покинул комнату",
        session: id,
        date: getDate(),
        publisher: false
    })
    delete simulcastStarted[feed.slot];
    delete feeds[feed.slot];
    feeds.slot = 0;
    delete feedStreams[id];
    // Send an unsubscribe request
    let unsubscribe = {
        request: "unsubscribe",
        streams: [{feed: id}]
    };
    if (remoteFeed != null) remoteFeed.send({message: unsubscribe});
    delete subscriptions[id];
}
