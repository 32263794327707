import {setFullScreen, setRightBarType, setSize, setAppUi, setIsMaximized, setError, setIsPinnedUI} from "../redux/slices/ui";
import {IPC_CHANNELS} from "../constants/ipc";
import {ERROR_TYPES} from "../constants/contants";
import {changeLang} from "../helpers/common";
import {changeTheme} from "../helpers/functions";

export const loadListeners = (store) => {

  window.ipcRenderer.on(IPC_CHANNELS.ELECTRON, (e, data) => {
    switch (data.type) {
      case 'change-ui':
        store.dispatch(setAppUi(data.data));
        return;

      case 'set-full-screen':
        store.dispatch(setFullScreen(data.data));
        return;

      case 'set-small':
        store.dispatch(setSize('S'));
        store.dispatch(setRightBarType(''));
        store.dispatch(setError(''))
        return;

      case 'set-calls-medium':
        store.dispatch(setSize('M'));
        return;

      case 'change-theme':
        changeTheme(data.theme);
        return;

      case 'set-is-maximized':
        store.dispatch(setIsMaximized(data.isMaximized));
        return;

      case 'screen-share-permission':
        // stopShareScreen();
        store.dispatch(setError(ERROR_TYPES.PERMISSION_SHARE))
        return;

      case 'change-lang':
        changeLang(data.lang);
        return;
      case 'change-pin-state':
        store.dispatch(setIsPinnedUI(parseInt(data.state) === 1));
        return;
    }
  })
}
