import React, {useCallback, useState} from 'react';
import {Button, ContextualMenu, Dividers, ListItem, Text} from "@clout-team/web-components";
import {useSelector} from "react-redux";
import {endCall} from "../../../../helpers/callsHelper";
import {usersInMeetingSelector} from "../../../../redux/selectors/users";
import {User} from "../../../../interfaces/user";
import Checkmark from "@clout-team/icons/react/Checkmark";
import {Emit} from "../../../../helpers/socketHelper";
import {SOCKET_ACTIONS} from "../../../../constants/socket";
import {conferenceHashSelector} from "../../../../redux/selectors/conference";
import './ChangeHostContext.scss';
import { PLUG_TYPES } from '../../../../constants/contants';
import {formatUserName} from "../../../../helpers/functions";

const ChangeHostContext = React.memo((props: {
  align: any
  onClose: () => void

}) => {
  const {align, onClose} = props;

  const lang = useSelector((state: any) => state.localization);
  const L = useCallback((key: string) => lang.translations[lang.locale][key], [lang]);
  const inMeetingUsers: User.IUsersTo[] = useSelector(usersInMeetingSelector);
  const hash = useSelector(conferenceHashSelector);

  const [selected, setSelected] = useState(0);

  const leave = useCallback(() => {
    Emit(SOCKET_ACTIONS.MEETING_SET_HOST, {
      id: inMeetingUsers.filter(u => !u.isLocal)[selected].id,
      link: hash
    })
    endCall(false, PLUG_TYPES.YOU_LEFT_MEET);
  }, [inMeetingUsers, hash, selected])

  const handleClick = useCallback((index: number) => () => {
    setSelected(index)
  }, [inMeetingUsers])

  return (
    <ContextualMenu
      isModal={true}
      alignToElement={align}
      closeClickedInside={false}
      onClose={() => onClose()}
    >
      <div className={'change-host-content'}>
        <ListItem title={<Text size={'header-bold'}>{L('calls.assign_a_new_host')}</Text>} size={'sm'} isHovered={false} isStatic={true} />
        <div className={'change-host-list'}>
          {
            inMeetingUsers.filter(u => !u.isLocal && !u.isShare).map((u, index) => {
              if (u.isLocal) return null;
              return <ListItem
                key={u.id}
                title={formatUserName(u)}
                size={'md'}
                avatar={{
                  src: u.avatar,
                  type: 'private'
                }}
                onClick={handleClick(index)}
                active={index === selected}
                iconRight={index === selected ? <Checkmark color={'#407EC9'}/> : <></>}
              />
            })
          }
        </div>
        <div className={'change-host-button'}>
          <Dividers/>
          <Button
            className={'rounded-corners-8 custom-btn-style'}
            onClick={leave}
            color={'red'}
            fullWidth
          >
            {L('calls.assign_and_leave')}
          </Button>
          <Dividers/>
        </div>
      </div>
    </ContextualMenu>
  );
})

export default ChangeHostContext;
