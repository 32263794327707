import { StartConferenceBanner } from "@clout-team/web-components";
import { useDispatch, useSelector } from "react-redux";
import {conferenceLinkSelector} from "../../../redux/selectors/conference";
import { User } from "../../../interfaces/user";
import { userCurrentSelector } from "../../../redux/selectors/users";
import { setFirstBanner, setShowInviteUsers } from "../../../redux/slices/ui";
import { uiSelectorFirstBanner } from "../../../redux/selectors/ui";
import './FirstEnter.scss';
import { settingsSelectorIsInvertRightBar } from "../../../redux/selectors/settings";
import {useEffect, useState} from "react";

const BannerFirstEnter = () => {
  const link: string = useSelector(conferenceLinkSelector),
    currentUser: User.IUsersTo = useSelector(userCurrentSelector),
    showFirst = useSelector(uiSelectorFirstBanner),
    dispatch = useDispatch(),
    isInvertRightBar = useSelector(settingsSelectorIsInvertRightBar),
    [show, setShow] = useState(true)

  useEffect(() => {
    if (currentUser && currentUser.isHost && !showFirst) {
      setShow(true);
      dispatch(setFirstBanner(true));
    }
  }, [currentUser, showFirst])

  const onInvite = () => {
    dispatch(setShowInviteUsers(true))
  };

  const onClose = () => {
    setShow(false);
    dispatch(setFirstBanner(true))
  };

  const onCopy = () => { };

  return (
    <div className={`banner-first-enter`}>
      {
        (currentUser?.isHost && show) ?
          <StartConferenceBanner
            link={link}
            inverted={isInvertRightBar}
            onInvite={onInvite}
            onClose={onClose}
            onCopy={onCopy} />
          :
          <></>
      }
    </div>
  )
}

export default BannerFirstEnter
