import React, { useCallback } from "react";
import { Text } from "@clout-team/web-components";
import { PRIVACY_LINK, USER_AGREEMENT_LINK } from "../../../constants/contants";
import { useSelector } from "react-redux";
import "./Privacy.scss";
import { profileCurrentSelector } from "../../../redux/selectors/users";

const Privacy = React.memo(() => {
  const lang = useSelector((state: any) => state.localization),
    profile = useSelector(profileCurrentSelector);
  const L = useCallback(
    (key: string) => lang.translations[lang.locale][key],
    [lang]
  );

  if (profile) return <></>;

  return (
    <Text
      align={"center"}
      className={"pre-wrap calls-privacy"}
      type={"secondary"}
      size={"footnote"}
    >
      {L("calls.waiting.footer.start")}{" "}
      <a href={USER_AGREEMENT_LINK} target="_blank" rel="noreferrer">
        {L("calls.waiting.footer.user.agree")}{" "}
      </a>{" "}
      {L("calls.waiting.footer.and.the")}{" "}
      <a href={PRIVACY_LINK} target="_blank" rel="noreferrer">
        {L("calls.waiting.footer.user.privacy")}
      </a>
    </Text>
  );
});

export default Privacy;
