import {JanusSingleton} from "./singleton";
import {LAYOUTS, SHARE_ROLES} from "../constants/contants";
import callStore from "../redux/stores/callStore";
import {setUserAsync, updateUser, updateUserLocal} from "../redux/slices/users";
import {setLayout} from "../redux/slices/ui";
import startShare from "../audio/start_share.mp3";
import {USERS} from "../constants/meeting";
import {attachStreamVideo, playSounds} from "../helpers/functions";
import {getDate, meetLog} from "../helpers/log";
import {emitLog} from "../helpers/socketHelper";

const janus = JanusSingleton.getJanus();
const opaqueId = "screensharingtest-" + window.Janus.randomString(12);
let myid = null,
  screentest = null,
  capture = null,
  room = null,
  shareId = 0;

let localTracks = {}, localVideos = 0


const SingletonShare = (function () {

  class ShareIn {

    constructor() {

    }

    shareAttach() {
      const store = callStore.getState();

      const users = store.meetingUsers.users;

      const iAm = users.find((v) => v.isLocal),
        hash = store.conference.conference.hash,
        roomId = store.conference.conference.roomId,
        token = store.conference.conference.token,
        profile = store.meetingUsers.profile

      shareId = iAm.id * 1000;

      room = roomId;

      janus.attach(
        {
          plugin: "janus.plugin.videoroom",
          opaqueId: opaqueId,
          success: function (pluginHandle) {
            screentest = pluginHandle;
            meetLog("Plugin attached! (" + screentest.getPlugin() + ", id=" + screentest.getId() + ")");
            capture = "screen";

            emitLog({
              roomId : room,
              message: "Плагин шаринга подключен! (" + screentest.getPlugin() + ", id=" + screentest.getId() + ")",
              session: screentest.getId(),
              date: getDate(),
              publisher: true
            })

            screentest.send({
              message: {
                request: "join",
                room: roomId,
                ptype: SHARE_ROLES.PUBLISHER,
                display: JSON.stringify({
                  id: shareId,
                  name: profile.name,
                  surname: profile.surname,
                  avatar: profile.avatar,
                  isShare: true,
                  video: true,
                  audio: true
                }),
                token: token,
                id: shareId,
              }
            });

          },
          error: function (error) {
            window.Janus.error("  -- Error attaching plugin...", error);
            emitLog({
              roomId : room,
              message: `Ошибка подключения плагина шаринга - ${JSON.stringify(error)}`,
              session: screentest.getId(),
              date: getDate(),
              publisher: true
            })
          },
          consentDialog: function (on) {
            window.Janus.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
            emitLog({
              roomId : room,
              message: "(Шаринг) Consent dialog should be " + (on ? "on" : "off") + " now",
              session: screentest.getId(),
              date: getDate(),
              publisher: true
            })
            if (on) {
              meetLog('Darken screen');
            } else {
              meetLog('Restore screen');
            }
          },
          iceState: function (state) {
            meetLog("ICE state changed to " + state);
            emitLog({
              roomId : room,
              message: "iceState ICE шаринга в состоянии " + state,
              session: screentest.getId(),
              date: getDate(),
              publisher: true
            })
          },
          mediaState: function (medium, on, mid) {
            meetLog("Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")");
            emitLog({
              roomId : room,
              message: "mediaState Шаринг Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")",
              session: screentest.getId(),
              date: getDate(),
              publisher: true
            })
          },
          webrtcState: function (on) {
            meetLog("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
            emitLog({
              roomId : room,
              message: "webrtcState Шаринг Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now",
              session: screentest.getId(),
              date: getDate(),
              publisher: true
            })
            if (on) {
              meetLog("Your screen sharing session just started: pass the <b>" + room + "</b> session identifier to those who want to attend.");
            } else {
              meetLog("Your screen sharing session just stopped.");
              // janus.destroy();
              // window.location.reload();
            }
          },
          slowLink: function (uplink, lost, mid) {
            window.Janus.warn("Janus reports problems " + (uplink ? "sending" : "receiving") +
              " packets on mid " + mid + " (" + lost + " lost packets)");
          },
          onmessage: function (msg, jsep) {
            window.Janus.debug(" ::: Got a message (publisher) :::", msg);

            emitLog({
              roomId : room,
              message: `onmessage шаринга - ${JSON.stringify(msg)}`,
              session: screentest.getId(),
              date: getDate(),
              publisher: true
            })

            let event = msg["videoroom"];
            window.Janus.debug("Event: " + event);
            if (event) {
              if (event === "joined") {
                myid = msg["id"];
                meetLog("Successfully joined room " + msg["room"] + " with ID " + myid);
                emitLog({
                  roomId : room,
                  message: "Шаринг успешно присоединился к комнате " + msg["room"] + " с ID " + myid,
                  session: screentest.getId(),
                  date: getDate(),
                  publisher: true
                })
                // if (role === "publisher") {
                // This is our session, publish our stream
                window.Janus.debug("Negotiating WebRTC stream for our screen (capture " + capture + ")");
                // Other browsers should be fine, we try to call getDisplayMedia directly
                publish();
                // }
              } else if (event === "event") {
                if (msg["error"]) {
                  meetLog(msg["error"])
                } else if (msg["leaving"]) {
                  let leaving = msg["leaving"];
                  if (parseInt(leaving) * 1000 === myid) {
                    if (screentest) screentest.hangup()
                  }
                }
              }
            }
            if (jsep) {
              window.Janus.debug("Handling SDP as well...", jsep);
              screentest.handleRemoteJsep({jsep: jsep});
            }
          },
          onlocaltrack: function (track, on) {
            const store = callStore.getState(),
              iAm = {...store.meetingUsers.users.find((u) => u.isLocal)}

            callStore.dispatch(updateUserLocal({isLoadingShare: true, isShare: true}));

            const user = {
              isPinned: false,
              key: USERS.IN_MEETING,
              name: iAm.name,
              surname: iAm.surname,
              avatar: iAm.avatar,
              id: shareId,
              answer: true,
              audio: false,
              video: false,
              isShare: true,
              isLoadingShare: true,
              isActive: false,
              isHost: false
            }

            // const existingUser = store.meetingUsers.users.find((u) => u.id === user.id);
            // if (existingUser) callStore.dispatch(deleteUserAsync(existingUser.id));

            window.Janus.debug("Local track " + (on ? "added" : "removed") + ":", track);

            emitLog({
              roomId : room,
              message: "Шаринг локальный трек " + (on ? "добавлен" : "удален") + " Трек - " + JSON.stringify({kind: track.kind, contentHint: track.contentHint, enabled: track.enabled, id: track.id, label: track.label, muted: track.muted}),
              session: screentest.getId(),
              date: getDate(),
              publisher: true
            })

            // We use the track ID as name of the element, but it may contain invalid characters
            let trackId = track.id.replace(/[{}]/g, "");
            if (!on) {
              // Track removed, get rid of the stream and the rendering
              let stream = localTracks[trackId];
              if (stream) {
                try {
                  let tracks = stream.getTracks();
                  for (let i in tracks) {
                    let mst = tracks[i];
                    if (mst)
                      mst.stop();
                  }
                } catch (e) {

                }
              }
              if (track.kind === "video") {
                localVideos--;
                if (localVideos === 0) {
                  // No video, at least for now: show a placeholder
                  meetLog("No webcam available")
                }
              }
              delete localTracks[trackId];
              // if (window.usersStreams.streams[user.id]) delete window.usersStreams.streams[user.id];
              return;
            }
            // If we're here, a new track was added
            let stream = localTracks[trackId];
            if (stream) {
              // We've been here already
              return;
            }
            if (track.kind === "audio") {
              // We ignore local audio tracks, they'd generate echo anyway
              if (localVideos === 0) {
                // No video, at least for now: show a placeholder
                meetLog("No webcam available")
              }
            } else {
              // New video track: create a stream out of it
              localVideos++;
              if (track.muted === false) {
                user.video = true;
                track.onended = () => {
                  ShareSingleton.stopShareScreen();
                };
              } else {
                ShareSingleton.stopShareScreen();
              }

              let stream = new MediaStream([track]);
              localTracks[trackId] = stream;
              attachStreamVideo(user.id, stream);
              meetLog("Created local stream:", stream);
              emitLog({
                roomId : room,
                message: "Шаринг создан локальный стрим",
                session: screentest.getId(),
                date: getDate(),
                publisher: true
              })
              localStorage.setItem('initial-layout-type', store.ui.layout)
              callStore.dispatch(setLayout(LAYOUTS.STACK));
              callStore.dispatch(setUserAsync(user));

              window.usersStreams.streams[user.id] = localTracks[trackId];

              setTimeout(() => {
                playSounds(startShare, 2500);
                callStore.dispatch(updateUser({id: user.id, isLoadingShare: false}));
                callStore.dispatch(updateUserLocal({isLoadingShare: false}));
              }, 1000)
            }
          },
          // eslint-disable-next-line no-unused-vars
          onremotetrack: function (track, mid, on) {
            // The publisher stream is sendonly, we don't expect anything here
          },
          oncleanup: function () {
            meetLog(" ::: Got a cleanup notification :::");
            emitLog({
              roomId : room,
              message: "Очищено PeerConnection",
              session: screentest.getId(),
              date: getDate(),
              publisher: true
            })
            localTracks = {};
            localVideos = 0;
          }
        });
    }

    startShareScreen() {
      if (screentest) {
        publish()
      } else {
        this.shareAttach()
      }
    }

    stopShareScreen() {
      if (screentest) {
        screentest.send({
          message: {
            "request": "unpublish"
          }
        })
      }

      callStore.dispatch(updateUserLocal({isLoadingShare: false, isShare: false}))
    }

  }

  let instance;
  return function () {
    if (!instance) {
      instance = new ShareIn()
      delete instance.constructor
    }
    return instance
  }
})()
export const ShareSingleton = SingletonShare()

function publish() {
  emitLog({
    roomId : room,
    message: "createOffer Шаринг старт",
    session: screentest.getId(),
    date: getDate(),
    publisher: true
  })
  screentest.createOffer(
    {
      // We want sendonly audio and screensharing
      tracks: [
        {
          type: 'screen', capture: {
            width: { ideal: 1920, max: 1920 },
            height: { ideal: 1080, max: 1080 }
          }, recv: false
        }
      ],
      success: function (jsep) {
        window.Janus.debug("Got publisher SDP!", jsep);
        let publish = {request: "configure", audio: false, video: true};
        screentest.send({
          message: publish, jsep: jsep
        });
        emitLog({
          roomId : room,
          message: "createOffer Шаринг успешно",
          session: screentest.getId(),
          date: getDate(),
          publisher: true
        })
      },
      error: function (error) {
        window.Janus.error("WebRTC error:", error);
        ShareSingleton.stopShareScreen();
        emitLog({
          roomId : room,
          message: `createOffer Шаринг ошибка - ${JSON.stringify(error)}`,
          session: screentest.getId(),
          date: getDate(),
          publisher: true
        })
      }
    });
}
