import React, {useCallback, useEffect} from 'react';
import {
  Layout,
} from "@clout-team/web-components";
import {useDispatch, useSelector} from "react-redux";
import '../ElectronCallHeader/ElectronCallHeader.scss';
import classNames from "classnames";
import {conferenceSelector} from "../../../../redux/selectors/conference";
import {
  uiSelectorIsAnswer,
  uiSelectorIsHide, uiSelectorIsSmall, uiSelectorIsXSmall, uiSelectorLayout,
} from "../../../../redux/selectors/ui";
import AlertFullScreen from "../AlertFullScreen/AlertFullScreen";
import ConferenceInfo from "../../../../components/Banners/ConferenceInfo/ConferenceInfo";
import ViewChangeButton from "../ViewChangeButton/ViewChangeButton";
import {LAYOUTS} from "../../../../constants/contants";
import {User} from "../../../../interfaces/user";
import Timer from "../../../../components/Timer";
import {usersInMeetingSelector} from "../../../../redux/selectors/users";
import {setSize} from "../../../../redux/slices/ui";

export default function WebCallsHeader() {
  const conference = useSelector(conferenceSelector);

  const lang = useSelector((state: any) => state.localization);
  const L = useCallback((key: string) => lang.translations[lang.locale][key], [lang]);
  const answer = useSelector(uiSelectorIsAnswer),
    layout = useSelector(uiSelectorLayout),
    isSmall = useSelector(uiSelectorIsSmall),
    isXSmall = useSelector(uiSelectorIsXSmall),
    isConference = conference.isConference,
    isHide = useSelector(uiSelectorIsHide),
    users: User.IUsersTo[] = useSelector(usersInMeetingSelector),
    dispatch = useDispatch()

  const onDoubleCLickHandle = useCallback(() => {
    if (isSmall || isXSmall) {
      dispatch(setSize('M'))
    }
  }, [dispatch])

  return (
    <>
      <AlertFullScreen L={L}/>
      <div className={classNames(
        'info-bar-item',
        {
          'is-group': layout === LAYOUTS.STACK && users.length > 1,
          'info-bar-item-sm': isSmall || isXSmall,
          'is-hide': isHide,
          'is-web': true
        }
      )} onDoubleClick={onDoubleCLickHandle}>
        <div key={'one'} className={'web-call-time'}>
          {
            isConference ? (
                <Layout gap={'sm'} className={'conference-info'} isAlignCenter>
                  <ConferenceInfo L={L}/>
                  {/*<Timer start={conference.info?.time_start ? (conference.info?.time_start) * 1000 : Date.now()}/>*/}
                  <Timer/>
                </Layout>
              ) :
              answer ? (
                <Layout gap={'sm'} className={'conference-info'} isAlignCenter>
                  <Timer/>
                </Layout>
              ) : <></>
          }
        </div>

        <div key={'two'} className={classNames('info-bar-right')}>
          {
            (answer || isConference) && <ViewChangeButton L={L}/>
          }
        </div>
      </div>
    </>
  )
}
