import React, { useCallback } from 'react'
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { InviteConferenceBanner } from '@clout-team/web-components';
import { settingsSelectorIsInvertRightBar } from '../../../redux/selectors/settings';
import './InviteUsers.scss'
import { userCurrentSelector } from '../../../redux/selectors/users';
import { setShowInviteUsers } from '../../../redux/slices/ui';
import { conferenceHashSelector } from '../../../redux/selectors/conference';
import { DOMAIN_NAME } from '../../../constants/contants';
import {formatUserName} from "../../../helpers/functions";

const InviteUsers = () => {
  const isInvertRightBar = useSelector(settingsSelectorIsInvertRightBar),
    currentUser = useSelector(userCurrentSelector),
    lang = useSelector((state: any) => state.localization),
    hash = useSelector(conferenceHashSelector),
    dispatch = useDispatch();
    const onClose = useCallback(() => {
      dispatch(setShowInviteUsers(false))
    },[])

  return <InviteConferenceBanner
    email={currentUser?.email}
    onClose={onClose}
    link={`${window.document.location}`}
    hash={hash}
    username={formatUserName(currentUser)}
    className={classNames({
      "invite-conference-dilog": true,
      "inverted-theme-aware": isInvertRightBar
    })}
    inverted={isInvertRightBar}
    lang={lang?.locale}
    domain={`${DOMAIN_NAME}`.replaceAll("/", "")}
  />
}

export default InviteUsers;
