import React, {useCallback, useMemo, useState} from 'react';
import {Avatar, Button, ContextualMenu, Layout, Text} from "@clout-team/web-components";
import Settings from "@clout-team/icons/react/SettingsFilled";
import Link from "@clout-team/icons/react/Link";
import {useDispatch, useSelector} from "react-redux";
import {userHostSelector} from "../../../redux/selectors/users";
import {
  conferenceHashSelector,
  conferenceLinkSelector,
  conferenceTitleSelector
} from "../../../redux/selectors/conference";
import './ConferenceInfo.scss';
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";
import { useContextThemeToggler } from '../../../helpers/useContextThemeToggler';
import { setContextType } from '../../../redux/slices/ui';
import { CONTEXT_TYPES } from '../../../constants/contants';
import {copyClipboard, formatUserName} from "../../../helpers/functions";
import {contextRoot} from "../../../App";

const ConferenceInfo = React.memo((props: {
  L: (key: string) => string,
}) => {
  const {L} = props;
  const [context, setContext] = useState<any>(null);
  const [copied, setCopied] = useState<string>(L('calls.copy.link'));

  const hash = useSelector(conferenceHashSelector);
  const link = useSelector(conferenceLinkSelector);
  const title = useSelector(conferenceTitleSelector);
  const host = useSelector(userHostSelector);
  const dispatch = useDispatch();
  const handler = useCallback((e: any) => {
    e.stopPropagation();
    showContext(e)
  }, [])

  const showContext = useCallback((e: any) => {
    const alignElem = document.getElementById('conference-align');
    if (alignElem) setContext(alignElem)
  }, []);

  const smoothCloseMenu = useCallback(() => {
    if (contextRoot) {
      contextRoot.classList.remove('active');
      setTimeout(() => {
        setContext(null);
      }, 300)
    }
  }, []);

  const closeContextHandle = useCallback(() => setContext(null), []);

  const userSettingsHandle = useCallback((target) => {
    dispatch(setContextType({ type: CONTEXT_TYPES.USER_SETTINGS, data: { element: target } }))
  }, [])

  const copyLink = useCallback(() => {
    copyClipboard(link);
    setCopied(L('calls.copied.link'));
    setTimeout(() => {
      smoothCloseMenu();
      setCopied(L('calls.copy.link'));
    }, 1000)
  }, [link]);

  useContextThemeToggler(!context)
  const contextMenu = () => {
    return (
      <ContextualMenu
        className={'menu-conference-info rounded-corners-12'}
        isModal={true}
        alignToElement={context}
        closeClickedInside={false}
        onClose={closeContextHandle}
        fullWidth
      >
        <div className={'menu-conference-info-top'}>
          <Layout gap={'lg'} vertical>
            <Text size={'body-bold'}>{`${title.trim()} ${L('calls.conference').toLowerCase()}`}</Text>
            <Layout gap={'sm'}>
              <Button
                className={'rounded-corners-8'}
                size={'md'}
                color={'white'}
                iconLeft={<Link/>}
                onClick={copyLink}
              >
                {copied}
              </Button>
              {/*<Button
                className={'rounded-corners-8'}
                size={'md'}
                color={'white'}
                iconLeft={<ShareAndroid/>}
                onClick={() => {}}
              >
                {L('calls.shar_to_chat')}
              </Button>*/}
            </Layout>
          </Layout>
        </div>
        <div className={'menu-conference-info-content'}>
          <Layout gap={'lg'} vertical>
            <Layout gap={'xsm'} vertical>
              <Text size={'footnote'} type={'secondary'}>{L('calls.info.meet.id')}</Text>
              <Text>{hash}</Text>
            </Layout>
            {host && (
              <Layout gap={'xsm'} vertical className={`layout-user-name`}>
                <Text size={'footnote'} type={'secondary'}>{L('calls.host')}</Text>
                <Layout gap={'sm'}>
                  <Avatar
                    size={'20'}
                    type={'private'}
                    src={host.avatar}
                  />
                  <Text>{`${formatUserName(host)}${host.isLocal ? ' (' + L('calls.you') + ')' : ''}`}</Text>
                </Layout>
              </Layout>
            )}
            <Layout gap={'xsm'} vertical>
              <Text size={'footnote'} type={'secondary'}>{L('calls.info.meet.link')}</Text>
              <Text type={'link'}>{link}</Text>
            </Layout>
          </Layout>
        </div>
      </ContextualMenu>
    )
  }

  const renderInfoButton = useMemo(() => {
    return <Button
        className={'green-path'}
        iconInside={<Settings height={24} width={24} />}
        color={'green'}
        size={'sm'}
        onClick={({ target }) => {
          userSettingsHandle(target)
        }}
        inverted
        hovered={context}
      />
  }, [handler, context])

  return (
    <div onDoubleClick={e => e.stopPropagation()}>
      <Layout gap={'xsm'} isAlignCenter>
        <div id={'conference-align'}/>
        {(context) && contextMenu()}
        {renderInfoButton}
        {isElectron() && <div className={'time-space'}/>}
        {/*TODO шаринга пока нет*/}
        {/*<Button*/}
        {/*  color={'white'}*/}
        {/*  inverted*/}
        {/*  iconInside={<ShareAndroid/>}*/}
        {/*  size={'sm'}*/}
        {/*/>*/}
      </Layout>
    </div>
  );
})

export default ConferenceInfo;
