import {meetLog} from "./log";

export class Fullscreen {

  // full screen active?
  static active() {
    if(document.fullscreen) {
      return document.fullscreen;
    } else if(document.webkitIsFullScreen) {
      return document.webkitIsFullScreen;
    } else if(document.mozIsFullScreen) {
      return document.mozIsFullScreen;
    } else if(document.msIsFullScreen) {
      return document.msIsFullScreen;
    } else {
      // ...
      return false;
    }
  }

  // retrieve availability (user config etc)
  static enabled() {
    if(document.fullscreenEnabled) {
      return document.fullscreenEnabled;
    } else if(document.webkitFullscreenEnabled) {
      return document.webkitFullscreenEnabled;
    } else if (document.mozFullscreenEnabled) {
      return document.mozFullscreenEnabled;
    } else {
      // ...
      return false;
    }
  }

  // open element in fullscreen mode
  static open(element) {
    if(this.enabled()) {
      if(element.requestFullscreen) {
        element.requestFullscreen();
      } else if(element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullscreen) {
        element.mozRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else {
        meetLog('Oh noes! you cannot go in fullscreen due to your browser.')
        return false;
      }
    } else {
      meetLog('You did not enable fullscreen in your browser config. you cannot use this feature.');
      return false;
    }
  }

  // closes full screen mode
  static close() {
    if(document.exitFullscreen) {
      document.exitFullscreen();
    } else if(document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if(document.mozExitFullscreen) {
      document.mozExitFullscreen();
    } else if(document.msExitFullscreen) {
      document.msExitFullscreen();
    } else {
      // the message has already been given in the request handler
      return false;
    }
  }

}

