import React, {useCallback} from 'react';
import {WINDOW_SIZE} from "../../../../constants/contants";
import {setSize} from "../../../../redux/slices/ui";
import ChevronDown from "@clout-team/icons/react/ChevronDown";
import {useDispatch, useSelector} from "react-redux";
import {uiSelectorIsSmall} from "../../../../redux/selectors/ui";

const ChangeSizeButton = React.memo(() => {

  const lang = useSelector((state: any) => state.localization);
  const L = useCallback((key: string) => lang.translations[lang.locale][key], [lang]),
    isSmall = useSelector(uiSelectorIsSmall),
    dispatch = useDispatch();

  const setIsXSmallHandler = () => {
    if (window.outerHeight <= 110) {
      window.resizeTo(WINDOW_SIZE.S[0], WINDOW_SIZE.S[1]);
      dispatch(setSize('S'));
    } else {
      window.resizeTo(WINDOW_SIZE.XS[0], WINDOW_SIZE.XS[1]);
      dispatch(setSize('XS'));
    }
  }

  return (
    // TODO пока заменим на системный, была задача
    // <TooltipWrap
    //   hint={isSmall ? L('calls.hide_video_view') : L('calls.show_video_view')}
    //   arrow={'right'}
    //   className={'change-xsmall-wrap'}
    // >
    <div title={isSmall ? L('calls.hide_video_view') : L('calls.show_video_view')} className={'change-xsmall-wrap'}>
      <span className={'change-xsmall'} onClick={setIsXSmallHandler}>
        <span className={'change-xsmall-bg'}/>
        <ChevronDown/>
      </span>
    </div>
    // </TooltipWrap>
  );
});

export default ChangeSizeButton;
