import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {uiSelectorAlertType, uiSelectorIsFull} from "../../../../redux/selectors/ui";
import {Alert} from "@clout-team/web-components";
import Info from "@clout-team/icons/react/Info";
import classNames from "classnames";
import './AlertFullScreen.scss';
import {ALERT_TYPES} from "../../../../constants/contants";
import {setAlertType} from '../../../../redux/slices/ui';
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";
import {formatUserName} from "../../../../helpers/functions";

const TOP_OFFSET = 64;
const AlertFullScreen = React.memo((props: {
  L: (key: string) => string
}) => {
  const {L} = props;
  const fullScreen = useSelector(uiSelectorIsFull),
    alertType = useSelector(uiSelectorAlertType),
    dispatch = useDispatch()

  const [alert, setAlert] = useState(false);
  const [showAlert, setShowAlert] = useState({
    isShow: false,
    offset: TOP_OFFSET
  });

  useEffect(() => {
    if (fullScreen && isElectron()) {
      dispatch(setAlertType({
        type: ALERT_TYPES.FULL_SCREEN
      }))
    }
  }, [fullScreen])

  const renderTitle = useCallback(() => {
    switch (alertType.type) {
      case ALERT_TYPES.FULL_SCREEN:
        return L('calls.press_esc_to_exit_fullscreen_mode')?.replace('{__key__}', '(Esc)')
      case ALERT_TYPES.GET_HOST:
        return L('calls.now_you_are_the_host')
      case ALERT_TYPES.HOST_MUTE_USER:
        if (alertType.data) {
          return L('calls.you_have_turned_off_the_microphone_of_the_user')?.replace('{__user__}', formatUserName(alertType.data))
        }
        return '';
      case ALERT_TYPES.I_MUTED:
        if (alertType.data) {
          return L('calls.user_turned_off_you_microphone')?.replace('{__user__}', formatUserName(alertType.data))
        }
        return '';
      case ALERT_TYPES.HOST_TURN_OFF_VIDEO:
        if (alertType.data) {
          return L('calls.you_have_turned_off_the_video_of_the_user')?.replace('{__user__}', formatUserName(alertType.data))
        }
        return '';
      case ALERT_TYPES.ME_TURN_OFF_VIDEO:
        if (alertType.data) {
          return L('calls.user_turned_off_you_video')?.replace('{__user__}', formatUserName(alertType.data))
        }
        return '';
      case ALERT_TYPES.LINK_COPIED:
        return L("reg.tooltip.link_copied_to_clipboard");
      default:
        return "";
    }
  }, [alertType])

  useEffect(() => {
    if (alertType.type !== '') {
      const center: any = document.getElementById('main-video');

      if (alertType.type === ALERT_TYPES.FULL_SCREEN) {
        if (fullScreen) {
          setAlert(true)
          setTimeout(() => setShowAlert({
            isShow: true,
            offset: center ? center.getBoundingClientRect().top + TOP_OFFSET : TOP_OFFSET
          }), 200);
        } else {
          setAlert(false);
          setTimeout(() => {
            setShowAlert({
              isShow: false,
              offset: TOP_OFFSET
            })
            dispatch(setAlertType({type: ''}))
          }, 200);
        }
      } else if (
        alertType.type === ALERT_TYPES.GET_HOST ||
        alertType.type === ALERT_TYPES.HOST_MUTE_USER ||
        alertType.type === ALERT_TYPES.I_MUTED ||
        alertType.type === ALERT_TYPES.HOST_TURN_OFF_VIDEO ||
        alertType.type === ALERT_TYPES.ME_TURN_OFF_VIDEO ||
        alertType.type === ALERT_TYPES.LINK_COPIED
      ) {
        setAlert(true)
        setTimeout(() => setShowAlert({
          isShow: true,
          offset: center ? center.getBoundingClientRect().top + TOP_OFFSET : TOP_OFFSET
        }), 200);
      }

      setTimeout(() => {
        setAlert(false);
        setTimeout(() => {
          dispatch(setAlertType({type: ''}))
        }, 200);
      }, 5000)
    }
  }, [fullScreen, alertType.type])

  return (
    <div className={classNames('calls-alert-wrap', {
      'calls-alert-wrap_show': alert,
      'is-show-close': true
    })}>
      {showAlert.isShow ? <Alert
        // @ts-ignore
        title={renderTitle()}
        iconLeft={<Info/>}
        offsetTop={showAlert.offset}
        onClose={() => setAlert(false)}
      /> : <></>
      }
    </div>
  );
})

export default AlertFullScreen;
