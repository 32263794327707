import getLang from "../../helpers/getLanguage";

const {_lg, userLang} = getLang()

const localization = (state = {
  locale: userLang,
  translations: _lg
}, action) => {
  switch (action.type) {
    case 'SET_LOCALE': {
      return {
        translations: _lg,
        locale: action.locale,
      }
    }
    default:
      return state;
  }
};

export default localization;
