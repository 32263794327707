import {createAsyncThunk, createSlice, PayloadAction, Slice} from "@reduxjs/toolkit";
import {RootState} from "../stores/callStore";
import {Emit} from "../../helpers/socketHelper";
import {SOCKET_ACTIONS} from "../../constants/socket";
import {STORAGE_PREV_DEVICE_ID} from "../../constants/contants";

export const setDebug = createAsyncThunk<any,
  string,
  { state: RootState }>(
  'debug',
  async (str, thunkAPI) => {
    const {
      debug,
      devices,
      conference: {conference: {hash}}
    } = thunkAPI.getState();

    thunkAPI.dispatch(setDeviceList(devices))

    let items = localStorage.getItem(STORAGE_PREV_DEVICE_ID);
    let toSetDevice =
      items !== null
        ? JSON.parse(items)
        : {video: "", audio: "", speaker: ""};

    const isAudio = devices.audio.find(d => d.deviceId.toLowerCase() === toSetDevice.audio.toLowerCase());
    const isVideo = devices.video.find(d => d.deviceId.toLowerCase() === toSetDevice.video.toLowerCase());
    const isSpeaker = devices.speaker.find(d => d.deviceId.toLowerCase() === toSetDevice.speaker.toLowerCase());

    thunkAPI.dispatch(setLocal({
      devices: {
        audio: isAudio?.label,
        video: isVideo?.label,
        speaker: isSpeaker?.label
      }
    }))

    console.log ( '%c' + 'Users', 'font: 1.5em/1 Arial; color: olivedrab;', debug);

    if (hash) {
      Emit(SOCKET_ACTIONS.MEETING_CUSTOM, {
        link: hash,
        attributes: {
          userId: debug?.local?.userId,
          debugUser: debug?.local
        }
      });
    }
  })

const debugSlice: Slice = createSlice({
  name: 'debug',
  initialState: {
    local: {
      userId: '',
      userName: '',
      roomId: 0,
      audio: null,
      video: null,
      devices: {
        audio: '',
        video: '',
        speaker: ''
      },
      devicesList: {}
    },
    users: []
  },

  reducers: {
    setDeviceList: (state, {payload}: PayloadAction<any>) => {
      state.local.devicesList = payload;
    },
    setLocal: (state, {payload}: PayloadAction<any>) => {
      state.local = {...state.local, ...payload};
    },
    addUser: (state, {payload}: PayloadAction<any>) => {
      const index = state.users.findIndex(u => u.userId === payload.userId)
      if (index > -1) {
        state.users[index] = payload;
      } else {
        state.users.push(payload)
      }
    },
    delDebugUser: (state, {payload}: PayloadAction<any>) => {
      const index = state.users.findIndex(v => v.userId === payload);
      if (index > -1) {
        state.users.splice(index, 1)
      }
    },
  }
})

export const {
  setLocal,
  addUser,
  delDebugUser,
  setDeviceList
} = debugSlice.actions;
export default debugSlice.reducer;
