import { createSelector } from '@reduxjs/toolkit';
import {RootState} from "../stores/callStore";
import {User} from "../../interfaces/user";
import {USERS} from "../../constants/meeting";

export const usersSelector = createSelector(
    (state: RootState) => state.meetingUsers.users,
    (users) => users
)

export const usersInMeetingSelector = createSelector(
    (state: RootState) => state.meetingUsers.users,
    (users) => users.filter((user: User.IUsersTo) => user.key === USERS.IN_MEETING)
)

export const usersCarouselSelector = createSelector(
  (state: RootState) => state.meetingUsers.users,
  (users) => {
    const pinnedUser = users.find(u => u.isPinned)
    const localUser = users.find(u => u.isLocal)

    if (users.length === 1) return [];

    if (pinnedUser) {
      return users.filter((user: User.IUsersTo) =>
        user.key === USERS.IN_MEETING &&
        user.id !== pinnedUser.id
      )
    }

    if (users.length === 2) {
      return users.filter((user: User.IUsersTo) =>
        user.key === USERS.IN_MEETING &&
        user.isLocal
      )
    } else if (users.length > 2) {
      const isShare = users.filter(u => u.isShare);

      if (isShare.length === 1) {
        return users.filter((user: User.IUsersTo) => user.key === USERS.IN_MEETING && !user.isShare)
      } else {
        if (localUser.isShare) return users.filter((user: User.IUsersTo) => user.key === USERS.IN_MEETING && (user.id / 1000) !== localUser.id)
        else return users.filter((user: User.IUsersTo) => user.key === USERS.IN_MEETING && !(user.isShare && user.isActive))
      }
    }

    return users.filter((user: User.IUsersTo) => user.key === USERS.IN_MEETING)
  }
)

export const usersInMeetingNotShareSelector = createSelector(
  (state: RootState) => state.meetingUsers.users,
  (users) => users.filter((user: User.IUsersTo) => (user.key === USERS.IN_MEETING && !user.isShare) || user.isLocal)
)

export const usersActiveSelector = createSelector(
    (state: RootState) => state.meetingUsers.users,
    (users) => users.filter((user: User.IUsersTo) => user.key === USERS.IN_MEETING || user.key === USERS.CALLING)
)

export const usersCallingNCanceledSelector = createSelector(
    (state: RootState) => state.meetingUsers.users,
    (users) => users.filter((user: User.IUsersTo) => user.key === USERS.CALLING || user.key === USERS.CANCELLED)
)

export const usersCallingSelector = createSelector(
    (state: RootState) => state.meetingUsers.users,
    (users) => users.filter((user: User.IUsersTo) => user.key === USERS.CALLING)
)

export const usersCanceledSelector = createSelector(
    (state: RootState) => state.meetingUsers.users,
    (users) => users.filter((user: User.IUsersTo) => user.key === USERS.CANCELLED)
)

export const usersWaitingSelector = createSelector(
    (state: RootState) => state.meetingUsers.users,
    (users) => users.filter((user: User.IUsersTo) => user.key === USERS.WAITING)
)

export const userActiveSelector = createSelector(
    (state: RootState) => state.meetingUsers.users,
    (users) => users.find((user: User.IUsersTo) => user.isActive === true)
)

export const userShareSelector = createSelector(
  (state: RootState) => state.meetingUsers.users,
  (users) => users.find((user: User.IUsersTo) => user.isShare === true && !user.isLocal)
)

export const userPinnedSelector = createSelector(
  (state: RootState) => state.meetingUsers.users,
  (users) => users.find((user: User.IUsersTo) => user.isPinned === true)
)

export const profileCurrentSelector = createSelector(
    (state: RootState) => state.meetingUsers?.profile,
    (profile) => profile
)

export const userCurrentSelector = createSelector(
    (state: RootState) => state.meetingUsers.users,
    (users) => users.find((user: User.IUsersTo) => user.isLocal === true)
)

export const userHostSelector = createSelector(
    (state: RootState) => state.meetingUsers.users,
    (users) => users.find((user: User.IUsersTo) => user.isHost === true)
)

export const userRespondingSelector = createSelector(
  (state: RootState) => state.meetingUsers.users,
  (users) => users.find((user: User.IUsersTo) => !user.isLocal && !user.isShare)
)

export const userSpeakerSelector = createSelector(
  (state: RootState) => state.meetingUsers.users,
  (users) => users.find((user: User.IUsersTo) => user.isSpeaking === true)
)

export const userProfileSelector = createSelector(
  (state: RootState) => state.meetingUsers.profile,
  (profile) => profile
)

export const nowAtMeetSelector = createSelector(
  (state: RootState) => state.meetingUsers.nowAtMeet,
  (nowAtMeet) => nowAtMeet
)
