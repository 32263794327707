import {createSlice, PayloadAction, Slice} from "@reduxjs/toolkit";

const contactsSlice: Slice = createSlice({
  name: 'contacts',
  initialState: [],
  reducers: {
    setContacts: (state, {payload}: PayloadAction<any>) => {
      state = payload
    }
  },
  extraReducers: {}
})

export const {
    setContacts
} = contactsSlice.actions;
export default contactsSlice.reducer;