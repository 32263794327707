import { Cookies } from "react-cookie";
const cookies = new Cookies();

export const declOfNum = (number: number, titles: string[]) => {
  let cases = [2, 0, 1, 1, 1, 2];

  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

export const classNames = (condition: boolean, className: string) => {
  return condition ? className : ``;
};

export const lang = (string: string) => {
  return transliterate(string, false);
};

const transliterate = (string: string, engToRus: boolean) => {
  if (string) {
    string = string.toLowerCase();
    if (engToRus) {
      // langReverse
      string = string.replace(/shh/g, "щ");
      string = string.replace(/sh/g, "ш");
      string = string.replace(/ch/g, "ч");
      string = string.replace(/cz/g, "ц");
      string = string.replace(/yu/g, "ю");
      string = string.replace(/ya/g, "я");
      string = string.replace(/yo/g, "ё");
      string = string.replace(/zh/g, "ж");
      string = string.replace(/``/g, "ъ");
      string = string.replace(/y'/g, "ы");
      string = string.replace(/e`/g, "э");
      string = string.replace(/a/g, "а");
      string = string.replace(/b/g, "б");
      string = string.replace(/v/g, "в");
      string = string.replace(/g/g, "г");
      string = string.replace(/d/g, "д");
      string = string.replace(/e/g, "е");
      string = string.replace(/z/g, "з");
      string = string.replace(/i/g, "и");
      string = string.replace(/j/g, "й");
      string = string.replace(/k/g, "к");
      string = string.replace(/l/g, "л");
      string = string.replace(/m/g, "м");
      string = string.replace(/n/g, "н");
      string = string.replace(/o/g, "о");
      string = string.replace(/p/g, "п");
      string = string.replace(/r/g, "р");
      string = string.replace(/s/g, "с");
      string = string.replace(/t/g, "т");
      string = string.replace(/u/g, "у");
      string = string.replace(/f/g, "ф");
      string = string.replace(/x/g, "х");
      string = string.replace(/`/g, "ь");
    } else {
      // lang
      string = string.replace(/щ/g, "shh");
      string = string.replace(/ш/g, "sh");
      string = string.replace(/ч/g, "ch");
      string = string.replace(/ц/g, "cz");
      string = string.replace(/ю/g, "yu");
      string = string.replace(/я/g, "ya");
      string = string.replace(/ё/g, "yo");
      string = string.replace(/ж/g, "zh");
      string = string.replace(/ъ/g, "``");
      string = string.replace(/ы/g, "y'");
      string = string.replace(/э/g, "e`");
      string = string.replace(/а/g, "a");
      string = string.replace(/б/g, "b");
      string = string.replace(/в/g, "v");
      string = string.replace(/г/g, "g");
      string = string.replace(/д/g, "d");
      string = string.replace(/е/g, "e");
      string = string.replace(/з/g, "z");
      string = string.replace(/и/g, "i");
      string = string.replace(/й/g, "j");
      string = string.replace(/к/g, "k");
      string = string.replace(/л/g, "l");
      string = string.replace(/м/g, "m");
      string = string.replace(/н/g, "n");
      string = string.replace(/о/g, "o");
      string = string.replace(/п/g, "p");
      string = string.replace(/р/g, "r");
      string = string.replace(/с/g, "s");
      string = string.replace(/т/g, "t");
      string = string.replace(/у/g, "u");
      string = string.replace(/ф/g, "f");
      string = string.replace(/х/g, "x");
      string = string.replace(/ь/g, "`");
    }
  }
  return string;
};

export const langReverse = function (string: string) {
  return transliterate(string, true);
};

export const conditionSearch = (field: string, searchValue: string) => {
  const lowercaseField = field.toLowerCase();
  const lowercaseValue = searchValue.toLowerCase();
  return (
    lowercaseField.indexOf(lang(lowercaseValue)) > -1 ||
    lowercaseField.indexOf(langReverse(lowercaseValue)) > -1
  );
};

export const getUrlParamsByKey = (key: string) => {
  let result = "",
    tmp: any = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === key) result = tmp[1];
    });
  return result;
};

/*export function useEffectAsync(
    effect: () => Promise<void>,
    deps?: DependencyList
): void {
    useEffect(() => {
        ;(async function () {
            await effect()
        })()
    }, deps)
}*/
//useEffectAsync(async() => {  await func()  }, [])


export const getValidatedString = (string, regexp) => {
  const reg = new RegExp(regexp)
  const isValid = reg.test(string)

  if (!isValid) {
    return string
      .split('')
      .filter((symbol) => reg.test(symbol))
      .join('')
  }

  return string
}
