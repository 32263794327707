import {BASE_URL} from "../constants/contants";
import getLang from "../helpers/getLanguage";
import {getToken} from "../helpers/functions";
import {meetLog} from "../helpers/log";

const axios = require('axios');

const checkResponse = (response: any) => response.data;
const catchError = (error: any) => error;
const {userLang} = getLang()
export const request = (method: string, url: string, data: any, cancelToken: any = false) => {
  const token = getToken()

  if (token && token !== 'undefined') axios.defaults.headers.common['token'] = token;
    url = `${BASE_URL}${url}`;

  let params: any = {method, url, data, headers: {
      lang: userLang
  }};
  if (cancelToken)
    params = {method, url, cancelToken: cancelToken.token};

  return axios.request(params).then(checkResponse).catch(catchError);
};

export const requestSync = (method: string = 'GET', url: string) => {
  let object = ''
  url = `${BASE_URL}${url}`;
  const xhr = new XMLHttpRequest();
  xhr.open(method, url, false);
  const token = getToken()
  if (token && token !== 'undefined') {
    xhr.setRequestHeader('token', token);
  }
  const str = new FormData();
  xhr.onreadystatechange = function (e: any) {

    if (e.target.readyState === 4) {
      if (e.target.status === 200 || e.target.status === 400) {
        try {
          object = JSON.parse(e.target.response);
        } catch (e) {
          meetLog('e', e);
        }
      }
    }
  }
  xhr.send(str);
  return object;
}
