import {Action, combineReducers, configureStore, ThunkAction} from '@reduxjs/toolkit';
import conferenceSlice from '../slices/conferense';
import usersSlice from '../slices/users';
import uiSlice from '../slices/ui';
import settingsSlice from '../slices/settings';
import debugSlice from '../slices/debug';
import localization from "../localization/reducers";
import contactsSlice from "../slices/contacts";
import devicesSlice from '../slices/devices';

const reducer = combineReducers({
  conference: conferenceSlice,
  settings: settingsSlice,
  meetingUsers: usersSlice,
  ui: uiSlice,
  contacts: contactsSlice,
  devices: devicesSlice,
  debug: debugSlice,
  localization
})

const configurator = () => {
  return configureStore({
    devTools: true,
    reducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      thunk: true,
      serializableCheck: false
    })
  })
}

export type RootState = ReturnType<typeof reducer>;
export type CallStore = ReturnType<typeof configurator>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;
export type CallDispatch = CallStore['dispatch'];

export default configurator();

