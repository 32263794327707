import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import {deviceFilter} from "../../helpers/devicesHelper";

export type MediaDeviceType = InputDeviceInfo | MediaDeviceInfo;

interface IInitialState {
  /** @description Список устройство для ввода аудио (микрофоны) */
  audio: MediaDeviceType[],

  /** @description Список устройство для вывода аудио (наушники, динамики и т.п.) */
  speaker: MediaDeviceType[],

  /** @description Список устройство для ввода видео (камера) */
  video: MediaDeviceType[],
}
const initialState: IInitialState = {
  audio: [],
  video: [],
  speaker: [],
}

const devicesSlice: Slice<typeof initialState> = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    updateDevices: (state, { payload }: PayloadAction<MediaDeviceType[]>) => {
      /** Инициализация и разделение устройств по массивам */
      state.speaker = (payload ?? [])
        ?.filter(deviceFilter("audiooutput"));
      state.video = (payload ?? [])
        ?.filter(deviceFilter("videoinput"))
      state.audio = (payload ?? [])
        ?.filter(deviceFilter("audioinput"))
    },
  },
  extraReducers: {}
})

export const {
  updateDevices
} = devicesSlice.actions;
const devicesReduces = devicesSlice.reducer
export default devicesReduces;
