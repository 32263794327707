import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { contextRoot } from '../App';
import { settingsSelectorIsInvertRightBar } from '../redux/selectors/settings';
export const useContextThemeToggler = (disabled?: boolean) => {
  const inverted = useSelector(settingsSelectorIsInvertRightBar);
  useEffect(() => {
    if (!disabled) {
      contextRoot?.classList?.remove("inverted-theme-aware")
      return () => {
        if (inverted) {
          contextRoot?.classList?.add("inverted-theme-aware")
        }
      }
    }
  }, [disabled])
  return {inverted}
}