import React from 'react';
import './VideoLoading.scss';
import {Text} from "@clout-team/web-components";
import ShareScreenFilled from "@clout-team/icons/react/ShareScreenFilled";
import VideoFilled from "@clout-team/icons/react/VideoFilled";
import IconLoading from "../../../../components/IconLoading/IconLoading";

const VideoLoading = React.memo((props: {
  type: 'video' | 'share',
  description: string
}) => {
  const {type, description} = props;

  return (
    <div className={'video-loading'}>
      <div className="video-loading-content">
        <IconLoading icon={type === 'video' ? <VideoFilled/> : <ShareScreenFilled/>}/>
        <Text>{description}</Text>
      </div>
    </div>
  );
});

export default VideoLoading;
