import React, {useCallback, useState} from 'react';
import Apps from "@clout-team/icons/react/Apps";
import Checkmark from "@clout-team/icons/react/Checkmark";
import {Button, ContextualMenu, Header, Text, ListItem} from "@clout-team/web-components";
import {useDispatch, useSelector} from "react-redux";
import {uiSelectorLayout} from "../../../../redux/selectors/ui";
import {LAYOUTS} from "../../../../constants/contants";
import {setLayout} from "../../../../redux/slices/ui";
import { useContextThemeToggler } from '../../../../helpers/useContextThemeToggler';

const ViewChangeButton = React.memo((props: {
  L: (key: string) => string,
}) => {
  const {L} = props;
  const [context, setContext] = useState<any>(null);
  const dispatch = useDispatch(),
    layout = useSelector(uiSelectorLayout)


  const closeContextHandle = useCallback(() => setContext(null), []);

  const handler = useCallback((e: any) => {
    e.stopPropagation();
    setContext(e.currentTarget)
  }, [])

  useContextThemeToggler(!context)
  const contextMenu = () => {
    return (
      <ContextualMenu
        className={'rounded-corners-12'}
        isModal={true}
        alignToElement={context}
        closeClickedInside={false}
        onClose={closeContextHandle}
      >
        <Header size={'sm'} left={<Text size={'footnote'} type={'secondary'}>{L('calls.meeting_view')}</Text>}/>
        {/*<ListItem*/}
        {/*  active={layout === LAYOUTS.SPOTLIGHT}*/}
        {/*  iconRight={layout === LAYOUTS.SPOTLIGHT ? <Checkmark color={'#407EC9'}/> : <></>}*/}
        {/*  key={'spotlight'}*/}
        {/*  title={L('calls.spotlight')}*/}
        {/*  size={'sm'}*/}
        {/*  onClick={() => {*/}
        {/*    dispatch(setLayout(LAYOUTS.SPOTLIGHT))*/}
        {/*    setContext(null)*/}
        {/*  }}*/}
        {/*/>*/}
        <ListItem
          active={layout === LAYOUTS.STACK}
          iconRight={layout === LAYOUTS.STACK ? <Checkmark color={'#407EC9'}/> : <></>}
          key={'stack'}
          title={L('calls.stack')}
          size={'sm'}
          onClick={() => {
            dispatch(setLayout(LAYOUTS.STACK))
            setContext(null)
          }}
        />
        <ListItem
          active={layout === LAYOUTS.GRID}
          iconRight={layout === LAYOUTS.GRID ? <Checkmark color={'#407EC9'}/> : <></>}
          key={'grid'}
          title={L('calls.grid')}
          size={'sm'}
          onClick={() => {
            dispatch(setLayout(LAYOUTS.GRID))
            setContext(null)
          }}
        />
      </ContextualMenu>
    )
  }

  return (
    <div className={'view-chage-button'}>
      {(context) && contextMenu()}
      <Button
        iconLeft={<Apps/>}
        color={'white'}
        size={'sm'}
        onClick={handler}
        inverted
        hovered={context}
      >
        {L('calls.view')}
      </Button>
    </div>
  );
});

export default ViewChangeButton;
