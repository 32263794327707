import React, {useCallback, useEffect, useState} from "react";
import {
  Button,
  Checkbox,
  GridView, Layout,
  ScrollSection,
  Text,
  TrafficLights
} from "@clout-team/web-components";
import './DisplayMedia.scss';
import {useSelector} from "react-redux";
import { getOs } from "../../../../invorkers";
import { isShowLoading } from "../../../../helpers/functions";

const DisplayMedia = () => {
  const [screenSources, setScreenSources] = useState<any[]>([]);
  const [selected, setSelected] = useState<number>(0);
  const lang = useSelector((state: any) => state.localization);
  const L = (key: string) => lang.translations[lang.locale][key];
  const isMac = getOs() === 'mac'

  useEffect(() => {
    isShowLoading(false);
    // @ts-ignore
    window.ipcRenderer?.on('set-sources', (sources) => {
      try {
        const sourcesArray = JSON.parse(sources);
        if (Array.isArray(sourcesArray)) {
          setScreenSources([
            ...sourcesArray.filter((source: any) => source.id.indexOf('screen') !== -1),
            ...sourcesArray.filter((source: any) => source.id.indexOf('window') !== -1)
          ]);
        }
      } catch (error) {

      }
    })
  }, [])

  const onClose = () => {
    // requestCloseFocusedWindow()
  }

  const onMinimize = () => {
    // requestMinimizeFocusedWindow()
  }

  const onMaximize = () => {
    // requestMaxFocusedWindow()
  }

  const renderTrafficLight = useCallback(() => {
    let isWindows = {};
    return <TrafficLights
      {...isWindows} isCustom
      // isFullScreen={changeResizeButton}
      onClose={onClose} onMinimize={onMinimize} onMaximize={onMaximize}/>
  }, [])

  return (
    <div className={'display-media'}>
      {/* <div className={classNames('display-media-header', {
        // 'display-media-header-win': appUi !== UI_TYPE.MAC
      })}>
        {isMac && renderTrafficLight()}
      </div> */}
      <div className={'display-media-title'}>
        <Text size={'dialog-title'}>{L('electron.title.share_an_application_window')}</Text>
      </div>
      <div className={'display-media-content'}>
        <ScrollSection className={'display-media-content__scroll-section'}>
          {
            <GridView title={true} view={'media'} type={'float'} items={screenSources.map((source: any, ind: number) => {
              const url = source.thumbnail;
              return {
                key: `des-${source.id}`,
                id: source.id,
                showText: true,
                isName: true,
                size: 120,
                onClick: (e: any) => {
                  e.stopPropagation();
                  const index = screenSources.findIndex(val => val.id === source.id);
                  if (index > -1)
                    setSelected(index);
                },
                type: 'media',
                state: selected === ind ? "selected" : "rest",
                isStroke: true,
                text: String(source.name),
                item: {
                  title: String(source.name),
                  name: String(source.name),
                  isMediaClick: true,
                },
                avatar: {
                  src: url
                }
              };
            })}
                      className={undefined} header={undefined} style={undefined} onContextMenu={undefined}
                      clickItem={undefined}
                      doubleClickItem={undefined} clickMedia={undefined} onlyHover={undefined} canViewType={undefined}
                      hasBackground={undefined}/>
          }
        </ScrollSection>
      </div>
      <div className={'display-media-buttons'}>
        {/* <Checkbox title={L('electron.title.share_sound')} id={`audio-checkbox`}/> */}
        <Layout className="display-media-buttons__container" gap={'lg'}>
          <Button
          key={'btn-1'}
          size={'lg'}
          inverted={true}
          color={'white'}
          onClick={() => {
            window.ipcRenderer?.invoke('electron-invorkers', {
              type: 'close-screen-picker'
            })
          }}>
            {L('contacts.button.cancel')}
          </Button>
          <Button
            key={'btn-2'} size={'lg'}
            onClick={() => {
              window.ipcRenderer?.invoke('electron-invorkers', {
                type: 'share-screen',
                data: screenSources[selected].id
              })
            }}
          >
            {L('conference.button.share')}
          </Button>
        </Layout>
      </div>
    </div>
  )

}

export default DisplayMedia;
