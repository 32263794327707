import callStore from "../redux/stores/callStore";
import {
  BROWSERS, DOMAIN,
  PLUG_TYPES, SOCKET_VERSION,
  STORAGE_PREV_DEVICE_ID
} from "../constants/contants";
import {
  deleteUserAsync,
  setUsers,
  setUserSpeaking,
  updateUserLocal
} from "../redux/slices/users";
import {setConferenceHash, setMeetingLink, setPermissions} from "../redux/slices/conferense";
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";
import {Emit, emitEvent, emitLog, getSocket} from "./socketHelper";
import {
  destroyConference,
} from "./common";
import hark from "hark";
import {setActiveDevices} from "../redux/slices/settings";
import {SOCKET_ACTIONS} from "../constants/socket";
import {addWaitingStream, playSounds} from "./functions";
import {changeUserDevice, JanusSingleton} from "../janus/singleton";
import {ACTION_KEYS} from "../components/ContextMenus/UserSettings";
import joinSound from "../audio/enter_in_room.mp3";
import {getDate, meetLog} from "./log";
import {createRoom} from "../api/calls";
import {setFromWaiting, setRout} from "../redux/slices/ui";
import {sendToElectron} from "@clout-team/web-components";

export const addSpeakEvent = (stream: any, userId: number) => {
  const speechEvents = hark(stream, {});
  speechEvents.on('speaking', function () {
    console.log('[addSpeakEvent] speaking', userId);
    callStore.dispatch(setUserSpeaking({userId, isSpeak: true}));
  });
  speechEvents.on('stopped_speaking', function () {
    console.log('[addSpeakEvent] stopped_speaking', userId);
    callStore.dispatch(setUserSpeaking({userId, isSpeak: false}));
  });
}

export const getMedia = async (params: Call.IMediaParams) => {
  //const permissions = callStore.getState().conference.conference.permissions;
  const tracks: MediaStreamTrack[] = [];
  /*if (permissions.camera && permissions.microphone) {
    const stream: any = await navigator.mediaDevices
      .getUserMedia(params)
      .catch((err: any) => {
        meetLog(err);
        callStore.dispatch(setPermissions({microphone: false, camera: false}));
        callStore.dispatch(updateUserLocal({video: false, audio: false}));
      });

    if (stream) return stream;
  } else if (permissions.camera && !permissions.microphone) {*/
  const videoStream: any = await navigator.mediaDevices
    .getUserMedia({video: params.video})
    .catch((err: any) => {
      callStore.dispatch(setPermissions({camera: false}));
      callStore.dispatch(updateUserLocal({video: false}));
      meetLog(err);
    });

  if (videoStream) {
    const videoTrack = videoStream.getVideoTracks();
    if (videoTrack[0]) tracks.push(videoTrack[0]);
    callStore.dispatch(setPermissions({camera: true}));
  }
  /*} else if (permissions.microphone && !permissions.camera) {*/
  const audioStream: any = await navigator.mediaDevices
    .getUserMedia({audio: params.audio})
    .catch((err: any) => {
      callStore.dispatch(setPermissions({microphone: false}));
      callStore.dispatch(updateUserLocal({audio: false}));
      meetLog(err);
    });

  if (audioStream) {
    const audioTrack = audioStream.getAudioTracks();
    if (audioTrack[0]) tracks.push(audioTrack[0]);
    callStore.dispatch(setPermissions({microphone: true}));
  }
  // }

  return new MediaStream(tracks);
};

export const getConnectedDevices = async () => {
  return await navigator.mediaDevices.enumerateDevices();
};

export const endCall = (isHost = true, type: string) => {
  const store = callStore.getState()

  // 8685
  if (isHost) {
    const hash = store.conference.conference.hash;
    Emit(SOCKET_ACTIONS.MEETING_HOST_CLOSE, {link: hash})
  }

  JanusSingleton.leave();
  if (!isElectron()) {
    destroyConference(type)
    callStore.dispatch(setUsers([]));
    JanusSingleton.destroy();
  }

  const socket = getSocket();
  if (socket) socket.disconnect();

  // таймаут так как сокет выше не успевает отправить событие
  setTimeout(() => {
    if (isElectron()) sendToElectron('close-window-calls')
  }, 100)
}

export const rejectCall = () => {
  if (!isElectron()) {
    window.close();
  }
}

export const createAudio = (id: string, fio: string, speaker: string) => {
  const exist = document.getElementById(id);
  if (!exist) {
    const audio: HTMLAudioElement = document.createElement('audio');
    audio.id = id;
    audio.style.display = 'none';
    audio.autoplay = true;
    audio.setAttribute('data-user', fio);
    audio.setAttribute('playsinline', 'true');
    document.body.append(audio);

    // @ts-ignore
    if (speaker && typeof audio.sinkId !== 'undefined') {
      // @ts-ignore
      audio.setSinkId(speaker)
    }

    emitLog({
      message: `Добавляем тег аудио в боди для юзера ${fio}`,
      date: getDate(),
      publisher: false
    })

    setTimeout(() => {
      const a: any = document.getElementById(id);
      if (a) {
        emitLog({
          message: `Добавлен успешно тег аудио в боди для юзера ${fio}`,
          date: getDate(),
          publisher: false
        })

        emitLog({
          message: `Тег аудио ${a.paused ? "стоит на паузе" : "воспроизводится"}`,
          date: getDate(),
          publisher: false
        })
      }
    }, 2000)

    return audio;
  }
}

export const removeAudio = (id: string) => {
  const audio = document.getElementById(`audio-${id}`);
  if (audio) audio.remove();
}

export const handleChangeDevice = async (
  device: any,
  type: string,
  isWaiting?: boolean
) => {

  let items = localStorage.getItem(STORAGE_PREV_DEVICE_ID);
  let toSetDevice = items !== null ? JSON.parse(items) : {video: "", audio: "", speaker: ""};
  switch (type) {
    case ACTION_KEYS.CHANGE_DEVICE_AUDIO:
      if (isWaiting) {
        const stream = await getMedia({
          audio: {deviceId: device.deviceId},
          video: {deviceId: toSetDevice.video}
        });
        if (stream) addWaitingStream(stream);
      } else {
        changeUserDevice({
          deviceId: device.deviceId,
          isAudio: true
        });
      }
      callStore.dispatch(
        setActiveDevices({
          audio: device.deviceId,
        })
      );
      localStorage.setItem(
        STORAGE_PREV_DEVICE_ID,
        JSON.stringify({...toSetDevice, audio: device.deviceId})
      );
      break;

    case ACTION_KEYS.CHANGE_DEVICE_VIDEO:
      if (isWaiting) {
        const stream = await getMedia({
          audio: {deviceId: toSetDevice.audio},
          video: {deviceId: device.deviceId}
        });
        if (stream) addWaitingStream(stream);
      } else {
        changeUserDevice({
          deviceId: device.deviceId,
          isVideo: true
        });
      }
      callStore.dispatch(
        setActiveDevices({
          video: device.deviceId,
        })
      );
      localStorage.setItem(
        STORAGE_PREV_DEVICE_ID,
        JSON.stringify({...toSetDevice, video: device.deviceId})
      );
      break;

    case ACTION_KEYS.CHANGE_DEVICE_SPEAKER:
      if (!isWaiting) {
        const {meetingUsers: {users}} = callStore.getState()
        users?.forEach(user => {
          const audioElem = document.getElementById(`remoteaudio-${user.id}`)
          // @ts-ignore
          if (audioElem && typeof audioElem.sinkId !== 'undefined') {
            // @ts-ignore
            audioElem.setSinkId(device.deviceId)
          }
        })
      }
      callStore.dispatch(
        setActiveDevices({
          speaker: device.deviceId,
        })
      );
      localStorage.setItem(
        STORAGE_PREV_DEVICE_ID,
        JSON.stringify({...toSetDevice, speaker: device.deviceId})
      );
      break;

    default:
      break;
  }
};

export const listenerChangePermissios = () => {
  try {
    navigator.permissions
      // @ts-ignore
      .query({name: "microphone"})
      .then((permissionStatus) => {
        permissionStatus.onchange = () => {
          if (permissionStatus.state === 'denied') {
            callStore.dispatch(setPermissions({microphone: false}));
            callStore.dispatch(updateUserLocal({audio: false}));
          } else {
              callStore.dispatch(setPermissions({microphone: true}));
              // callStore.dispatch(updateUserLocal({audio: true}));
          }
        };
      })
      .catch((error) => {
        // couldn't query the permission
        console.error(error);
      });

    navigator.permissions
      // @ts-ignore
      .query({name: "camera"})
      .then((permissionStatus) => {
        permissionStatus.onchange = () => {
          if (permissionStatus.state === 'denied') {
            callStore.dispatch(setPermissions({camera: false}));
            callStore.dispatch(updateUserLocal({video: false}));
          } else {
              callStore.dispatch(setPermissions({camera: true}));
              // callStore.dispatch(updateUserLocal({video: true}));
          }
        };
      })
      .catch((error) => {
        // couldn't query the permission
        console.error(error);
      });
  } catch (e) {
    console.log(e);
  }
}

export const getUserDevices = () => {

  // очистим локалсторадж для новых юзеров
  if (!localStorage.getItem('clearDevices1')) {
    localStorage.removeItem(STORAGE_PREV_DEVICE_ID);
    localStorage.removeItem('clearDevices');
    localStorage.setItem('clearDevices1', 'true')
  }

  let items = localStorage.getItem(STORAGE_PREV_DEVICE_ID);
  return items !== null ? JSON.parse(items) : {video: "", audio: "", speaker: ""};
}

export const joinUser = () => {
  playSounds(joinSound, 2500);
}

export const roomBehavior = async () => {
  const {
    conference: {conference: {title, hash}}
  } = callStore.getState();

  // если есть хэш
  if (hash) {
    // инфа о комнате и подключение
    emitEvent({
      'event': 'info',
      'tmp_key': 'start',
      'link': hash
    })
  } else {
    // создаем комнату и заходим
    const roomRequest = await createRoom();
    if (roomRequest?.response?.hash) {
      const responseHash = roomRequest.response.hash;
      // создаем комнату и подключаемся
      Emit(SOCKET_ACTIONS.MEETING_CREATE, {
        'link': responseHash,
        'title': title
      });
      const meetingLink = `${DOMAIN}/${responseHash}`;

      callStore.dispatch(setFromWaiting(true));
      callStore.dispatch(setConferenceHash(responseHash));
      callStore.dispatch(setMeetingLink(meetingLink));
      callStore.dispatch(setRout(''));
      document.dispatchEvent(new CustomEvent('navigate', {detail: `/${responseHash}`}));
    } else {
      meetLog("не удалось создать комнату");
    }
  }
};
