import React, { useCallback, useMemo } from "react";
import { Button, Layout, TooltipWrap } from "@clout-team/web-components";
import { useDispatch, useSelector } from "react-redux";
import { userCurrentSelector } from "../../../redux/selectors/users";
import { User } from "../../../interfaces/user";
import { updateUserLocal } from "../../../redux/slices/users";
import classNames from "classnames";
import { conferencePermissions } from "../../../redux/selectors/conference";
import { IPermissions } from "../../../redux/slices/conferense";
import { getMedia } from "../../../helpers/callsHelper";
import { settingsSelectorActiveDevices } from "../../../redux/selectors/settings";

import Mic from "@clout-team/icons/react/Mic";
import MicOff from "@clout-team/icons/react/MicOff";
import Video from "@clout-team/icons/react/Video";
import VideoOff from "@clout-team/icons/react/VideoOff";
import { setConfirmType } from "../../../redux/slices/ui";
import { DIALOG_CONFIRM } from "../../../constants/contants";
import { uiSelectorIsPermissionAwaitRequest } from "../../../redux/selectors/ui";
import {addWaitingStream, removeStream} from "../../../helpers/functions";
import {meetLog} from "../../../helpers/log";

const WaitingMediaButtons = React.memo(() => {
  const lang = useSelector((state: any) => state.localization);
  const L = useCallback(
      (key: string) => lang.translations[lang.locale][key],
      [lang]
    ),
    permissions: IPermissions = useSelector(conferencePermissions),
    localUser: User.IUsersTo = useSelector(userCurrentSelector),
    activeDevices = useSelector(settingsSelectorActiveDevices),
    isPermissionAwaitRequest = useSelector(uiSelectorIsPermissionAwaitRequest),
    dispatch = useDispatch();

  const toggleAudio = useCallback(() => {
    if (!permissions.microphone) {
      dispatch(setConfirmType({ type: DIALOG_CONFIRM.SET_PERMISSIONS }));
      return;
    }
    if (localUser) {
      const isAudio = !localUser.audio;
      dispatch(updateUserLocal({ audio: isAudio }));
    }
  }, [dispatch, localUser, permissions]);

  const toggleVideo = useCallback(() => {
    if (!permissions.camera) {
      dispatch(setConfirmType({ type: DIALOG_CONFIRM.SET_PERMISSIONS }));
      return;
    }
    const isVideo = !localUser.video;
    if (isVideo) {
      getMedia({
        audio: {
          deviceId: activeDevices?.audio,
        },
        video: {
          deviceId: activeDevices?.video,
        },
      }).then((stream) => {
        addWaitingStream(stream);
      });
    } else {
      try {
        if (window.waitingStream.stream) {
          removeStream(window.waitingStream.stream)
          window.waitingStream.stream = null
        }
      }catch (e) {
        meetLog(e)
      }
    }
    dispatch(updateUserLocal({ video: isVideo }));
  }, [dispatch, localUser, permissions, activeDevices, window.waitingStream.stream]);

  const renderButtons = useMemo(() => {
    if (isPermissionAwaitRequest) return null;

    const iconSize = {
      width: 24,
      height: 24,
    };

    return (
      <Layout gap={"sm"}>
        <TooltipWrap
          hint={
            localUser?.audio
              ? L("calls.audio.mute_my_microphone")
              : L("calls.audio.unmute_my_microphone")
          }
        >
          {!permissions.microphone && (
            <span className="media-permission">!</span>
          )}
          <Button
            size={"lg"}
            className={classNames("sky-btn rounded-corners-8", {
              "red-icon": !localUser?.audio,
            })}
            iconLeft={
              localUser?.audio ? (
                <Mic {...iconSize} />
              ) : (
                <MicOff {...iconSize} />
              )
            }
            color={"white"}
            onClick={toggleAudio}
          >
            {localUser?.audio
              ? L("calls.tooltip.mute")
              : L("calls.tooltip.unmute")}
          </Button>
        </TooltipWrap>

        <TooltipWrap
          hint={
            localUser?.video
              ? L("calls.video.stop_my_video")
              : L("calls.video.start_my_video")
          }
        >
          {!permissions.camera && <span className="media-permission">!</span>}
          <Button
            tooltip={{
              hint: localUser?.video
                ? L("calls.video.stop_my_video")
                : L("calls.video.start_my_video"),
            }}
            size={"lg"}
            className={classNames("sky-btn rounded-corners-8", {
              "red-icon": !localUser?.video,
            })}
            iconLeft={
              localUser?.video ? (
                <Video {...iconSize} />
              ) : (
                <VideoOff {...iconSize} />
              )
            }
            color={"white"}
            onClick={toggleVideo}
          >
            {localUser?.video ? L("calls.video.stop") : L("calls.video.start")}
          </Button>
        </TooltipWrap>
      </Layout>
    );
  }, [isPermissionAwaitRequest, localUser?.audio, localUser?.video, L, permissions.microphone, permissions.camera, toggleAudio, toggleVideo]);

  return <div className={`micro-camera`}>{renderButtons}</div>;
});

export default WaitingMediaButtons;
