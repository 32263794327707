import React, {ReactElement} from 'react';
import './CircleButton.scss';
import {TooltipWrap} from "@clout-team/web-components";
import classNames from "classnames";

const CircleButton = (props: {
  onClick: (e: React.MouseEvent<HTMLElement>) => void
  icon: ReactElement
  hint: string
  className?: string
  isTransition?: boolean
  active?: boolean
}) => {
  const {onClick, icon, hint, className, isTransition = true, active} = props;

  return (
    <div className={classNames('circle-button', className, {
      'circle-button-smooth': isTransition,
      'is-active': active
    })}>
      <TooltipWrap hint={hint}>
        <div className={'circle-button-content'} onClick={(e) => {
          e.stopPropagation();
          onClick(e)
        }} onDoubleClick={(e) => e.stopPropagation()}>
          {icon}
        </div>
      </TooltipWrap>
    </div>
  );
};

export default CircleButton;
