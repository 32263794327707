import React, {useEffect, useState} from 'react'
import {getDuration} from "../../helpers/functions";

let intervalID: any

export default function Timer(props: {
  start?: number
}) {
  const {start} = props;
  const [time, setTime] = useState('')

  useEffect(() => {
    const getZero = (times: number) => {
      if (times < 10) return `0${times}`
      else return times
    }

    const convertTime = (milliseconds: number) => {
      const data = getDuration(milliseconds);
      return data.hours > 0 ? `${getZero(data.hours)}:${getZero(data.minutes)}:${getZero(data.seconds)}` : '' + `${getZero(data.minutes)}:${getZero(data.seconds)}`;
    }

    const initialTime = start ? start : Date.now()
    const tick = () => {
      let timeDifference = Date.now() - initialTime
      let formatted: string = convertTime(timeDifference)
      setTime(formatted)
    }

    intervalID = setInterval(() => tick(), 1000)
    return () => clearInterval(intervalID)
  }, [])

  return <>{time}</>
}
