import {BROWSERS} from "../constants/contants";

export enum MediaPermissionsErrorType {
  /** (macOS) browser does not have permission to access cam/mic */
  SystemPermissionDenied = 'SystemPermissionDenied',
  /** user denied permission for site to access cam/mic */
  UserPermissionDenied = 'UserPermissionDenied',
  /** (Windows) browser does not have permission to access cam/mic OR camera is in use by another application or browser tab */
  CouldNotStartVideoSource = 'CouldNotStartVideoSource',
  /** all other errors */
  Generic = 'Generic',
}


export const browserName = (): string => {
  const ua = navigator.userAgent;

  if (ua.search(/Firefox/) > 0) return 'Firefox';
  if (ua.search(/YaBrowser/) > 0) return 'Yandex';
  if (ua.search(/Chrome/) > 0) return 'Chrome';
  if (ua.search(/Safari/) > 0) return 'Safari';
  if (ua.match(/\sedg\/(\d+(\.?_?\d+)+)/i)) return 'Microsoft Edge';

  return '';
}

export const requestMediaPermissions = (constraints?: MediaStreamConstraints) => {
  return new Promise<boolean>((resolve) => {
    navigator.mediaDevices
      .getUserMedia(constraints ?? { audio: true, video: true })
      .then((stream: MediaStream) => {
        stream.getTracks().forEach((t) => {
          t.stop();
        });
        resolve(true);
      })
      .catch((err: any) => {
        const browser: string = browserName()

        const errName = err.name;
        const errMessage = err.message;
        let errorType: MediaPermissionsErrorType =
          MediaPermissionsErrorType.Generic;
        if (browser === BROWSERS.CHROME) {
          if (errName === 'NotAllowedError') {
            if (errMessage === 'Permission denied by system') {
              errorType =
                MediaPermissionsErrorType.SystemPermissionDenied;
            } else if (errMessage === 'Permission denied') {
              errorType =
                MediaPermissionsErrorType.UserPermissionDenied;
            }
          } else if (errName === 'NotReadableError') {
            errorType =
              MediaPermissionsErrorType.CouldNotStartVideoSource;
          }
        } else if (browser === BROWSERS.SAFARI) {
          if (errName === 'NotAllowedError') {
            errorType =
              MediaPermissionsErrorType.UserPermissionDenied;
          }
        } else if (browser === BROWSERS.EDGE) {
          if (errName === 'NotAllowedError') {
            errorType =
              MediaPermissionsErrorType.UserPermissionDenied;
          } else if (errName === 'NotReadableError') {
            errorType =
              MediaPermissionsErrorType.CouldNotStartVideoSource;
          }
        } else if (browser === BROWSERS.FIREFOX) {
          // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
          if (errName === 'NotFoundError') {
            errorType =
              MediaPermissionsErrorType.SystemPermissionDenied;
          } else if (errName === 'NotReadableError') {
            errorType =
              MediaPermissionsErrorType.SystemPermissionDenied;
          } else if (errName === 'NotAllowedError') {
            errorType =
              MediaPermissionsErrorType.UserPermissionDenied;
          } else if (errName === 'AbortError') {
            errorType =
              MediaPermissionsErrorType.CouldNotStartVideoSource;
          }
        }

        resolve({
          // @ts-ignore
          type: errorType,
          name: err.name,
          message: err.message,
        });
      });
  });
};

export const requestAudioPermissions = () => requestMediaPermissions({ audio: true, video: false })
export const requestVideoPermissions = () => requestMediaPermissions({ audio: false, video: true })
