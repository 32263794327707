import { DOMAIN_NAME } from "../../constants/contants";
import {request, requestSync} from "../../utils/fetch";

export const profileSet = async (data: any) => await request('post', `profile.set?${new URLSearchParams(data).toString()}`, {});
export const profileGet = async () => await request('get', `profile.get`, {});
export const profileGetSync = () => requestSync('get', `profile.get`);
export const createRoom = async (isPrivate = true) => await request('post', `meet/room`, {confirm_auth: isPrivate});

export const registerUser = async (name: string) => await request('post', `meet/user/registration`, {name: name});

export const setProfileName = async (name: string, surname: string = '') => await request('put', `profile.setName`, {name, surname});

export const sendMeetingDetails = async (email: string, hash: string, user_token: string) => {
  return await request('post', `meet/${hash}/send/details`, { email: email, user_token: user_token, domain: `${DOMAIN_NAME}`.replaceAll("/", "") });
}
export const sendMeetingRate = async (hash: string, user_token: string, rating: number) => {
  return await request('put', `meet/${hash}/set/rating`, { user_token: user_token, rating: rating });
}
