import React, {useCallback, useEffect, useMemo, useState} from "react";
import WaitingMediaButtons from "../WaitingMediaButtons";
import { useDispatch, useSelector } from "react-redux";
import VideoBlock from "../../../components/VideoBlock";
import { User } from "../../../interfaces/user";
import { userCurrentSelector } from "../../../redux/selectors/users";
import {
  settingsSelectorIsReverseLocalVideo,
} from "../../../redux/selectors/settings";
import "./WaitingRoomVideo.scss";
import { IPermissions } from "../../../redux/slices/conferense";
import { conferencePermissions } from "../../../redux/selectors/conference";
import { uiSelectorIsPermissionAwaitRequest } from "../../../redux/selectors/ui";
import { setIsPermissionAwaitRequest } from "../../../redux/slices/ui";

const bodyPix = require("@tensorflow-models/body-pix");

const WaitingRoomVideo = () => {
  const lang = useSelector((state: any) => state.localization),
    [update, setUpdate] = useState(0);

  const L = useCallback(
    (key: string) => lang.translations[lang.locale][key],
    [lang]
  );
  const dispatch = useDispatch(),
    localUser: User.IUsersTo = useSelector(userCurrentSelector),
    isReversLocalVideo = useSelector(settingsSelectorIsReverseLocalVideo),
    permissions: IPermissions = useSelector(conferencePermissions),
    isPermissionAwaitRequest = useSelector(uiSelectorIsPermissionAwaitRequest)

  useEffect(() => {
    const loadDiv = document.getElementById("finish-load");
    if (isPermissionAwaitRequest) return;

    if (loadDiv && permissions.camera) {
      if (localUser?.video && window.waitingStream.stream?.getVideoTracks().length === 0) {
        loadDiv.classList.add("waiting-room-video-loader-visible");
      } else if (localUser?.video && window.waitingStream.stream?.getVideoTracks().length > 0) {
        loadDiv.classList.add("waiting-room-video-loader-finish");

        setTimeout(() => {
          loadDiv.classList.remove(
            "waiting-room-video-loader-finish",
            "waiting-room-video-loader-visible"
          );
        }, 300);
      }
    } else if (loadDiv) {
      loadDiv.classList.remove(
        "waiting-room-video-loader-finish",
        "waiting-room-video-loader-visible"
      );
    }
  }, [isPermissionAwaitRequest, permissions.camera, localUser?.video, update]);

  useEffect(() => {
    window.waitingStream.registerListener(function(val) {
      setUpdate(Math.random());
    });

    dispatch(setIsPermissionAwaitRequest(false));
  }, []);

  const renderVideo = useMemo(() => {
    // TODO test camera background
    // if (window.waitingStream.stream?.stream) {
    //   setTimeout(() => {
    //     const webcamElement: any = document.querySelector("video");
    //     const canvasPerson = document.createElement("canvas");
    //     const contextPerson: any = canvasPerson.getContext("2d");
    //     meetLog(456, webcamElement);

    //     bodyPix
    //       .load({
    //         architecture: "MobileNetV1",
    //         outputStride: 16,
    //         multiplier: 0.75,
    //         quantBytes: 2,
    //       })
    //       .catch((error) => {
    //         meetLog(error);
    //       })
    //       .then((objNet: any) => {

    //         meetLog(555, objNet)

    //         objNet
    //           .segmentPerson(webcamElement, {
    //             flipHorizontal: false,
    //             internalResolution: "medium",
    //             segmentationThreshold: 0.5,
    //           })
    //           .catch((error) => {
    //             meetLog(error);
    //           })
    //           .then((personSegmentation) => {
    //             if (personSegmentation != null) {
    //               contextPerson.drawImage(
    //                 webcamElement,
    //                 0,
    //                 0,
    //                 webcamElement.width,
    //                 webcamElement.height
    //               );
    //               var imageData = contextPerson.getImageData(
    //                 0,
    //                 0,
    //                 webcamElement.width,
    //                 webcamElement.height
    //               );
    //               var pixel = imageData.data;
    //               for (var p = 0; p < pixel.length; p += 4) {
    //                 if (personSegmentation.data[p / 4] == 0) {
    //                   pixel[p + 3] = 0;
    //                 }
    //               }
    //               contextPerson.imageSmoothingEnabled = true;
    //               contextPerson.putImageData(imageData, 0, 0);
    //             }
    //           });
    //       });
    //   }, 5000)
    // }

    return (
      <VideoBlock
        isReverse={isReversLocalVideo}
        stream={window.waitingStream.stream}
        muted={true}
        className={`waiting-room-video__video`}
      />
    );
  }, [isReversLocalVideo, update]);

  const renderLoading = useMemo(() => {
    return (
      <div id={"finish-load"} className="waiting-room-video-loader">
        <span />
      </div>
    );
  }, []);

  const renderCameraText = useMemo(() => {
    if (isPermissionAwaitRequest) {
      return L("calls.camera_preparation")
    } else {
      return !localUser || (localUser?.video && !window.waitingStream.stream?.getVideoTracks().length)
      ? L("calls.camera_starting")
      : L("calls.camera.off")
    }
  }, [isPermissionAwaitRequest, L, localUser, update])

  return (
    <div className={"waiting-room-video"} id="waiting-video-responsive">
      <div className={"waiting-room-video__content"}>
        <div className={"no-stream"}>
          {renderCameraText}
        </div>
        {localUser?.video && window.waitingStream.stream?.getVideoTracks().length > 0 && renderVideo}
        <WaitingMediaButtons />
        {renderLoading}
      </div>
    </div>
  );
};

export default WaitingRoomVideo;
