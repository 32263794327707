import { createSelector } from '@reduxjs/toolkit';
import {RootState} from "../stores/callStore";

export const settingsSelectorIsReverseLocalVideo = createSelector(
  (state: RootState) => state.settings,
  (settings) => settings.isReversLocalVideo
)

export const settingsSelectorIsRememberUserName = createSelector(
  (state: RootState) => state.settings,
  (settings) => settings.isRememberUserName
)

export const settingsSelectorIsInvertRightBar = createSelector(
  (state: RootState) => state.settings,
  (settings) => settings.chatSettings.isInvertRightBar
)

export const settingsSelectorChatSettings = createSelector(
  (state: RootState) => state.settings,
  (settings) => settings.chatSettings
)


export const settingsSelectorActiveDevices = createSelector(
  (state: RootState) => state.settings,
  (settings) => settings.activeDevices as  {
    audio: string,
    video: string,
    speaker: string
  }
);

export const settingsSelectorConferenceSettings = createSelector(
  (state: RootState) => state.settings,
  (settings) => settings.conferenceSettings
)

export const settingsSelectorBlurMyBackground = createSelector(
  (state: RootState) => state.settings,
  (settings) => settings.blurMyBackground
)

export const settingsSelectorReferrer = createSelector(
  (state: RootState) => state.settings,
  (settings) => settings.referrer
)
