import { createSelector } from '@reduxjs/toolkit';
import {RootState} from "../stores/callStore";

export const subjectSelector = createSelector(
    (state: RootState) => state.conference,
    (conference) => conference.subject_hash
)

export const conferenceSelector = createSelector(
    (state: RootState) => state.conference,
    (conference) => conference.conference
)

export const conferenceIsConferenceSelector = createSelector(
    (state: RootState) => state.conference,
    (conference) => conference.conference.isConference
)

export const conferenceHashSelector = createSelector(
    (state: RootState) => state.conference,
    (conference) => conference.conference.hash
)

export const conferenceTitleSelector = createSelector(
  (state: RootState) => state.conference,
  (conference) => conference.conference.title
)

export const conferenceLinkSelector = createSelector(
  (state: RootState) => state.conference,
  (conference) => conference.conference.link
)

export const conferenceRoomIdSelector = createSelector(
  (state: RootState) => state.conference,
  (conference) => conference.conference.roomId
)

export const conferencePermissions = createSelector(
  (state: RootState) => state.conference,
  (conference) => conference.conference.permissions
)

export const conferenceToken = createSelector(
  (state: RootState) => state.conference,
  (conference) => conference.conference.token
)
