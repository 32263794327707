import React from 'react';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import callStore from "./redux/stores/callStore";
import {Provider} from "react-redux";
import {createRoot} from "react-dom/client";
import '@clout-team/web-components/build/assets/styles/style.scss';
import '@clout-team/web-components/build/index.css';
import {ApplicationStorage, SocketView} from "@clout-team/web-components";

export const runApp = () => {
    Promise.resolve().then(() => {
        const container = document.getElementById("root");
        const root = createRoot(container!);

        root.render(
            <ApplicationStorage onlyProvider>
                <SocketView onlyMeet/>
                <BrowserRouter basename={"/"}>
                    <Provider store={callStore}>
                        <App/>
                    </Provider>
                </BrowserRouter>
            </ApplicationStorage>
        );
    });
};
runApp();
