import React, {useEffect, useState} from 'react';
import {MessageBar} from "@clout-team/web-components";
import Info from "@clout-team/icons/react/Info";
import {useSelector} from "react-redux";
import {uiSelectorIsHide, uiSelectorIsPinned} from "../../../../redux/selectors/ui";
import classNames from "classnames";
import './UnmuteBar.scss';
import {JanusSingleton} from "../../../../janus/singleton";

let timeOutShow: ReturnType<typeof setTimeout>, timeOutHide: ReturnType<typeof setTimeout>

const UnmuteBar = React.memo((props: {
  L: (key: string) => string
}) => {
  const {L} = props,
    isHide = useSelector(uiSelectorIsHide),
    isPinnedUI = useSelector(uiSelectorIsPinned),
    [show, setShow] = useState(false)

  useEffect(() => {
    if (!isPinnedUI && isHide && JanusSingleton.isAudioMuted()) {
      timeOutShow = setTimeout(() => {
        setShow(true);
        timeOutHide = setTimeout(() => {
          setShow(false);
        }, 5000)
      }, 2000)
    } else {
      setShow(false);
    }
    return () => {
      clearTimeout(timeOutShow);
      clearTimeout(timeOutHide);
    }
  }, [isHide, isPinnedUI])

  return (
    <div className={classNames('unmute-message-bar', {
      'unmute-message-bar-show': show
    })}>
      <MessageBar
        type={'text-copied'}
        isAbsolute
        isClose={false}
        content={L('calls.please_key_to_unmute')?.replace('{__key__}', 'M')}
        iconLeft={<Info/>}
      />
    </div>
  );
})

export default UnmuteBar;
