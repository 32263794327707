import {batch, useDispatch} from "react-redux";
import {endSession} from "./helpers/common";
import {emitLog, socketInit} from "./helpers/socketHelper";
import "./App.scss";
import "./pages/index/index.scss";
import React, {useEffect} from "react";
import {updateDevices} from "./redux/slices/devices";
import {getConnectedDevices, getMedia, getUserDevices, listenerChangePermissios} from "./helpers/callsHelper";
import {Route, Routes} from "react-router-dom";
import {setSize} from "./redux/slices/ui";
import {addWaitingStream} from "./helpers/functions";
import {setActiveDevices} from "./redux/slices/settings";
import {IS_DEVELOP, IS_LOCALHOST, STORAGE_PREV_DEVICE_ID} from "./constants/contants";
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";
import callStore from "./redux/stores/callStore";
import {loadListeners} from "./listeners";
import PageIndex from "./pages/index";
import PageWaiting from "./pages/waiting";
import {getDate, meetLog} from "./helpers/log";
import {setDebug} from "./redux/slices/debug";
import {deviceFilter} from "./helpers/devicesHelper";
import {changeUserDevice} from "./janus/singleton";
import DisplayMedia from "./pages/index/components/DisplayMedia/DisplayMedia";
import { setPermissions } from "./redux/slices/conferense";
import { ConsoleManager } from "@clout-team/web-components";

if (isElectron()) loadListeners(callStore);

export const contextRoot = document.getElementById("context-root");
let timeOutDevices: ReturnType<typeof setTimeout>;

window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
  emitLog({
    message: `JS ошибка - ${JSON.stringify({
      errorMsg: errorMsg,
      url: url,
      lineNumber: lineNumber
    })}`,
    date: getDate(),
    publisher: true,
  })
}

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    socketInit();

    batch(async () => {

      listenerChangePermissios();

      /** set devices */
      let devices = getUserDevices();
      let a = devices.audio, v = devices.video;
      if (!a) a = true
      if (!v) v = true
      const stream = await getMedia({
        audio: typeof a === 'boolean' ? a : {deviceId: a},
        video: typeof v === 'boolean' ? v : {deviceId: v},
      });

      const connectedDevices = await getConnectedDevices();
      emitLog({
        message: `Список устройств пользователя - ${JSON.stringify(connectedDevices)}`,
        date: getDate(),
        publisher: true,
      })
      dispatch(updateDevices(connectedDevices))

      const checkAudioId = stream.getAudioTracks()[0]?.getSettings()?.deviceId;
      if (checkAudioId) devices.audio = checkAudioId;

      const checkVideoId = stream.getVideoTracks()[0]?.getSettings()?.deviceId;
      if (checkVideoId) devices.video = checkVideoId;

      const speakers = connectedDevices.filter(deviceFilter("audiooutput"))
      if (speakers && speakers.length > 0) {
        const defaultSpeaker = speakers.find(s => s.deviceId === 'default');
        if (defaultSpeaker) devices.speaker = defaultSpeaker?.deviceId
        else devices.speaker = speakers[0]?.deviceId
      }

      dispatch(
        setActiveDevices({
          video: devices.video,
          audio: devices.audio,
          speaker: devices.speaker
        })
      );

      localStorage.setItem(
        STORAGE_PREV_DEVICE_ID,
        JSON.stringify({...devices})
      );

      if (stream.getTracks().length > 0) {
        addWaitingStream(stream);
      }
    });

    /*if (window.location.host !== 'meet.lenzaos.com' && window.location.host !== 'app.meet.lenzaos.com') {
      setInterval(() => {
        dispatch(setDebug(''))
      }, 5000)
    }*/

    window.onbeforeunload = (e) => {
      endSession();
    };

    const resizeHandler = (e: any) => {
      if (e.target.innerWidth <= 700) {
        dispatch(setSize('S'));
      } else {
        dispatch(setSize('M'));
      }
    }
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  useEffect(() => {
    const contextRoot = document.querySelector('#context-root');
    const config: MutationObserverInit = {
      attributes: true,
      childList: true
    };
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        const target = mutation.target as HTMLDivElement;
        target.classList.toggle("empty", target.childElementCount === 0);
      }
    });

    if (contextRoot) {
      observer.observe(contextRoot, config);
      contextRoot.classList.toggle("empty", contextRoot.childElementCount === 0);
    }

    return () => {
      observer.disconnect();
    }
  });
  useEffect(() => {
    ConsoleManager();
    /** Подписка на изменении устройств */
    const deviceListener = (eee) => {
      getConnectedDevices().then(function (devices) {
        // devices.forEach( device => {
        //   if (device.kind == 'audioinput' && device.label) {
        //     console.log('Has Audio Access')
        //     callStore.dispatch(setPermissions({audio: true}));
        //   };
        //   if (device.kind == 'videoinput' && device.label) {
        //     console.log('Has Video Access')
        //     callStore.dispatch(setPermissions({camera: true}));
        //   };
        // })

        clearTimeout(timeOutDevices);

        timeOutDevices = setTimeout(() => {
          dispatch(updateDevices(devices));
          const {settings: {activeDevices}} = callStore.getState();
          if (activeDevices) {
            changeUserDevice({
              deviceId: activeDevices.audio,
              isAudio: true
            });
          }
        }, 500)
      });
    };

    try {
      navigator.mediaDevices.addEventListener("devicechange", deviceListener);
      return () => {
        navigator.mediaDevices.removeEventListener(
          "devicechange",
          deviceListener
        );
      };
    } catch (error) {
      meetLog("device not supports");
    }
  }, [])

  return (
    <Routes>
      <Route path="/screen-picker" element={<DisplayMedia />}/>
      <Route path="/:hash/history" element={<>history</>}/>
      <Route path="/:hash" element={<PageIndex/>}/>
      <Route path="/" element={<PageWaiting/>}/>
    </Routes>
  );
};

export default App;
