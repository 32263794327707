import React, {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Layout, sendToElectron, Text} from "@clout-team/web-components";
import classNames from "classnames";
import {User} from "../../../../interfaces/user";
import {
  userPinnedSelector,
  userActiveSelector,
  usersInMeetingSelector,
  userCurrentSelector,
} from "../../../../redux/selectors/users";
import {uiSelectorIsSmall, uiSelectorIsXSmall, uiSelectorLayout} from "../../../../redux/selectors/ui";
import {conferenceIsConferenceSelector} from "../../../../redux/selectors/conference";
import VideoLoading from "../VideoLoading/VideoLoading";
import Fullscreen from "@clout-team/icons/react/Fullscreen";
import ShareScreenFilled from "@clout-team/icons/react/ShareScreenFilled";
import {setSize} from "../../../../redux/slices/ui";
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";
import ViewCenterVideo from "../ViewCenterVideo/ViewCenterVideo";
import {LAYOUTS} from "../../../../constants/contants";
import UnmuteBar from "../UnmuteBar/UnmuteBar";
import './ViewCenter.scss'
import {formatUserName} from "../../../../helpers/functions";

const ViewCenter = React.memo(() => {
  let activeUser: User.IUsersTo = useSelector(userActiveSelector);
  const isSmall = useSelector(uiSelectorIsSmall),
    xSmall = useSelector(uiSelectorIsXSmall),
    isConference = useSelector(conferenceIsConferenceSelector),
    localUser: User.IUsersTo = useSelector(userCurrentSelector),
    layout = useSelector(uiSelectorLayout),
    pinnedUser = useSelector(userPinnedSelector),
    users = useSelector(usersInMeetingSelector),
    dispatch = useDispatch()

  if (pinnedUser) activeUser = pinnedUser;

  const lang = useSelector((state: any) => state.localization);
  const L = useCallback((key: string) => lang.translations[lang.locale][key], [lang]);

  const userView = useMemo(() => {
    if (!activeUser) return null;
    const renderVideo = () => {

      return (
        <>
          {localUser?.isShare === true && !pinnedUser && layout !== LAYOUTS.GRID ?
            localUser?.isLoadingShare ?
              <VideoLoading type={'share'} description={L('calls.your_are_presenting_your_screen')}/> :
              (
                <div className={'you-share-wrap'}>
                  <Layout gap={'sm'} vertical isAlignCenter>
                    <ShareScreenFilled width={24} height={24}/>
                    <Text align={'center'} type={'secondary'}>
                      {L('calls.you_are_sharing')}...
                    </Text>
                  </Layout>
                </div>
              )
            : (
              <>
                {activeUser.isLoadingShare && <VideoLoading type={'share'} description={
                  L('calls.user_is_presenting').replace('{__user__}', formatUserName(activeUser))
                }/>}
                <ViewCenterVideo/>
              </>
            )}
        </>
      )
    }

    return (
      <div className={'resize-view-center'}>
        {renderVideo()}
      </div>
    )
  }, [layout, activeUser, isSmall, lang, pinnedUser, localUser])

  const electronScreen = useCallback(() => {
    if (isElectron()) {
      sendToElectron('request-set-resizable')
      setTimeout(() => {
        dispatch(setSize('M'))
      }, 200)
    } else {
      dispatch(setSize('M'))
    }
  }, [activeUser, isSmall, dispatch, xSmall]);

  return (
    <div
      className={classNames(
        'participant-view-bottom',
        {
          'is-small': isSmall,
          'is-x-small': xSmall,
          'is_private': !isConference,
          'is_group': layout === LAYOUTS.STACK && users.length > 1
        }
      )}>
      <div className={classNames(
        'participant-view-bottom__content',
      )} id={'main-video'}>
        {
          isSmall && isElectron() && (
            <div className={'maximize-is-small'} onClick={electronScreen}>
              <Layout gap={'xsm'} isAlignCenter>
                <Fullscreen width={24} height={24}/>
                <Text>{L('calls.maximize_window')}</Text>
              </Layout>
            </div>
          )
        }
        {userView}
      </div>
      <UnmuteBar L={L}/>
    </div>
  );
})

export default ViewCenter;
