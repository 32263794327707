import React, {ReactElement} from 'react';
import './IconLoading.scss';

const IconLoading = (props: {
  icon: ReactElement,

}) => {
  const {icon} = props;

  return (
    <div className={'icon-loading'}>
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3066_147644)">
          <path fillRule="evenodd" clipRule="evenodd" d="M30.6228 6.66061C25.9578 6.08725 21.2334 7.05835 17.1728 9.42527C13.1122 11.7922 9.93907 15.4245 8.1391 19.7663C6.33913 24.1081 6.0115 28.9201 7.20636 33.4658C7.38186 34.1334 6.98287 34.817 6.3152 34.9925C5.64752 35.168 4.96399 34.769 4.78849 34.1013C3.4547 29.0271 3.82043 23.6555 5.82969 18.8089C7.83896 13.9623 11.3811 9.90756 15.9138 7.26541C20.4466 4.62327 25.7203 3.53925 30.9277 4.17928C36.1352 4.81931 40.9894 7.14812 44.7475 10.8093C48.5055 14.4704 50.9603 19.2622 51.7362 24.4511C52.512 29.6401 51.5661 34.9403 49.0432 39.5405C46.5204 44.1408 42.5596 47.7876 37.7671 49.9227C32.9746 52.0579 27.6144 52.5638 22.5071 51.363C21.835 51.205 21.4183 50.5321 21.5763 49.8601C21.7344 49.188 22.4072 48.7713 23.0793 48.9293C27.6546 50.0051 32.4564 49.5519 36.7497 47.6391C41.043 45.7264 44.5912 42.4594 46.8512 38.3384C49.1113 34.2173 49.9587 29.4692 49.2636 24.8208C48.5686 20.1724 46.3695 15.8798 43.0029 12.6C39.6364 9.3202 35.2878 7.23397 30.6228 6.66061Z" fill="#605E5C"/>
        </g>
        <defs>
          <clipPath id="clip0_3066_147644">
            <rect width="48" height="48" fill="white" transform="translate(4 4)"/>
          </clipPath>
        </defs>
      </svg>
      <div className={'icon-loading-icon'}>
        {icon}
      </div>
    </div>
  );
};

export default IconLoading;
