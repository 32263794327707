import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Avatar, Layout, Text } from "@clout-team/web-components";
import { conferenceHashSelector } from "../../../redux/selectors/conference";
import { Emit } from "../../../helpers/socketHelper";
import { SOCKET_ACTIONS } from "../../../constants/socket";
import "./WaitingUsers.scss";
import { nowAtMeetSelector, userProfileSelector } from "../../../redux/selectors/users";
import {formatUserName} from "../../../helpers/functions";

let timeOutInfo: ReturnType<typeof setTimeout>;
const WaitingUsers = React.memo(() => {
  const lang = useSelector((state: any) => state.localization),
    L = useCallback(
      (key: string) => lang.translations[lang.locale][key],
      [lang]
    ),
    hash = useSelector(conferenceHashSelector),
    nowAtMeet = useSelector(nowAtMeetSelector),
    profile = useSelector(userProfileSelector);

  useEffect(() => {
    if (hash) {
      const checkRoomRecursive = () => {
        timeOutInfo = setTimeout(async () => {
          Emit(SOCKET_ACTIONS.MEETING_USERS, { link: hash });
          checkRoomRecursive();
        }, 5000);
      };
      checkRoomRecursive();
      Emit(SOCKET_ACTIONS.MEETING_USERS, { link: hash });
    }

    return () => clearTimeout(timeOutInfo);
  }, [hash]);

  const renderContent = useMemo(() => {
    let data = {
      description: "",
      jsx: <></>,
    };

    if (nowAtMeet.length <= 4) {
      data.description = L("calls.now_at_the_meeting")?.replace(
        "{__users__}",
        nowAtMeet.map((u) => formatUserName(u)).join(", ")
      );
      data.jsx = nowAtMeet.map((u) => {
        return <Avatar key={u.id} size="24" src={u.avatar} type="private" />;
      });
    } else {
      const elems = nowAtMeet.slice(0, 4);
      data.description = `${L("calls.now_at_the_meeting")?.replace(
        "{__users__}",
        elems.map((u) => formatUserName(u)).join(", ")
      )} ${L("calls.and_other_participants")?.replace(
        "{__count__}",
        nowAtMeet.length - 4
      )}`;
      data.jsx = elems.map((u, i) => {
        return (
          <div className="wating-users-avatars">
            {i === 3 && <span>+{nowAtMeet.length - 4}</span>}
            <Avatar key={u.id} size="24" src={u.avatar} type="private" />
          </div>
        );
      });
    }

    return data;
  }, [L, nowAtMeet]);

  const renderEmptyText = useMemo(() => {
    if (!profile || !hash) return null;

    return (
      <Text align={"center"}>
        {L("calls.there_is_no_one_in_the_conference")}
      </Text>
    )
  }, [L, profile, hash]);

  return (
    <div className="wating-users-component">
      {nowAtMeet.length > 0 ? (
        <div className="now-at-meet">
          <Layout gap="sm" vertical isAlignCenter>
            <Layout gap="sm">{renderContent.jsx}</Layout>
            <Text align="center">{renderContent.description}</Text>
          </Layout>
        </div>
      ) : (
        renderEmptyText
      )}
    </div>
  );
});

export default WaitingUsers;
