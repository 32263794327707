import {useSelector} from "react-redux";
import {uiSelectorCommentsCounters, uiSelectorPlugType} from "../../redux/selectors/ui";
import {Button, Layout, sendToIos, Text} from "@clout-team/web-components";
import {LAND_PAGE, PLUG_TYPES, REFERRERS} from "../../constants/contants";
import React, {ReactElement, useCallback, useEffect, useMemo} from "react";
import end from "../../audio/endCall.mp3";
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";
import './finish.scss';
import SendToEmailBanner from "./components/SendToEmailBanner.tsx/SendToEmailBanner";
import RateWrapper from "./components/RateItem/RateWrapper";
import classNames from "classnames";
import {playSounds} from "../../helpers/functions";
import {JanusSingleton} from "../../janus/singleton";
import {settingsSelectorReferrer} from "../../redux/selectors/settings";
import {isIphone} from "@clout-team/helpers";

const PageFinish = () => {
    const lang = useSelector((state: any) => state.localization);
    const L = useCallback((key: string) => lang.translations[lang.locale][key], [lang]);
    const plug = useSelector(uiSelectorPlugType),
        chatCounter = useSelector(uiSelectorCommentsCounters),
        referrerLink = useSelector(settingsSelectorReferrer)

    useEffect(() => {
        JanusSingleton.destroy()
    }, [])
    useEffect(() => {
        if (plug.type !== '' && !isElectron()) playSounds(end, 2500);
    }, [plug.type])

    const renderData: {
        title: string
        data?: ReactElement
    } = useMemo(() => {
        switch (plug.type) {
            case PLUG_TYPES.YOU_LEFT_MEET:
                return {
                    title: L('calls.you_have_left_the_meeting'),
                    ...(chatCounter?.messages_count > 0
                        ? {
                            data: <SendToEmailBanner L={L} />
                        }
                        : {})
                }

            case PLUG_TYPES.MEETING_OVER:
                return {
                    title: L('calls.meet.over.title'),
                    ...(chatCounter?.messages_count > 0
                        ? {
                            data: <SendToEmailBanner L={L} />
                        }
                        : {})
                }

            case PLUG_TYPES.MEETING_OVER_HOST:
                return {
                    title: L('calls.meet.over.text'),
                    ...(
                        chatCounter?.messages_count > 0
                            ? {
                                data: <SendToEmailBanner L={L} />
                            }
                            : {}
                    )
                }
            case PLUG_TYPES.MEETING_OVER_ENTRY:
                return {
                    title: L('calls.meet.over.text')
                }

            case PLUG_TYPES.I_WAS_KICK:
                return {
                    title: L('calls.mett.remove.you'),
                }
            case PLUG_TYPES.REJECT_ENTRY:
                return {
                    title: L('calls.mett.remove.you'),
                }
            default: return {
                title: '',
            }
        }
    }, [L, chatCounter?.messages_count, plug.type])

    return(
        <div className={classNames('plug-block', {
            'is-align-center': plug.type === PLUG_TYPES.REJECT_ENTRY || plug.type === PLUG_TYPES.MEETING_OVER_ENTRY
        })}>
            <Layout gap={'lg'} vertical isAlignCenter>
                <Text size={'dialog-title'} className="meeting-title-text" align={'center'}>{renderData.title}</Text>
                <RateWrapper L={L} />
                <div>
                    <Button
                        size={'lg'}
                        onClick={() => {
                            if (isIphone()) {
                                sendToIos('meet_close', '')
                            } else {
                                if (referrerLink === REFERRERS.CHATS) window.close()
                                else document.location.href = LAND_PAGE
                            }
                        }}
                    >
                        {referrerLink === REFERRERS.CHATS ? L('calls.close_window') : L('calls.return_to_home_page')}
                    </Button>
                </div>
                {renderData.data}
            </Layout>
        </div>
    )
}

export default PageFinish
