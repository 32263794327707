import React, { useCallback } from "react";
import { Button, Dialog, Text } from "@clout-team/web-components";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmType } from "../../../redux/slices/ui";
import { BROWSERS, MEET_BRAND_NAME } from "../../../constants/contants";
import { browserName } from "../../../helpers/permissionsHelper";
import chromeIcon from "../../../assets/images/chrome_error.png";
import yandexIcon from "../../../assets/images/yandex_error.png";

const browser: string = browserName();

const DialogPermissions = () => {
  const lang = useSelector((state: any) => state.localization),
    L = useCallback(
      (key: string) => lang.translations[lang.locale][key],
      [lang]
    ),
    dispatch = useDispatch();

  const closeDialog = useCallback(() => {
    dispatch(setConfirmType({ type: "" }));
  }, []);

  return (
    <div className="dialog-permissions-wrap">
      {browser === BROWSERS.CHROME || browser === BROWSERS.YANDEX ? (
        <div className="dialog-permissions-info">
          <div className="dialog-permissions-info-arrow">
            <svg
              width="66"
              height="341"
              viewBox="0 0 66 341"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M60.0271 338.117C67.2492 318.216 66.7677 295.426 58.7431 275.686C54.7308 265.896 48.6322 257.39 43.1755 248.402C38.0397 239.896 34.1879 231.069 34.5089 220.958C34.8299 210.205 39.0027 200.094 41.731 189.823C43.1755 184.526 44.2989 179.23 44.7804 173.773C45.2618 167.996 44.7804 162.378 43.9779 156.761C42.373 145.366 38.5212 134.292 35.6323 123.058C32.7435 111.823 31.299 100.429 30.9781 88.8732C30.1756 61.9105 33.8669 34.9479 31.4595 8.14572C31.299 6.05933 29.6941 4.13342 27.4472 4.13342C25.3608 4.13342 23.2744 5.89883 23.4349 8.14572C25.6818 32.059 22.9534 55.8118 22.793 79.7251C22.793 91.6015 23.4349 103.478 25.5213 115.033C27.6077 126.428 31.4595 137.502 34.1879 148.897C36.7558 159.49 37.7187 170.243 35.4718 180.996C33.2249 191.427 29.2127 201.378 27.2868 211.81C26.3238 217.106 25.8423 222.402 26.4843 227.699C27.1263 232.995 28.7312 238.131 30.9781 242.945C35.1508 252.254 41.41 260.278 46.3853 269.106C57.7802 289.328 59.7061 314.043 51.842 335.87C51.0395 337.957 52.6445 340.203 54.5704 340.845C57.2988 341.487 59.3851 340.043 60.0271 338.117Z"
                fill="white"
              />
              <path
                d="M7.38261 43.4538C12.9998 31.8983 19.4195 20.6639 26.3207 9.91097C26.6417 9.26901 27.1231 8.78752 27.4441 8.14555C27.4441 7.98506 27.7651 7.8246 27.7651 7.8246C27.9256 7.02214 27.9256 8.14558 27.6046 7.8246L26.1602 7.50359L26.4812 7.9851C26.6417 8.62707 27.1231 9.42951 27.4441 10.0715C28.8885 13.2813 30.333 16.3306 31.7774 19.5405C34.6662 25.6392 37.5551 31.8984 40.4439 37.997C43.6538 45.0587 47.0241 51.9598 50.234 59.0215C51.1969 60.9474 53.9253 61.4289 55.6907 60.4659C57.7771 59.3425 58.098 56.9351 57.1351 55.0092C50.7154 41.2069 44.2957 27.5651 37.8761 13.7628C35.4687 8.62703 31.9379 -3.40987 24.0738 0.923415C21.1849 2.52833 19.4195 6.0592 17.6541 8.78756C15.7282 11.8369 13.8023 15.0467 12.0369 18.0961C7.86412 25.1577 4.0123 32.3798 0.481477 39.7625C-0.481474 41.6884 -8.00682e-06 44.0957 1.92589 45.2192C3.69131 46.0216 6.41966 45.5402 7.38261 43.4538Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="dialog-permissions-info-icon">
            <img
              src={browser === BROWSERS.CHROME ? chromeIcon : yandexIcon}
              alt="icon"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <Dialog
        closeOnClickOutside={false}
        dialogTitle={{
          title: L("calls.camera_and_mic_are_blocked"),
          varOnlyTitle: false,
        }}
        onClose={closeDialog}
        dialogButtons={{
          right: [
            <Button size={"lg"} onClick={closeDialog} inverted>
              {L("button.skip")}
            </Button>,
          ],
        }}
      >
        <Text>{L("calls.gice_access")?.replace('{__brand_name__}', MEET_BRAND_NAME)}</Text>
      </Dialog>
    </div>
  );
};

export default DialogPermissions;
