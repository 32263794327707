import React, {useCallback} from 'react';
import {Button, Dialog} from "@clout-team/web-components";
import {User} from "../../../interfaces/user";
import {Emit} from "../../../helpers/socketHelper";
import {SOCKET_ACTIONS} from "../../../constants/socket";
import {useSelector} from "react-redux";
import {conferenceHashSelector} from "../../../redux/selectors/conference";
import classNames from "classnames";
import {settingsSelectorIsInvertRightBar} from "../../../redux/selectors/settings";
import {formatUserName} from "../../../helpers/functions";

const DialogRemoveFromMeeting = React.memo((props: {
  L: (key: string) => string
  user: User.IUsersTo
  onClose: () => void
}) => {
  const {L, user, onClose} = props;
  const hash = useSelector(conferenceHashSelector),
  invertedRight = useSelector(settingsSelectorIsInvertRightBar);

  const removeUser = useCallback(() => {
    //dispatch(deleteUserAsync(user.id));
    Emit(SOCKET_ACTIONS.MEETING_KICK, {link: hash, id: user.id});
    onClose();
  }, [user, hash, onClose])

  return (
    <Dialog
      className={classNames("dialog-rounded-corners", {
        "inverted-theme-aware": invertedRight,
      })}
      closeOnClickOutside={false}
      dialogTitle={{
        title: L('calls.are_you_sure_you_want_to_remove_user_from_meeting')?.replace('{__user__}', formatUserName(user)),
        varOnlyTitle: false,
      }}
      onClose={onClose}
      dialogButtons={{
        right: [
          <Button key={'btn-1'} size={'lg'} inverted={true} color={'white'} onClick={onClose}>
            {L('calls.cancel')}
          </Button>,
          <Button key={'btn-2'} size={'lg'} onClick={removeUser} color={'red'}>
            {L('calls.remove')}
          </Button>
        ]
      }}
    />
  );
})

export default DialogRemoveFromMeeting;
