import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../stores/callStore";

export const devicesSelectorAudioDevices = createSelector(
  (state: RootState) => state.devices,
  (devices) => devices.audio
)
export const devicesSelectorVideoDevices = createSelector(
  (state: RootState) => state.devices,
  (devices) => devices.video
)
export const devicesSelectorSpeakersDevices = createSelector(
  (state: RootState) => state.devices,
  (devices) => devices.speaker
)
