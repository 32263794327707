import React, {useCallback, useEffect} from 'react';
import {useSelector} from "react-redux";
import {userCurrentSelector, usersInMeetingSelector} from "../../../../redux/selectors/users";
import {Button, ContextualMenu, Layout} from "@clout-team/web-components";
import {endCall} from "../../../../helpers/callsHelper";
import './EndConferenceContext.scss';
import { useContextThemeToggler } from '../../../../helpers/useContextThemeToggler';
import { PLUG_TYPES } from '../../../../constants/contants';

const EndConferenceContext = React.memo((props: {
  align: any
  onClose: () => void
  onChangeHost: () => void
}) => {
  const {align, onClose, onChangeHost} = props;
  const localUser = useSelector(userCurrentSelector);
  const inMeetingUsers = useSelector(usersInMeetingSelector);
  const lang = useSelector((state: any) => state.localization);
  const L = useCallback((key: string) => lang.translations[lang.locale][key], [lang]);

  const logOutConference = useCallback(() => {
    if (localUser.isHost) endCall(true, PLUG_TYPES.MEETING_OVER)
    else endCall(false, PLUG_TYPES.YOU_LEFT_MEET)
  }, [localUser])

  const endForEveryone = useCallback(() => {
    endCall(true, PLUG_TYPES.MEETING_OVER);
  }, [])

  const leaveHost = useCallback(() => {
    onChangeHost()
  }, [onChangeHost])

  useContextThemeToggler();
  return (
    <>
      {
        align && (
          <ContextualMenu
            className={'menu-conference-end'}
            isModal={true}
            alignToElement={align}
            closeClickedInside={false}
            onClose={onClose}
          >
            <Layout gap={'lg'} vertical>
              {
                localUser && localUser.isHost && inMeetingUsers.length > 1 ? (<>
                  <Button
                    className={'rounded-corners-8 custom-btn-style'}
                    onClick={endForEveryone}
                    color={'red'}
                  >
                    {L('calls.end_conference_for_everyone')}
                  </Button>
                  <Button
                    className={'rounded-corners-8 custom-btn-style'}
                    color={'white'}
                    onClick={leaveHost}
                  >
                    {L('calls.leave_conference')}
                  </Button>
                </>) : (
                  <Button
                    className={'rounded-corners-8 custom-btn-style'}
                    onClick={logOutConference}
                    color={'red'}
                  >
                    {L('calls.logout_conference')}
                  </Button>
                )
              }
            </Layout>
          </ContextualMenu>
        )
      }
    </>
  );
})

export default EndConferenceContext;
