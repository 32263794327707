import {User} from "../interfaces/user";
import {getUrlParamsByKey} from "../utils/functions";
import {Emit} from "./socketHelper";
import {SOCKET_ACTIONS} from "../constants/socket";
import {Cookies} from "react-cookie";
import {REFERRERS} from "../constants/contants";
import {updateUserSettings} from "./common";
import {meetLog} from "./log";

const cookies = new Cookies();

export const copyClipboard = (clip: string) => {
  navigator.clipboard.writeText(clip);
}

export const formatUserName = (user: User.IUsersTo | User.IContacts) => {
  return user ? `${user.name} ${user.surname}` : ''
}

export const isIncoming = () => {
  return getUrlParamsByKey('is_incoming')
}

export const setMediaSize = (width: number) => {
  const root = document.getElementById('root');
  if (root) {
    if (width <= 1200 && width > 800) {
      root.classList.add('md-size');
      root.classList.remove('sm-size');
    } else if (width <= 800) {
      root.classList.add('sm-size');
      root.classList.remove('md-size');
    } else {
      root.classList.remove('md-size', 'sm-size');
    }
  }
}

export const calcVideoSize = (width: number = 0, height: number = 0) => {
  function setSize(w: number, h: number) {
    if (w > h) {
      const newWidth = Math.round(h * (1 + 77.78 / 100)),
        newHeight = Math.round(w * 56.3 / 100);
      return {
        width: newWidth > w ? w : newWidth,
        height: newHeight > h ? h : newHeight
      }
    } else {
      return {
        width: w,
        height: Math.round(h * 56.3 / 100)
      }
    }
  }

  if (width > 0 && height > 0) {
    return setSize(width, height)
  } else {
    const wrap = document.getElementById('main-video');
    if (wrap) {
      const wrapWidth = wrap.offsetWidth;
      const wrapHeight = wrap.offsetHeight;
      return setSize(wrapWidth, wrapHeight)
    }
  }

  return {width: 0, height: 0}
}

export const renameTitle = (title: string, hash: string) => {
  Emit(SOCKET_ACTIONS.MEETING_SET_TITLE, {
    'link': hash,
    'title': title
  })
}

export const getToken = () => {
  return cookies.get('token');
}

export const playSounds = (soundSrc: string, delay: number) => {
  const audio = document.createElement('audio');
  audio.src = soundSrc;
  audio.autoplay = true;
  audio.loop = false;
  document.body.appendChild(audio);
  setTimeout(() => audio.remove(), delay);
}

export const removeStream = (mediaStream: any) => {
  if (!mediaStream) return;
  try {
    mediaStream.getTracks().forEach(track => {
      track.stop();
      //mediaStream.removeTrack(track);
    });
  } catch (e) {
    meetLog(e)
  }
}

export const attachStreamVideo = (userId: number, stream: MediaStream) => {
  const vds = document.querySelectorAll(`.video-${userId}`);
  if (vds.length > 0) {
    vds.forEach(v => {
      const video = v.querySelector('video');
      if (video) {
        // @ts-ignore
        window.Janus.attachMediaStream(video, stream);
      }
    })
  }
}

export const changeTheme = (theme: string) => {
  updateUserSettings({
    theme: theme.includes('theme') ? theme : `theme-${theme}`
  })
}

// TODO в хэлперы
export const getDuration = (milliseconds: number) => {
  const total_seconds = Math.floor(milliseconds / 1000);
  const total_minutes = Math.floor(total_seconds / 60);
  const total_hours = Math.floor(total_minutes / 60);
  const days = Math.floor(total_hours / 24);

  const seconds = total_seconds % 60;
  const minutes = total_minutes % 60;
  const hours = total_hours % 24;

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  }
}

export const getReferrer = () => {
  if (document.referrer.indexOf('chat.lenzaos.com') > -1) {
    return REFERRERS.CHATS
  } else if (document.referrer.indexOf('space.lenzaos.com') > -1) {
    return REFERRERS.SPACES
  } else if (document.referrer.indexOf('contacts.lenzaos.com') > -1) {
    return REFERRERS.CONTACTS
  } else return '';
}

export const isShowLoading = (isShow: boolean) => {
  const loading = document.getElementById('call-main-load');
  if (loading) {
    if (isShow) loading.classList.remove('hide-load');
    else loading.classList.add('hide-load');
  }
}

export const clearConsole = (value: string) => {
  meetLog(value)
}

export const selectText = (node: HTMLElement) => {
  const range = document.createRange();
  range.selectNodeContents(node);
  const sel: any = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
}

export const addWaitingStream = (stream: MediaStream) => {
  if (window.waitingStream.stream) {
    removeStream(window.waitingStream.stream)
  }
  window.waitingStream.stream = stream;
}

export const wordForm = (num: number, word: string[]): string => {
  const lang = cookies.get('lang') + "";
  let cases: number[] = [];
  let retDefault: number = 0;
  if (['ar', 'ru'].indexOf(lang) !== -1) {
    cases = [0, 1, 3, 3, 3, 4];
    retDefault = 4;
  } else if (['cn', 'cnSmpl', 'ja', 'ko'].indexOf(lang) !== -1) {
    cases = [0, 5, 5, 5, 5, 5];
    retDefault = 5;
  } else {
    cases = [0, 1, 5, 5, 5, 5];
    retDefault = 5;
  }

  return word[(num % 100 > 4 && num % 100 < 20) ? retDefault : cases[(num % 10 < 5) ? num % 10 : 5]];
}

export const VARIANTS_WORDS = ['zero', 'one', 'two', 'few', 'many', 'other'];
export const getAllCountResultText = (titleVariants: string, count: number) => {
  let title: string = wordForm(count, VARIANTS_WORDS);
  if (titleVariants[title]) {
    return titleVariants[title].replace('{count}', count);
  }
  return `${count} ${title}`;
}
