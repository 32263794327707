import {createSlice, PayloadAction, Slice} from "@reduxjs/toolkit";
import {UI_TYPE} from "../../constants/meeting";
import {LAYOUTS, ROUT_TYPES} from "../../constants/contants";
import {matchPath} from "react-router-dom";
import {meetLog} from "../../helpers/log";

const urlParams = matchPath({ path: "/:hash" }, window.location.pathname)

const uiSlice: Slice = createSlice({
  name: 'ui',
  initialState: {
    isPinnedUI: false,
    commentsCounters: {
      count_unread: 0,
      count_unread_alpha: 0,
      followersCount: 0,
      messages_count: 0,
    },
    rightBarType: '',
    size: window.innerWidth > 280 ? 'M' : 'S',
    fullScreen: false,
    isHide: false,
    appUi: UI_TYPE.WIN,
    isMaximized: false,
    os: UI_TYPE.WIN,
    error: '',
    firstBanner: false,
    plugType: {
      type: '',
      data: undefined
    },
    alertType: {
      type: '',
      data: undefined
    },
    confirmType: {
      type: '',
      data: undefined
    },
    isAnswer: false,
    router: urlParams && urlParams.params && urlParams.params.hash ? '' : ROUT_TYPES.GUEST,
    layout: LAYOUTS.GRID, // spotlight, grid, stack, side by side
    isChangedLayout: false, // когда первый раз кто нибудь присоединяется один раз меняем на грид
    isAwaitReady: true,
    inviteNewPerson: false,
    isAwaitStart: false,
    isAwaitHostConfirm: false,
    isShowWaitingEntry: false,
    isPermissionAwaitRequest: true,
    fromWaiting: false,
    contextType: {
      type: '',
      data: undefined
    }
  },
  reducers: {
    setIsChangedUi: (state, {payload}: PayloadAction<any>) => {
      state.isChangedLayout = payload
    },
    setFromWaiting: (state, {payload}: PayloadAction<any>) => {
      state.fromWaiting = payload
    },
    setIsPermissionAwaitRequest: (state, {payload}: PayloadAction<any>) => {
      state.isPermissionAwaitRequest = payload
    },
    setIsShowWaitingEntry: (state, {payload}: PayloadAction<any>) => {
      state.isShowWaitingEntry = payload
    },
    setRightBarType: (state, {payload}: PayloadAction<any>) => {
      state.rightBarType = payload
    },
    setCommentCounters: (state, {payload}: PayloadAction<boolean>) => {
      try {
        state.commentsCounters = payload;
      }
      catch (e) {
        meetLog(e);
      }
    },
    setIsAwaitHostConfirm: (state, {payload}: PayloadAction<any>) => {
      state.isAwaitHostConfirm = payload
    },
    setIsAwaitStart: (state, {payload}: PayloadAction<any>) => {
      state.isAwaitStart = payload
    },
    setIsAwaitReady: (state, {payload}: PayloadAction<any>) => {
      state.isAwaitReady = payload
    },
    setConfirmType: (state, {payload}: PayloadAction<any>) => {
      state.confirmType = payload
    },
    setContextType: (state, {payload}: PayloadAction<any>) => {
      state.contextType = payload
    },
    setPlugType: (state, {payload}: PayloadAction<any>) => {
      if( state.plugType.type === '' )
        state.plugType = payload
    },
    setFirstBanner: (state, {payload}: PayloadAction<any>) => {
        state.firstBanner = payload
    },
    setRout: (state, {payload}: PayloadAction<any>) => {
      state.router = payload
    },
    setIsAnswer: (state, {payload}: PayloadAction<any>) => {
      state.isAnswer = payload
    },
    setAlertType: (state, {payload}: PayloadAction<any>) => {
      state.alertType = payload
    },
    setOs: (state, {payload}: PayloadAction<any>) => {
      state.os = payload
    },
    setIsPinnedUI: (state, {payload}: PayloadAction<any>) => {
      state.isPinnedUI = payload
    },
    setLayout: (state, {payload}: PayloadAction<any>) => {
      state.layout = payload
    },
    setSize: (state, {payload}: PayloadAction<any>) => {
      state.size = payload
    },
    setFullScreen: (state, {payload}: PayloadAction<any>) => {
      state.fullScreen = payload
    },
    setIsHide: (state, {payload}: PayloadAction<any>) => {
      state.isHide = payload
    },
    setAppUi: (state, {payload}: PayloadAction<any>) => {
      state.appUi = payload
    },
    setError: (state, {payload}: PayloadAction<any>) => {
      state.error = payload
    },
    setIsMaximized: (state, {payload}: PayloadAction<any>) => {
      state.isMaximized = payload
    },
    setShowInviteUsers: (state, {payload}: PayloadAction<boolean>) => {
      state.inviteNewPerson = payload
    },
  },
  extraReducers: (builder) => {}
})

export const {
  setRightBarType, setIsChangedUi,
  setCommentCounters, setIsPermissionAwaitRequest,
  setLayout, setIsAwaitStart,
  setIsAwaitHostConfirm, setFromWaiting,
  setSize, setIsAwaitReady,
  setFullScreen, setIsHide,
  setAppUi, setIsMaximized,
  setError, setOs, setIsShowWaitingEntry,
  setPlugType, setContextType,
  setIsPinnedUI, setAlertType,
  setIsAnswer, setRout, setConfirmType,
  setFirstBanner, setShowInviteUsers
} = uiSlice.actions;
export default uiSlice.reducer;
