import React, {useCallback, useMemo} from 'react';
import {User} from "../../../interfaces/user";
import {Button, Dialog, Text} from "@clout-team/web-components";
import {updateUser} from "../../../redux/slices/users";
import {Emit} from "../../../helpers/socketHelper";
import {SOCKET_ACTIONS} from "../../../constants/socket";
import {batch, useDispatch, useSelector} from "react-redux";
import {conferenceHashSelector} from "../../../redux/selectors/conference";
import {setAlertType} from "../../../redux/slices/ui";
import {ALERT_TYPES} from "../../../constants/contants";
import {settingsSelectorIsInvertRightBar} from "../../../redux/selectors/settings";
import classNames from "classnames";
import {formatUserName} from "../../../helpers/functions";

const DialogMuteUser = React.memo((props: {
  L: (key: string) => string
  user: User.IUsersTo
  onClose: () => void
  turnType?: 'audio' | 'video'
}) => {
  const {L, user, onClose, turnType = 'audio'} = props;
  const hash = useSelector(conferenceHashSelector),
      invertedRight = useSelector(settingsSelectorIsInvertRightBar),
    dispatch = useDispatch()

  const renderData = useMemo(() => {
    switch (turnType) {
      case 'audio':
        return {
          title: L('calls.turn_off_the_user_microphone'),
          description: L('calls.turn_off_the_user_microphone_for_all_conference')?.replaceAll('{__user__}', formatUserName(user)),
          button: L('calls.turn_off_microphone'),
          userField: 'audio',
          alertType: ALERT_TYPES.HOST_MUTE_USER,
          customEventField: 'turn_audio'
        };
      case 'video':
        return {
          title: L('calls.turn_off_the_users_video'),
          description: L('calls.turn_off_the_user_video_for_all_conference')?.replaceAll('{__user__}', formatUserName(user)),
          button: L('calls.turn_off_video'),
          userField: 'video',
          alertType: ALERT_TYPES.HOST_TURN_OFF_VIDEO,
          customEventField: 'turn_video'
        };
      default:
        return {
          title: '',
          description: '',
          button: '',
          userField: '',
          alertType: '',
          customEventField: ''
        };
    }
  }, [L, turnType, user])

  const muteUser = useCallback(() => {

    batch(() => {
      dispatch(updateUser({[renderData.userField]: false, id: user.id}))
      dispatch(setAlertType({
        type: renderData.alertType,
        data: user
      }))
    })
    Emit(SOCKET_ACTIONS.MEETING_CUSTOM, {
      link: hash,
      attributes: {
        userId: user.id,
        [renderData.customEventField]: true
      }
    });
    onClose();
  }, [hash, user, renderData.customEventField, renderData.userField, renderData.alertType, onClose, dispatch])

  return (
    <Dialog
      className={classNames("dialog-rounded-corners", {
        "inverted-theme-aware": invertedRight,
      })}
      closeOnClickOutside={false}
      dialogTitle={{
        title: renderData.title,
        varOnlyTitle: false,
      }}
      onClose={onClose}
      dialogButtons={{
        right: [
          <Button key={'btn-1'} size={'lg'} inverted={true} color={'white'} onClick={onClose}>
            {L('calls.cancel')}
          </Button>,
          <Button key={'btn-2'} size={'lg'} onClick={muteUser}>
            {renderData.button}
          </Button>
        ]
      }}
    >
      <Text>{renderData.description}</Text>
    </Dialog>
  );
})

export default DialogMuteUser;
