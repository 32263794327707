export const SOCKET_EVENTS = {
    MEETING_JOINING: 'joining',
    MEETING_INCOMMING_CALL: 'incoming_call',
    MEETING_CALL_END: 'call_end',
    MEETING_CLOSE: 'meeting_close',
    MEETING_HOST_CLOSE: 'close',
    MEETING_JOIN: 'join',
    MEETING_REJECT: 'reject',
    MEETING_INFO: 'info',
    MEETING_ROOM_CREATED: 'room_created',
    MEETING_NO_ANSWER: 'no_answer',
    MEETING_WAITING_ENTRY: 'waiting_entry',
    MEETING_CUSTOM: 'event',
    MEETING_SET_HOST: 'set_host',
    MEETING_ERROR: 'error',
    MEETING_KICK: 'kick',
    MEETING_ROOM_STATUS: 'status',
    MEETING_LEAVING: 'leaving',
    MEETING_START_TIMER: 'start_timer',
    MEETING_GET_ENTRY: 'get_entry',
    MEETING_ACCEPT_ENTRY: 'accept_entry',
    MEETING_REJECT_ENTRY: 'reject_entry',
    MEETING_START_AUDIO: 'start_audio',
    MEETING_END_AUDIO: 'end_audio',
    MEETING_START_VIDEO: 'start_video',
    MEETING_END_VIDEO: 'end_video',
    MEETING_WAITING_ENTRY_CANCEL: 'waiting_entry_cancel',
    MEETING_SET_TITLE: 'set_title',
    MEETING_DISABLE_SHARE: 'disable_share',
    MEETING_DISABLE_CHAT: 'disable_chat',
    MEETING_ENABLE_SHARE: 'enable_share',
    MEETING_ENABLE_CHAT: 'enable_chat',
    MEETING_USERS: 'users',

    /** @param `set_confirm_auth` УСТАНОВКА вход без подтверждения */
    MEETING_SET_CONFIRM_AUTH: 'set_confirm_auth',
}

export const SOCKET_ACTIONS = {
    MEETING_HOST_CLOSE: 'close',
    MEETING_INFO: 'info',
    MEETING_JOIN: 'join',
    MEETING_CREATE: 'create',
    MEETING_CUSTOM: 'event',
    MEETING_SET_HOST: 'set_host',
    MEETING_KICK: 'kick',
    MEETING_ROOM_STATUS: 'status',
    MEETING_CHANGE_NAME: 'change_name',
    MEETING_GET_ENTRY: 'get_entry',
    MEETING_ACCEPT_ENTRY: 'accept_entry',
    MEETING_REJECT_ENTRY: 'reject_entry',
    MEETING_START_AUDIO: 'start_audio',
    MEETING_END_AUDIO: 'end_audio',
    MEETING_START_VIDEO: 'start_video',
    MEETING_END_VIDEO: 'end_video',
    MEETING_JOIN_CANCEL: 'join_cancel',
    MEETING_USERS: 'users',

    /** @param `set_title` УСТАНОВКА НАЗВАНИИ КОНФЕРЕНЦИИ */
    MEETING_SET_TITLE: 'set_title',

    /** @param `disable_share` ОТКЛЮЧИТЬ ШЕЙРИНГ ДЛЯ ВСЕХ */
    MEETING_DISABLE_SHARE: 'disable_share',

    /** @param `disable_chat` ОТКЛЮЧИТЬ ЧАТ ДЛЯ ВСЕХ */
    MEETING_DISABLE_CHAT: 'disable_chat',

    /** @param `enable_share` ВКЛЮЧИТЬ ШЕЙРИНГ ДЛЯ ВСЕХ */
    MEETING_ENABLE_SHARE: 'enable_share',

    /** @param `enable_chat` ВКЛЮЧИТЬ ЧАТ ДЛЯ ВСЕХ */
    MEETING_ENABLE_CHAT: 'enable_chat',

    /** @param `enable_confirm_auth` ВКЛЮЧИТЬ ЧАТ ДЛЯ ВСЕХ */
    MEETING_ENABLE_CONFIRM_AUTH: 'enable_confirm_auth',

    /** @param `disable_confirm_auth` ВКЛЮЧИТЬ ЧАТ ДЛЯ ВСЕХ */
    MEETING_DISABLE_CONFIRM_AUTH: 'disable_confirm_auth',
}

export const SOCKET_CUSTOM_ACTIONS = {
    USER_AUDIO: 'info',
    USER_VIDEO: 'join'
}
