import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {userCurrentSelector, usersWaitingSelector} from "../../../redux/selectors/users";
import {WaitingRoomBanner} from "@clout-team/web-components";
import "./WaitingEntry.scss";
import {uiSelectorIsShowWaitingEntry} from "../../../redux/selectors/ui";
import {setIsShowWaitingEntry} from '../../../redux/slices/ui';
import {Emit} from "../../../helpers/socketHelper";
import {SOCKET_ACTIONS} from "../../../constants/socket";
import {conferenceHashSelector} from "../../../redux/selectors/conference";
import {deleteUserAsync} from "../../../redux/slices/users";
import {User} from "../../../interfaces/user";
import classNames from "classnames";
import { settingsSelectorIsInvertRightBar } from '../../../redux/selectors/settings';

const WaitingEntry = () => {
  const waitingUsers = useSelector(usersWaitingSelector),
    isShowWaitingEntry = useSelector(uiSelectorIsShowWaitingEntry),
    hash = useSelector(conferenceHashSelector),
    invertedRightBar = useSelector(settingsSelectorIsInvertRightBar),
    currentUser = useSelector(userCurrentSelector),
    dispatch = useDispatch()

  useEffect(() => {
    if (waitingUsers.length === 0) dispatch(setIsShowWaitingEntry(false));
  }, [waitingUsers])

  const onCLose = useCallback(() => {
    dispatch(setIsShowWaitingEntry(false))
  }, [dispatch])

  const delUser = useCallback((id: string) => {
    const user = waitingUsers.find((u: User.IUsersTo) => u.waitingId === id)
    if (user) dispatch(deleteUserAsync(user.id))
    if (waitingUsers.length === 1) onCLose();
  }, [waitingUsers, dispatch])

  const onAccept = useCallback((data) => {
    Emit(SOCKET_ACTIONS.MEETING_ACCEPT_ENTRY, {link: hash, id: data})
    delUser(data)
  }, [hash, waitingUsers])

  const onAcceptAll = useCallback(() => {
    waitingUsers.forEach(u => {
      Emit(SOCKET_ACTIONS.MEETING_ACCEPT_ENTRY, {link: hash, id: u.waitingId})
      dispatch(deleteUserAsync(u.id))
    })
    onCLose();
  }, [hash, waitingUsers])

  const onRemove = useCallback((data) => {
    Emit(SOCKET_ACTIONS.MEETING_REJECT_ENTRY, {link: hash, id: data})
    delUser(data)
  }, [hash, waitingUsers])

  const onRemoveAll = useCallback(() => {
    waitingUsers.forEach(u => {
      Emit(SOCKET_ACTIONS.MEETING_REJECT_ENTRY, {link: hash, id: u.waitingId})
      dispatch(deleteUserAsync(u.id))
    })
    onCLose();
  }, [hash, waitingUsers])

  return (
    <>
      {
        ( currentUser?.isHost && isShowWaitingEntry ) && (
          <div className={classNames('waiting-entry')}>
            <WaitingRoomBanner
                className={`${invertedRightBar ? 'inverted-theme-aware' : ""}`}
                users={waitingUsers.map(u => {
                  return {
                    name: u.name,
                    surname: u.surname,
                    domain: u.domain,
                    login: u.login,
                    avatar: u.avatar,
                    id: u.waitingId
                  }
                })}
                onClose={onCLose}
                onAccept={onAccept}
                onRemove={onRemove}
                onAcceptAll={onAcceptAll}
                onRemoveAll={onRemoveAll}
            />
          </div>
        )
      }
    </>
  );
};

export default WaitingEntry;
