import React, { ReactElement } from 'react';
// import Footer from './components/Footer';
import Header from './components/Header';
import './wrap.scss'
const PageWrap = (props: {
  children: ReactElement
  headerRight?: ReactElement
}) => {
  const { children, headerRight } = props;
  return (
    <div className={'pages-wrap'}>
      <Header right={headerRight} />
      {children}
      {/* <Footer /> */}
    </div>
  );
};

export default PageWrap;
