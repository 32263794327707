import React, {useCallback, useMemo} from 'react';
import {
  Layout, sendToElectron,
  Text,
  TrafficLights,
} from "@clout-team/web-components";
import {useDispatch, useSelector} from "react-redux";
import './ElectronCallHeader.scss';
import classNames from "classnames";
import {User} from "../../../../interfaces/user";
import {conferenceSelector} from "../../../../redux/selectors/conference";
import {
  userRespondingSelector, userActiveSelector,
  userCurrentSelector
} from "../../../../redux/selectors/users";
import {
  uiSelectorIsAnswer,
  uiSelectorIsSmall,
  uiSelectorIsXSmall, uiSelectorOs
} from "../../../../redux/selectors/ui";
import AlertFullScreen from "../AlertFullScreen/AlertFullScreen";
import {setSize} from "../../../../redux/slices/ui";
import ConferenceInfo from "../../../../components/Banners/ConferenceInfo/ConferenceInfo";
import ViewChangeButton from "../ViewChangeButton/ViewChangeButton";
import ChangeSizeButton from "../ChangeSizeButton/ChangeSizeButton";
import Timer from "../../../../components/Timer";
import {formatUserName} from "../../../../helpers/functions";
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";
import { PLUG_TYPES } from '../../../../constants/contants';
import { endCall } from '../../../../helpers/callsHelper';

const isWindowsOS = window.navigator.userAgent.toLowerCase().includes('windows')

export default function ElectronCallHeader() {
  const conference = useSelector(conferenceSelector);
  const localUser = useSelector(userCurrentSelector);
  const lang = useSelector((state: any) => state.localization);
  const L = useCallback((key: string) => lang.translations[lang.locale][key], [lang]);
  const isSmall = useSelector(uiSelectorIsSmall),
    isXSmall = useSelector(uiSelectorIsXSmall),
    answer = useSelector(uiSelectorIsAnswer),
    isConference = conference.isConference,
    userResponding: User.IUsersTo = useSelector(userRespondingSelector),
    activeUser: User.IUsersTo = useSelector(userActiveSelector),
    os = useSelector(uiSelectorOs),
    // appUi: string = useSelector(uiSelectorAppUi),
    dispatch = useDispatch()

  const logOutConference = useCallback(() => {
    if (localUser.isHost) endCall(true, PLUG_TYPES.MEETING_OVER)
    else endCall(false, PLUG_TYPES.YOU_LEFT_MEET)
  }, [localUser])

  const renderUserName = useMemo(() => (user: User.IUsersTo) => {
    return <>
      <span className={'vertical-space'}/>
      <Text size={'footnote-bold'}>{formatUserName(user)}</Text>
    </>
  }, [])

  const onDoubleCLickHandle = useCallback(() => {
    if (isSmall || isXSmall) {
      if (isElectron()) {
        sendToElectron('request-set-resizable')
        setTimeout(() => {
          dispatch(setSize('M'))
        }, 200)
      } else {
        dispatch(setSize('M'))
      }
    } else {
      if (isElectron()) sendToElectron('toggle-window-maximize')
    }
  }, [isSmall, isXSmall, dispatch])

  return (
    <>
      <AlertFullScreen L={L}/>
      <div className={classNames(
        'info-bar-item',
        {
          'is-group': isConference,
          'info-bar-item-sm': isSmall || isXSmall,
          'info-bar-item-m': os === 'mac',
          'info-bar-item-w': os === 'win'
        }
      )} onDoubleClick={onDoubleCLickHandle}>
        <ChangeSizeButton/>
        <div key={1} className={'call-time'}>
          {
            isConference ? (
                <Layout gap={'sm'} className={'conference-info'} isAlignCenter>
                  {!isSmall && !isXSmall && <ConferenceInfo L={L}/>}
                  <Timer/>
                  {(isSmall || isXSmall) && activeUser && renderUserName(activeUser)}
                </Layout>
              ) :
              answer ? (
                <Layout gap={'sm'} className={'conference-info'} isAlignCenter>
                  {!isSmall && !isXSmall && <ConferenceInfo L={L}/>}
                  <Timer/>
                  {(isSmall || isXSmall) && renderUserName(userResponding)}
                </Layout>
              ) : <></>
          }
          {(isSmall || isXSmall) && !isConference && !answer && renderUserName(userResponding)}
        </div>
        <div key={3} className={classNames('info-bar-right', {
          // 'info-bar-right-win': appUi !== UI_TYPE.MAC && isElectron()
        })}>
          {
            (answer || isConference) && <ViewChangeButton L={L}/>
          }
        </div>
        {isElectron() && isWindowsOS && (
            <TrafficLights
                isCustom
                isFullScreen={false}
                onClose={() => {
                  logOutConference()
                  sendToElectron('close-window-calls')
                }}
                onMinimize={() => {
                    sendToElectron('minimize-window')
                }}
                onMaximize={() => sendToElectron('max-window')}
                isWin={isWindowsOS}
            />
        )}
      </div>
    </>
  )
}
